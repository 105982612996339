import React, { useState, useEffect, useRef } from "react";
import Header from '../../../components/header/Header'
import Footer from '../../../components/footer/Footer'
import ChatComponent from './ChatComponent';
import AWS from 'aws-sdk';
import StarpilotChat from "../../../components/StarpilotChatTrsln";
import TranscriptChat from "../../../components/TranscriptChat";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import {
  useMeetingManager,
  useMeetingStatus,
  useToggleLocalMute,
  useSelectAudioInputDevice,
  useAudioInputs,
  useContentShareState,
  LocalVideo,
  useLocalVideo,
  useRemoteVideoTileState,
  RemoteVideo,
  ContentShare,
  useContentShareControls,
  useFeaturedTileState,
  useSelectVideoQuality,
  useRosterState,
  Roster,
  RosterGroup,
  RosterAttendee,
  useAttendeeStatus,
  useActiveSpeakersState,
} from "amazon-chime-sdk-component-library-react";
import { doctorMeeting, joinOtherUserApi, meetingInfoApi, isRxSentForAutoFillPrescription } from "../../../api/apiHandler";
import Swal from 'sweetalert2';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { getAwsChimeChatGptResponse, getAwsChimeChatGptMessages, storeChimePrompt, addChimePromptInConversation, updateAppoinmentStatus, updateAppoinment, getDownloadChimeConversationGptResponseTextFile } from '../../../api/apiHandler';
import { v4 as uuidv4 } from 'uuid';
import queryString from 'query-string';
import { InviteListPop } from "../../../modal/InviteListPop/index";

// console.log('AWS: ', AWS);

function MeetingRoomTrsln() {

  const navigate = useNavigate();
  const param = useParams();
  const location = useLocation();
  const meeting_data = location.state;
  console.log('meeting_data: ', meeting_data);
  const [meetingData, setMeetingData] = useState(meeting_data);
  const meetingManager = useMeetingManager();
  const [chat, setChat] = useState()
  const [transcriptChat, setTranscriptChat] = useState(false)
  const [language, setLanguage] = useState("")
  const [guest, setGuest] = useState(false)
  const [guestMeetData, setGuestMeetData] = useState("");
  const meetingStatus = useMeetingStatus();
  const { toggleMute } = useToggleLocalMute();
  const selectAudioInput = useSelectAudioInputDevice();
  const [audioStatus, setAudioStatus] = useState(true);
  const [videoTiles, setVideoTiles] = useState(false);
  const [callCreated, setCallCreated] = useState(false);
  const [joinBtn, setJoinBtn] = useState(false);
  const fileInputRef = useRef(null);
  const [leaveBtn, setLeaveBtn] = useState(false);
  const [transacriptName, setTransacriptName] = useState("");
  const [meetingResponse, setMeetingResponse] = useState(null);
  const [attendeeResponse, setAttendeeResponse] = useState(null);
  const [activeAttendeeId, setActiveAttendeeId] = useState(null);
  const [screenShareBtn, setScreenShareBtn] = useState(true);
  const [videoBtn, setVideoBtn] = useState(false);
  const [handleScreenShareBtn, setHandleScreenShareBtn] = useState(false);
  const [userEmail, setUserEmail] = useState(meeting_data != null ? meeting_data.email : null);
  const [userName, setUserName] = useState(meeting_data != null ? meeting_data.name : null);
  const [handleJoinOtherUserApi, setHandleJoinOtherUserApi] = useState(false);
  const [userRole, setUserRole] = useState(meeting_data != null ? meeting_data.role : 0);
  const [patientId, setPatientId] = useState(meeting_data != null ? meeting_data.patient_id : 0)
  console.log('patientId: ', patientId);
  console.log("ttests===>", localStorage.getItem("user_login_type_role", false))
  const [messages, setMessages] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [me, setMe] = useState();
  const { interimTranscript, resetTranscript, transcript, listening } = useSpeechRecognition();
  const [storedTranscript, setStoredTranscript] = useState([]);
  console.log('storedTranscript: ', storedTranscript);
  const [showSuggestionModal, setShowSuggestionModal] = useState(false);
  const [getChatGptResponse, setGetChatGptResponse] = useState(null);
  const [invite, setInvite] = useState(null);

  const [image, setImage] = useState("");
  const [fileType, setFileType] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);


  const [uuid, setUuid] = useState('');
  const [isNewchat, setIsNewChat] = useState(1);
  const [handleChatGptApiCalling, setHandleChatGptApiCalling] = useState(false);
  // const [handleScreenShareView,setScreenShareView] = useState(true);
  console.log("handleChatGptApiCalling==>", handleChatGptApiCalling)
  const activeSpeakers = useActiveSpeakersState();
  const { roster } = useRosterState();
  const [activeSpeakerName, setActiveSpeakerName] = useState("");
  console.log('activeSpeakerName=>', activeSpeakerName)

  const [inputText, setInputText] = useState('');
  const [handleLoader, setHandleLoader] = useState(false);

  let staticPrompt = [
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "hello good morning meet how are you"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "can I help you"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "sorry to hear that"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "have you been having this symptoms"
    },
    {
      "role": "Jagdish",
      "transcript": "okay"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "did you travel anywhere or have you been in contact with any sick person"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "do you have any other symptoms"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "are you taking any medicine or any supplements"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "I think you have the flu"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "can you please let me know what's your age and weight height and"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "male or female"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "okay please take following medicines first"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "Aspirin-325milligrams 10 Tablets 10 times Daily"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "Paracetamol 2 Tablets 20 times Daily"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "Ventelyo 11 Puffs 50 times Daily"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "ibuprofen 2 Capsules 30 times Daily"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "warfarin 1 tablet 19 time daily"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "please note"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "Grape juice 12 ounces liquid 25 times daily"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "Pheonreceumen 1 tablet 16 time daily"
    },
    {
      "role": "Rashmi Rawat Dr",
      "transcript": "you should also get some rest"
    }
  ]

  useEffect(() => {
    if (activeSpeakers.length > 0 && roster) {
      const activeSpeakerId = activeSpeakers[0];
      const activeSpeakerInfo = roster[activeSpeakerId];
      if (activeSpeakerInfo) {
        // Assuming the name is stored after '#'
        const name = activeSpeakerInfo.externalUserId.split("#")[1];
        setActiveSpeakerName(name);
      } else {
        setActiveSpeakerName("");
      }
    }
  }, [activeSpeakers, roster]);

  useEffect(() => {
    generateUuidIfEmpty()
  }, [])

  const generateUuidIfEmpty = () => {
    if (!uuid) {
      const newUuid = uuidv4();
      setUuid(newUuid);
    }
  };

  useEffect(() => {
    if (handleChatGptApiCalling) {
      // handleChatGptApiCall()
      handleChatGptApiMessagesCall()
    }
  }, [handleChatGptApiCalling])

  useEffect(() => {

    const userAgent = window.navigator.userAgent;
    const isMobileDevice = /Mobi|Android/i.test(userAgent);

    if (meeting_data != null) {
      if (meeting_data.start_meeting == 1) {

        if (isMobileDevice) {
          setScreenShareBtn(false)
        } else {
          setScreenShareBtn(true)
        }
        startCall();
      } else {

        if (isMobileDevice) {
          setScreenShareBtn(false)
        } else {
          setScreenShareBtn(true)
        }
        joinOtherUser({ "joinInviteBtn": false });
      }
    } else {
      if (isMobileDevice) {
        setScreenShareBtn(false)
      } else {
        setScreenShareBtn(true)
      }
      joinOtherUser({ "joinInviteBtn": true });
    }
    requestAudioPermissions();
  }, [])

  const requestAudioPermissions = () => {
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        // Handle success, the user has granted permission

        // You can do further processing with the audio stream if needed
      })
      .catch((error) => {
        // Handle error, the user has denied permission or there is another issue

      });
  };

  const startCall = async () => {
    try {
      const res = await doctorMeeting({ user_login_type_role: localStorage.getItem("user_login_type_role"), appointment_id: parseInt(meeting_data.appointment_id) });
      const response = JSON.parse(res);

      setMeetingResponse(response.data.meetingResponse);
      setAttendeeResponse(response.data.attendeeResponse);
      setMe(response.data.attendeeResponse.Attendee.AttendeeId)
      // setCallCreated(true);
      setJoinBtn(true);

    } catch (error) {
      setJoinBtn(false)

    }
  };

  const joinMeeting = async () => {
    setJoinBtn(false)
    const joinData = {
      meetingInfo: meetingResponse,
      attendeeInfo: attendeeResponse,
    };

    try {
      await meetingManager.join(joinData);
      await meetingManager.start();
      SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
      setVideoTiles(true)
      setCallCreated(true);
      setLeaveBtn(true)
      setHandleChatGptApiCalling(true)
      // setVideoTiles(meetingManager.videoTileStates);
    } catch (error) {

    }
  };

  const joinOtherUser = async (joinUser) => {
    console.log("joinUser--->", joinUser)
    let joinOtherUserObj;
    if (joinUser.joinInviteBtn) {
      if (localStorage.getItem("user_token")) {
        if (localStorage.getItem("user_login_type_role") == 1) {
          navigate('/');
        }

        joinOtherUserObj = {
          "meeting_id": param.meetingid,
          "appointment_id": param.appointmentid,
          "user_id": parseInt(localStorage.getItem("user_id")),
          "name": localStorage.getItem("user_name"),
          "email": localStorage.getItem("user_email"),
          "role": localStorage.getItem("user_login_type_role"),
          "joinType": "invite"
        }
        const res = await meetingInfoApi({
          "meeting_id": param.meetingid,
          "appointment_id": param.appointmentid,
        });

        const response = JSON.parse(res);
        console.log("response-->", response)
        if (response.data.meetingData.doctor_id == parseInt(localStorage.getItem("user_id"))) {
          const joinMeetingObj = {
            "meeting_id": param.meetingid,
            "appointment_id": param.appointmentid,
            "user_id": localStorage.getItem("user_id"),
            "name": localStorage.getItem("user_name"),
            "email": localStorage.getItem("user_email"),
            "role": parseInt(localStorage.getItem("user_login_type_role")),
            "start_meeting": 0,
            "patient_id": response.data.meetingData.patient_id
          }
          console.log("joinMeetingObj", joinMeetingObj);
          navigate('/meeting', { state: joinMeetingObj })
        }
        setGuestMeetData({
          patientId: response.data.meetingData.patient_id,
        })
        setUserRole(localStorage.getItem("user_login_type_role"))
        setGuest(true)
      } else {

        handleNameInput();
        return;
      }
    } else {
      joinOtherUserObj = {
        "meeting_id": meeting_data.meeting_id,
        "appointment_id": meeting_data.appointment_id,
        "user_id": meeting_data.user_id,
        "name": meeting_data.name,
        "email": meeting_data.email,
        "role": meeting_data.role,

      }
      if (meeting_data?.language) {
        joinOtherUserObj.language = (meeting_data?.language)
      }
    }
    console.log("joinOtherUserObj t==>", joinOtherUserObj)
    // if (userName && userEmail) { meetingInfoApi
    console.log("joinOtherUserObj t11==>", userName, userEmail)
    const res = await joinOtherUserApi(joinOtherUserObj);

    const response = JSON.parse(res);
    console.log("response-->", response)
    setLanguage(response?.data?.language || "")
    const joinData = {
      meetingInfo: response.data.meetingResponse,
      attendeeInfo: response.data.attendeeResponse,

    };
    console.log("response.data.transacript_name--->", response.data.transacript_name)
    setTransacriptName(response.data.transacript_name)
    setMeetingResponse(response.data.meetingResponse);
    setMe(response.data.attendeeResponse.Attendee.AttendeeId)
    try {
      await meetingManager.join(joinData);
      await meetingManager.start();
      SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
      setVideoTiles(true)
      setJoinBtn(false)
      setLeaveBtn(true)
      setCallCreated(true)
      // setVideoTiles(meetingManager.videoTileStates);
    } catch (error) {

    }
    // }
  };
  console.log("transacriptName000>", transacriptName)
  const handleNameInput = () => {
    const name = prompt("Enter your name:");
    if (name === null) return;

    const namePattern = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;

    if (!namePattern.test(name)) {

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Please enter a valid name',
        toast: true,
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        handleNameInput()
      })
    } else {
      setUserName(name)
      setTimeout(() => {
        handleEmailInput()
      }, 500)

    }
  };

  const handleEmailInput = () => {
    const email = prompt("Enter your email:");
    if (email === null) return;

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailPattern.test(email)) {

      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Please enter a valid email address',
        toast: true,
        showConfirmButton: false,
        timer: 1000
      }).then(() => {
        handleEmailInput()
      })
    } else {
      setHandleJoinOtherUserApi(true)
      setUserEmail(email)
    }
  };

  useEffect(() => {
    console.log("get")
    if (userName != null && userEmail != null) {
      if (handleJoinOtherUserApi) {
        joinOtherUserViaInvite()
      }
    }
  }, [handleJoinOtherUserApi])

  const joinOtherUserViaInvite = async () => {
    const joinOtherUserObj = {
      "meeting_id": param.meetingid,
      "appointment_id": 0,
      "user_id": 0,
      "name": userName,
      "email": userEmail,
      "role": 4
    }

    if (userName && userEmail) {
      const res = await joinOtherUserApi(joinOtherUserObj);

      const response = JSON.parse(res);
      const joinData = {
        meetingInfo: response.data.meetingResponse,
        attendeeInfo: response.data.attendeeResponse,

      };
      setMeetingResponse(response.data.meetingResponse);
      setMe(response.data.attendeeResponse.Attendee.AttendeeId)
      setHandleJoinOtherUserApi(false)
      try {
        await meetingManager.join(joinData);
        await meetingManager.start();
        SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
        setVideoTiles(true)
        setJoinBtn(false)
        setLeaveBtn(true)
        setCallCreated(true)
        // setVideoTiles(meetingManager.videoTileStates);
      } catch (error) {

      }
    }
  }

  // const handleAudio = () => {
  //     setAudioStatus(!audioStatus);
  //     if (!audioStatus) {
  //       selectAudioInput("default");
  //     } else {
  //       selectAudioInput(null);
  //     }
  // };

  const handleAudio = async () => {
    setAudioStatus(!audioStatus);
    if (!audioStatus) {
      // Unmute and select default audio input
      toggleMute(false);
      selectAudioInput("default");
    } else {
      // Mute and deselect audio input
      toggleMute(true);
      selectAudioInput(null);
    }
  };

  const handleOtherUserJoin = async () => {
    try {
      // Use the Clipboard API to copy text to the clipboard
      if (!guest && localStorage.getItem("user_login_type_role") == 2) {
        let url = process.env.REACT_APP_WEB_URL + 'meeting-trspt/' + parseInt(meeting_data.appointment_id) + '/' + meetingResponse.Meeting.MeetingId;
        await navigator.clipboard.writeText(url);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Link copied',
          toast: true,
          showConfirmButton: false,
          timer: 2000
        });
      }

    } catch (err) {

    }
  };


  useEffect(() => {
    if (activeSpeakers.length > 0 && roster) {
      const activeSpeakerId = activeSpeakers[0];
      const activeSpeakerInfo = roster[activeSpeakerId];
      if (activeSpeakerInfo) {
        // Assuming the name is stored after '#'
        const name = activeSpeakerInfo.externalUserId.split("#")[1];
        setActiveSpeakerName(name);
      } else {
        setActiveSpeakerName("");
      }
    }
  }, [activeSpeakers, roster]);


  // Function to set the active attendee ID
  const setActiveAttendee = (attendeeId) => {
    setActiveAttendeeId(attendeeId);
  };

  const { toggleVideo } = useLocalVideo();
  const { toggleContentShare } = useContentShareControls();
  const { tileId } = useFeaturedTileState();
  const {
    tiles,
    attendeeIdToTileId,
    tileIdToAttendeeId
  } = useRemoteVideoTileState();

  const { isLocalUserSharing, sharingAttendeeId } = useContentShareState();
  console.log('isLocalUserSharing: ', isLocalUserSharing, sharingAttendeeId);
  const selectVideoQuality = useSelectVideoQuality();
  const attendees = Object.values(roster);

  const localVid = document.getElementById("localvideo");
  const remoteVid = document.getElementById(`remote_${tileId}`);
  if (localVid) {
    if (tileId === null) {


      localVid.classList.add("localvideo_main");
      localVid.classList.remove("localvideo_side");
    } else {


      localVid.classList.add("localvideo_side");
      localVid.classList.remove("localvideo_main");
    }
  }
  if (remoteVid) {
    if (tileId === null) {

      remoteVid.classList.add("remotevideo");
      remoteVid.classList.remove("ch-featured-tile");
    } else {


      remoteVid.classList.remove("remotevideo");
      remoteVid.classList.add("ch-featured-tile");
    }
  }

  const buttonClick = () => {

    setVideoBtn(!videoBtn)
    toggleVideo();
    selectVideoQuality("720p");
  };

  const handleScreenShare = () => {
    toggleContentShare();
    setHandleScreenShareBtn(!handleScreenShareBtn)
    // setScreenShareView(!handleScreenShareView)
  }

  const remoteTiles = Object.entries(attendeeIdToTileId).map((e) => ({
    [e[0]]: e[1]
  }));

  const MyRosterCell = ({ name, attendeeId }) => {

    const { videoEnabled, sharingContent, muted } = useAttendeeStatus(attendeeId);
    // console.log(handleScreenShareBtn, "perryParwinder")
    return (
      <>
        {!videoEnabled ?
          <div className={`${sharingAttendeeId && "col-lg-3 col-xl-2 col-6"} col-md-6 `}>
            <div className="member-image bg-dark h-auto">
              <div className="blank-screen h-auto" style={{ aspectRatio: 1 }}>{name}</div>
            </div>
          </div> : ""}
      </>
    );
  };
  console.log("attendees-->", attendees)
  const attendeeItems = attendees.map((attendee) => {
    const { chimeAttendeeId, externalUserId } = attendee;
    const name = externalUserId.split("#")[1];

    remoteTiles.length > 0 &&
      remoteTiles.map((r) => {
        if (r[chimeAttendeeId]) {
          // tempAttendeeInfo.push({"name" : name})

        }
      });
    return (
      <MyRosterCell
        key={chimeAttendeeId}
        attendeeId={chimeAttendeeId}
        name={name}
      />
    );
  });

  const autoPresc = async () => {
    try {
      let meetingDataObj;
      if (guest) {
        meetingDataObj = {
          "meeting_id": param.meetingid,
          "appointment_id": param.appointmentid,
          "patient_id": guestMeetData?.patientId,
          "check": true
        }
      } else {
        meetingDataObj = {
          "meeting_id": meetingResponse.Meeting.MeetingId,
          "appointment_id": meetingData.appointment_id,
          "patient_id": meetingData.patient_id,
          "check": true
        }
      }
      const serializedObject = queryString.stringify(meetingDataObj);
      const url = `/doctor/auto-prescription?${serializedObject}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 500);
    } catch (error) {
      console.log("meetingDataObj---?", error)
    }
  }

  const leaveMeeting = async () => {
    try {
      if (guest) {
        Swal.fire({
          icon: 'warning',
          title: 'Exit Meeting',
          text: 'Are you sure you want to exit the meeting?',
          showCancelButton: true,
          confirmButtonText: 'Yes, Exit',
          cancelButtonText: 'Cancel',
        }).then(async (result) => {
          if (result.isConfirmed) {
            await meetingManager.leave();
            SpeechRecognition.stopListening();
            setTimeout(() => {
              navigate('/');
            }, 500)
          } else {
          }
        });
      } else if (userRole == 2) {
        Swal.fire({
          icon: 'warning',
          title: 'Exit Meeting',
          text: 'Are you sure you want to exit the meeting?',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'Auto Presciption',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-1 right-gap',
            confirmButton: 'order-2',
            denyButton: 'order-3',
          }
        }).then(async (result) => {
          if (result.isConfirmed) {

            const isAutoPrescriptionCompletedObj = {
              "meeting_id": meetingResponse.Meeting.MeetingId
            }

            isRxCompletedOrNot(isAutoPrescriptionCompletedObj)

          } else if (result.isDenied) {
            // setTimeout(()=>{
            //   navigate('/doctor/auto-prescription',{ state : meetingDataObj })
            // },500)

            Swal.fire({
              title: "You want to continue with Auto Prescription ?",
              icon: "warning",
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Yes"
            }).then((result) => {
              if (result.isConfirmed) {
                const meetingDataObj = {
                  "meeting_id": meetingResponse.Meeting.MeetingId,
                  "appointment_id": meetingData.appointment_id,
                  "patient_id": meetingData.patient_id
                }
                const serializedObject = queryString.stringify(meetingDataObj);
                const url = `/doctor/auto-prescription?${serializedObject}`;
                setTimeout(() => {
                  window.open(url, '_blank');
                }, 500);

                // const storeChimePromptObj = {
                //   "meeting_id" : meetingResponse.Meeting.MeetingId,
                //   // "prompt" : storedTranscript
                //   "prompt" : staticPrompt
                // }
                // storeChimePrompt(storeChimePromptObj).then((response) => {
                //   response = JSON.parse(response)
                //   console.log('response: ', response);
                //   if (response.code == process.env.REACT_APP_SUCCESS) {
                //       const meetingDataObj = {
                //         "meeting_id" : meetingResponse.Meeting.MeetingId,
                //         "appointment_id" : meetingData.appointment_id,
                //         "patient_id" : meetingData.patient_id
                //       }
                //     const serializedObject = queryString.stringify(meetingDataObj);
                //     const url = `/doctor/auto-prescription?${serializedObject}`;
                //     setTimeout(() => {
                //       window.open(url, '_blank');
                //     }, 500);
                //   }
                //   else {
                //       Swal.fire({
                //           position: 'top-end',
                //           icon: 'error',
                //           title: response.message,
                //           toast: true,
                //           showConfirmButton: false,
                //           timer: 2000
                //       })
                //   }
                // })

              } else {
                handleMeetingStatus()
              }
            });
          } else {
            // console.log("cancel");
          }
        })
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Exit Meeting',
          text: 'Are you sure you want to exit the meeting?',
          showCancelButton: true,
          confirmButtonText: 'Yes, Exit',
          cancelButtonText: 'Cancel',
        }).then(async (result) => {
          if (result.isConfirmed) {
            handlePatientMeetingStatus()
          } else {

          }
        });
      }

    } catch (error) {

    }
  };


  const isRxCompletedOrNot = (isAutoPrescriptionCompletedObj) => {
    isRxSentForAutoFillPrescription(isAutoPrescriptionCompletedObj).then(async (response) => {
      response = JSON.parse(response)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        if (response.data.is_rx_sent == 1) {
          await meetingManager.leave();
          SpeechRecognition.stopListening();
          setTimeout(() => {
            navigate('/');
          }, 500)
        } else {
          handleMeetingStatus()
          // setTimeout(() => {
          //   navigate('/');
          // }, 500)
          // Swal.fire({
          //   position: 'top-end',
          //   icon: 'warning',
          //   title: 'Please complete the auto prescription before leaving the meeting',
          //   toast: true,
          //   showConfirmButton: false,
          //   timer: 2000
          // })
        }
      } else if (response.code == process.env.REACT_APP_ERROR) {
        handleMeetingStatus()
        // Swal.fire({
        //   position: 'top-end',
        //   icon: 'warning',
        //   title: 'Please complete the auto prescription before leaving the meeting',
        //   toast: true,
        //   showConfirmButton: false,
        //   timer: 2000
        // })
      }
      else {
        handleMeetingStatus()
        // Swal.fire({
        //   position: 'top-end',
        //   icon: 'error',
        //   title: response.message,
        //   toast: true,
        //   showConfirmButton: false,
        //   timer: 2000
        // })
      }
    })
  }

  const handleMeetingStatus = () => {

    const updateStatusObj = {
      "appointment_id": meetingData.appointment_id,
      "appointment_status": process.env.REACT_APP_MEETING_DONE
    }

    updateAppoinmentStatus(updateStatusObj).then(async (response) => {
      response = JSON.parse(response)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        await meetingManager.leave();
        SpeechRecognition.stopListening();
        setTimeout(() => {
          navigate('/doctor/home');
        }, 500)
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })
  }

  const handlePatientMeetingStatus = () => {
    console.log("handleLeave")
    const updateStatusObj = {
      "appointment_id": meetingData.appointment_id,
    }

    updateAppoinment(updateStatusObj).then(async (response) => {
      response = JSON.parse(response)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        await meetingManager.leave();
        SpeechRecognition.stopListening();
        setTimeout(() => {
          navigate('/');
        }, 500)
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    })
  }

  function isJsonString(value) {
    if (typeof value === 'string') {
      try {
        JSON.parse(value);
        return true; // It's a valid JSON string
      } catch (e) {
        return false; // It's not a valid JSON string
      }
    }
    return false; // It's not a string at all
  }
  // useEffect(() => {
  //   let timeout;
  //   if (transcript) {
  //     console.log('transcript: ', transcript);

  //     clearTimeout(timeout);
  //     timeout = setTimeout(() => {

  //       const activeUserObj = {
  //         "role" :  activeSpeakerName,
  //         "transcript" : transcript
  //       }

  //       setStoredTranscript(prevTranscripts => [...prevTranscripts, activeUserObj]);
  //       resetTranscript(); 
  //     }, 1000); 
  //   } else {

  //   }
  //   return () => clearTimeout(timeout);
  // }, [transcript, resetTranscript]);

  const add_montechat_transcript = async (data) => {
    try {
      const activeUserObj = {
        "meeting_id": meetingResponse.Meeting.MeetingId,
        // "role": "Patient",
        "role": ((guest && localStorage.getItem("user_login_type_role") == 2 && transacriptName) || (localStorage.getItem("user_login_type_role") == 2 ? "Doctor1" : localStorage.getItem("user_login_type_role") == 1 ? "Patient" : localStorage.getItem("user_login_type_role") == 3 ? "Chemist" : "Guest")),
        "transcript": data,
        "login_role": localStorage.getItem("user_login_type_role")
      }
      return addChimePromptInConversation(activeUserObj)
        .then((response) => {
          response = JSON.parse(response);
          // console.log('response: ', response);
          resetTranscript();
          if (response.code == process.env.REACT_APP_SUCCESS) {
            // console.log("response.data==>", response.data)
            setStoredTranscript(response.data);
            return response.data;
          } else {
            return false;
          }
        })
    } catch (error) {
      console.log("error---->", error)
    }
  }

  useEffect(() => {
    let interval;
    if (localStorage.getItem("user_login_type_role") != 3) {
      console.log("not chemist")
      if (transcript) {
        console.log('transcript: ', transcript);

        // const activeUserObj = {
        //     "meeting_id": meetingResponse.Meeting.MeetingId,
        //     "role": activeSpeakerName,
        //     "transcript": transcript
        // };

        const activeUserObj = {
          "meeting_id": meetingResponse.Meeting.MeetingId,
          // "role": "Patient",
          "role": ((guest && localStorage.getItem("user_login_type_role") == 2 && transacriptName) || (localStorage.getItem("user_login_type_role") == 2 ? "Doctor1" : localStorage.getItem("user_login_type_role") == 1 ? "Patient" : localStorage.getItem("user_login_type_role") == 3 ? "Chemist" : "Guest")),
          "transcript": transcript,
          "login_role": localStorage.getItem("user_login_type_role")
        }
        // console.log('activeUserObj: ', activeUserObj);

        interval = setInterval(() => {
          // console.log('activeUserObj: ', activeUserObj);
          addChimePromptInConversation(activeUserObj)
            .then((response) => {
              response = JSON.parse(response);
              // console.log('response: ', response);
              resetTranscript();
              if (response.code == process.env.REACT_APP_SUCCESS) {
                // console.log("response.data==>", response.data)
                setStoredTranscript(response.data);
              } else {
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: response.message,
                  toast: true,
                  showConfirmButton: false,
                  timer: 2000
                });
              }
            })
            .catch((error) => {
              resetTranscript();
              console.log("error", error);
            });
        }, 3000);
      }
    } else {
      console.log("it is  chemist")
    }


    return () => clearInterval(interval);
  }, [transcript, resetTranscript]);


  const handleChatGptApiCall = async () => {
    if (!handleChatGptApiCalling && inputText == '') {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
    }
    // console.log("storedTranscript==>", storedTranscript)
    const chatGptObj = {
      "patient_id": patientId,
      "meeting_id": meetingResponse.Meeting.MeetingId,
      "uuid": uuid,
      "is_new_chat": isNewchat,
      "prompt": storedTranscript.length > 0 ? storedTranscript : "Hello"
    }
    if (uploadImage) {
      console.log('data: ',);
      let imgUrl = ""
      await uploadData(uploadImage, "/analysis_file").then((newImage) => {
        Swal.close()
        console.log('newImagenewImagenewImage', newImage)
        imgUrl = newImage;
      }).catch((e) => {
        Swal.close()
      });
      chatGptObj.imgUrl = `https://msh-dev.s3.amazonaws.com/analysis_file/${imgUrl}`;
      chatGptObj.imgType = fileType;
    }
    console.log('chatGptObj: getAwsChimeChatGptResponse ', chatGptObj);
    setImage("")
    setUploadImage(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the input field
    }
    setFileType("")
    getAwsChimeChatGptResponse(chatGptObj).then((response) => {
      response = JSON.parse(response)
      console.log("response gpt response", response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setInputText('')
        setHandleLoader(false)
        setGetChatGptResponse(null)
        if (!handleChatGptApiCalling) {
          openSuggestionModal()
        }
        setIsNewChat(0)
        setHandleChatGptApiCalling(false)
        const chatgptResponse = response.data.response;
        // console.log('chatgptResponse: ', chatgptResponse);
        chatgptResponse.forEach(item => {
          if (item.role == "assistant") {
            setGetChatGptResponse(item.content)
          }
        });
      } else {
        setGetChatGptResponse(null)
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      Swal.close()
    })
  }

  const uploadData = async (file, folder_type) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
      region: REGION,
    });
    const ext = file.name.split('.').pop()
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    try {
      const newFileName = new Date().getTime() + '.' + ext;
      const params = {
        Bucket: S3_BUCKET + folder_type,
        Key: newFileName,
        Body: file,
        ACL: "public-read",
      };
      return new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(newFileName);
          }
        });
      });
    } catch (error) {
      console.error("Error in UploadData:", error);
    }
  };

  const onImageChange = (event) => {
    console.log("event00<", event)
    if (event.target.files && event.target.files[0]) {
      console.log("event.target.files[0]==>", event.target.files[0])
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if (allowedTypes.includes(event.target.files[0].type)) {
        const fileSizeLimit = 10 * 1024 * 1024;
        if (event.target.files[0].size > fileSizeLimit) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: "File size exceeds the limit of 10 MB",
            toast: true,
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          setImage(URL.createObjectURL(event.target.files[0]));
          setUploadImage(event.target.files[0]);
          if (event.target.files[0].type == 'application/pdf') {
            setFileType("pdf")
          } else if (event.target.files[0].type == 'text/plain') {
            setFileType("text")
          } else if (event.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setFileType("doc")
          } else if (event.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFileType("xlsx")
          } else {
            setFileType("image")
          }
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Only Image File(png, jpeg, jpg, PDF, Text, docx, xlsx) Type Allowed!",
          toast: true,
          showConfirmButton: false,
          timer: 3000
        })
      }

    }
  }

  const handleChatGptApiMessagesCall = () => {
    if (!handleChatGptApiCalling && inputText == '') {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
    }
    const chatGptObj = {
      "patient_id": patientId,
      "meeting_id": meetingResponse.Meeting.MeetingId,
      "uuid": uuid,
      "is_new_chat": isNewchat,
      "prompt": storedTranscript.length > 0 ? storedTranscript : "Hello"
    }
    console.log('chatGptObj: getAwsChimeChatGptMessages ', chatGptObj);

    getAwsChimeChatGptMessages(chatGptObj).then((response) => {
      response = JSON.parse(response)
      console.log("response getAwsChimeChatGptMessages", response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setInputText('')
        setHandleLoader(false)
        setGetChatGptResponse(null)
        if (!handleChatGptApiCalling) {
          openSuggestionModal()
        }
        setIsNewChat(0)
        setHandleChatGptApiCalling(false)
        const chatgptResponse = response.data.response;
        // console.log('chatgptResponse: ', chatgptResponse);

        chatgptResponse.forEach(item => {
          if (item.role == "assistant") {
            setGetChatGptResponse(item.content)
          } else {
            if (item?.content) {
              if (isJsonString(item?.content)) {
                setStoredTranscript([...JSON.parse(item?.content)])
                // console.log("sdfsdf",
                //   [...JSON.parse(item?.content)]
                // )
              }
            } else {
              // console.log("sdfsdfxcfdsfdsfsdf", item?.content)
            }
          }
        });

      } else {
        setGetChatGptResponse(null)
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      Swal.close()
    })
  }

  const openSuggestionModal = () => {
    setShowSuggestionModal(true)
  }

  const closeSuggestionModal = () => {
    setShowSuggestionModal(false)
    setImage("")
    setUploadImage(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the input field
    }
    setFileType("")
  }

  const handleSendMessage = async () => {
    if (inputText != '') {
      const userInputObj = {
        "role": "Doctor",
        "transcript": inputText
      }
      setStoredTranscript(prevTranscripts => [...prevTranscripts, userInputObj]);
      setHandleLoader(true)

    }
  }

  useEffect(() => {
    if (handleLoader) {
      handleChatGptApiCall()

    }
  }, [handleLoader])

  const handleFileUpload = (e) => {
    console.log("e", e.target.files);
  }

  const handleDownloadTxtFile = () => {

    const downloadTxtFileObj = {
      "meeting_id": meetingResponse.Meeting.MeetingId
    }

    getDownloadChimeConversationGptResponseTextFile(downloadTxtFileObj).then((response) => {
      response = JSON.parse(response);
      console.log('response: ', response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        window.open(response.data.url, '_blank');
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        });
      }
    })

  }
  console.log("setStoredTranscrip-->", storedTranscript)
  return (
    <>
      <InviteListPop meeting_id={(meeting_data?.meeting_id ? (meeting_data?.meeting_id) : (param.meetingid))} appointment_id={(meeting_data?.appointment_id ? (meeting_data?.appointment_id) : (param.appointmentid))} invite={invite} meetType={"trspt"} setInvite={setInvite} />
      <main className="flex-column">
        {(parseInt(localStorage.getItem("user_login_type_role")) != 1) && (<StarpilotChat invite={invite} setInvite={setInvite} guest={guest} addMonteChatTranscript={add_montechat_transcript} transacriptName={transacriptName} buttonClick={buttonClick} videoTiles={videoTiles} screenShareBtn={screenShareBtn} handleScreenShare={handleScreenShare} handleScreenShareBtn={handleScreenShareBtn} videoBtn={videoBtn} handleOtherUserJoin={handleOtherUserJoin} leaveMeeting={leaveMeeting} leaveBtn={leaveBtn} autoPrescMain={autoPresc} audioStatus={audioStatus} handleAudio={handleAudio} callCreated={callCreated} meetingid={(meeting_data?.meeting_id ? (meeting_data?.meeting_id) : (param.meetingid))} chat={chat} setChat={setChat} />)}
        {transcriptChat &&

          <TranscriptChat language={language} setLanguage={setLanguage} transcriptChat={transcriptChat} setTranscriptChat={setTranscriptChat} transacriptName={transacriptName} meetingid={(meeting_data?.meeting_id ? (meeting_data?.meeting_id) : (param.meetingid))} chat={chat} invite={invite} setInvite={setInvite} guest={guest} buttonClick={buttonClick} videoTiles={videoTiles} screenShareBtn={screenShareBtn} handleScreenShare={handleScreenShare} handleScreenShareBtn={handleScreenShareBtn} videoBtn={videoBtn} handleOtherUserJoin={handleOtherUserJoin} leaveMeeting={leaveMeeting} leaveBtn={leaveBtn} autoPrescMain={autoPresc} audioStatus={audioStatus} handleAudio={handleAudio} callCreated={callCreated} />
        }
        <Header />
        <main>
          <section>
            <div className="container">
              <div className="meeting-room">
                {/* <p>Transcription: {transcription}</p> */}
                {/* {videoTiles && (
                    <>
                        <div className="members-dv">
                            {tileId && <p>Active Id now: {tileId}</p>}
                            <div className="row justify-content-center">
                            {videoBtn ?
                            <div className="col-md-6 col-lg-3 col-xl-2">
                                <div className="member-image">
                                {videoTiles && <LocalVideo nameplate="me" id="localvideo" />}
                                </div>
                            </div> : 
                            <div className="col-md-6 col-lg-3 col-xl-2">
                                <div className="member-image bg-dark">
                                <div className="blank-screen">{userName}</div>
                                </div>
                            </div>
                            }
                            
                                {tiles.map((item) => {
                                { }
                                    const attendee = tileIdToAttendeeId[item];
                                    let remotename = "";
                                    if (roster[attendee]) {
                                    const { externalUserId } = roster[attendee];
                                    remotename = externalUserId.split("#")[1];
                                    }
                                    return (
                                      <>
                                      {videoBtn ? 
                                      <div className="col-md-6 col-lg-3 col-xl-2">
                                        <div className="member-image">
                                          <RemoteVideo
                                          tileId={item}
                                          id={`remote_${item}`}
                                          className="remotevideo"
                                          nameplate={userName}
                                          />
                                        </div>
                                      </div> : 
                                      <div className="col-md-6 col-lg-3 col-xl-2">
                                          <div className="member-image bg-dark">
                                          <div className="blank-screen">{userName}</div>
                                          </div>
                                      </div> }
                                      </>
                                    );
                                })}
                               
                            </div>
                        </div>
                        <div className="member-joined">
                            <div className="row">
                            <div className="col-12">
                                <div className="member1">
                                <ContentShare />
                                </div>
                            </div>
                            </div>
                        </div>
                    </>
                )} */}
                <div className="members-dv">
                  <div className="row justify-content-center">
                    {attendeeItems}
                  </div>
                </div>
                { }
                {videoTiles && (
                  <>
                    <div className="members-dv">
                      {tileId && <p>Active Id now: {tileId}</p>}
                      <div className="row justify-content-center">
                        <div className="col-md-6 col-lg-3 col-xl-2 col-6">
                          <div className="member-image">
                            <LocalVideo nameplate="me" id="localvideo" />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 col-xl-2 col-6">
                          <div className="member-image">
                            {tiles.map((item) => {
                              { }
                              const attendee = tileIdToAttendeeId[item];
                              { }
                              let remotename = "";
                              { }
                              { }
                              if (roster[attendee]) {
                                const { externalUserId } = roster[attendee];
                                remotename = externalUserId.split("#")[1];
                              }
                              return (
                                <>
                                  <RemoteVideo
                                    tileId={item}
                                    id={`remote_${item}`}
                                    className="remotevideo"
                                  />
                                  <span>user: {remotename}</span>
                                </>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className={`${!sharingAttendeeId && "col-lg-3 col-xl-2"} col-md-12 `}>
                        <div className="member-joined bg-dark">
                          <div className="member1 h-auto" style={{aspectRatio: 1}}>
                            {/* {handleScreenShareBtn ? 
                                  <> */}

                            {isLocalUserSharing ?
                              (<p>{activeSpeakerName}</p>)
                              :
                              (<ContentShare />)}

                            {/* </> : <p>{activeSpeakerName}</p>} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="d-flex justify-content-between align-items-center">
                  <div className="meeting-widgets">
                    {/* <button>Meeting Status: {meetingStatus}</button>                       */}
                    {!callCreated ? ""
                      : <div className="mute-widget">
                        <i className={audioStatus ? "fa-solid fa-microphone" : "fa-solid fa-microphone-slash"} onClick={handleAudio} />
                        {/* <button disabled={!callCreated} onClick={handleAudio}>
                                  Mute/Unmute
                              </button> */}
                      </div>}
                    {joinBtn ? <div className="join-other">
                      {/* <i className="fa-solid fa-arrow-up-from-bracket" onClick={joinMeeting}/> */}
                      <i className="fa-solid fa-phone" onClick={joinMeeting}></i>
                      {/* <button disabled={!joinBtn} onClick={joinMeeting}>
                                  Join
                              </button> */}
                    </div> : ""}
                    {!callCreated ? ""
                      : <div className="join-other">
                        <i className="fa-solid fa-plus" onClick={handleOtherUserJoin} />
                      </div>}
                    {videoTiles && (
                      <>
                        <div className="join-other">
                          {/* <i className="fa-solid fa-video" onClick={buttonClick} /> */}
                          <i className={videoBtn ? "fa-solid fa-video" : "fa-solid fa-video-slash"} onClick={buttonClick} />
                        </div>
                        <div className="join-other">
                          {isLocalUserSharing ?
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/desktop.png"} disabled={!screenShareBtn} onClick={handleScreenShare} />
                            :
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/desktop-slash.png"} disabled={!screenShareBtn} onClick={handleScreenShare} />
                          }
                          {/* <i className="fa-solid fa-desktop" disabled={!screenShareBtn} onClick={handleScreenShare} /> */}
                        </div>

                      </>)}
                    {callCreated && userRole == 2 ?
                      <div className="join-other">
                        <i className="fa-solid fa-circle-info" onClick={handleChatGptApiMessagesCall} />
                      </div> : ""}
                    {callCreated ?
                      <div className="join-other">
                        <i class="fa-solid fa-download" onClick={handleDownloadTxtFile} ></i>
                      </div> : ""}
                    {callCreated ?
                      <div className="join-other">
                        <i class="fa-solid fa-clipboard-list" onClick={autoPresc} ></i>
                        {/* <img src={process.env.PUBLIC_URL + "/assets/images/icon/trxn.png"} alt="search" /> */}
                      </div> : ""}
                    {callCreated && ((!guest && localStorage.getItem("user_login_type_role") == 2)) ?
                      <div className="join-other" onClick={() => setInvite(!invite)}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M19 17V19H7V17C7 17 7 13 13 13C19 13 19 17 19 17ZM16 8C16 7.40666 15.8241 6.82664 15.4944 6.33329C15.1648 5.83994 14.6962 5.45542 14.1481 5.22836C13.5999 5.0013 12.9967 4.94189 12.4147 5.05764C11.8328 5.1734 11.2982 5.45912 10.8787 5.87868C10.4591 6.29824 10.1734 6.83279 10.0576 7.41473C9.94189 7.99667 10.0013 8.59987 10.2284 9.14805C10.4554 9.69623 10.8399 10.1648 11.3333 10.4944C11.8266 10.8241 12.4067 11 13 11C13.7956 11 14.5587 10.6839 15.1213 10.1213C15.6839 9.55871 16 8.79565 16 8ZM19.2 13.06C19.7466 13.5643 20.1873 14.1724 20.4964 14.8489C20.8054 15.5254 20.9766 16.2566 21 17V19H24V17C24 17 24 13.55 19.2 13.06ZM18 5C17.6979 5.00002 17.3976 5.04725 17.11 5.14C17.6951 5.97897 18.0087 6.97718 18.0087 8C18.0087 9.02282 17.6951 10.021 17.11 10.86C17.3976 10.9527 17.6979 11 18 11C18.7956 11 19.5587 10.6839 20.1213 10.1213C20.6839 9.55871 21 8.79565 21 8C21 7.20435 20.6839 6.44129 20.1213 5.87868C19.5587 5.31607 18.7956 5 18 5ZM8 10H5V7H3V10H0V12H3V15H5V12H8V10Z" fill="#0e874a" />
                        </svg>

                      </div> : ""}

                    {!callCreated ? "" : <div className="join-other test" onClick={() => setTranscriptChat(!transcriptChat)}>
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M3 6C3 5.46957 3.21071 4.96086 3.58579 4.58579C3.96086 4.21071 4.46957 4 5 4H19C19.5304 4 20.0391 4.21071 20.4142 4.58579C20.7893 4.96086 21 5.46957 21 6V15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H12.384L9.504 19.592C8.537 20.461 7 19.776 7 18.477V17H5C4.46957 17 3.96086 16.7893 3.58579 16.4142C3.21071 16.0391 3 15.5304 3 15V6ZM7 8C6.73478 8 6.48043 8.10536 6.29289 8.29289C6.10536 8.48043 6 8.73478 6 9C6 9.26522 6.10536 9.51957 6.29289 9.70711C6.48043 9.89464 6.73478 10 7 10H12C12.2652 10 12.5196 9.89464 12.7071 9.70711C12.8946 9.51957 13 9.26522 13 9C13 8.73478 12.8946 8.48043 12.7071 8.29289C12.5196 8.10536 12.2652 8 12 8H7ZM15 8C14.7348 8 14.4804 8.10536 14.2929 8.29289C14.1054 8.48043 14 8.73478 14 9C14 9.26522 14.1054 9.51957 14.2929 9.70711C14.4804 9.89464 14.7348 10 15 10H17C17.2652 10 17.5196 9.89464 17.7071 9.70711C17.8946 9.51957 18 9.26522 18 9C18 8.73478 17.8946 8.48043 17.7071 8.29289C17.5196 8.10536 17.2652 8 17 8H15ZM7 11C6.73478 11 6.48043 11.1054 6.29289 11.2929C6.10536 11.4804 6 11.7348 6 12C6 12.2652 6.10536 12.5196 6.29289 12.7071C6.48043 12.8946 6.73478 13 7 13H9C9.26522 13 9.51957 12.8946 9.70711 12.7071C9.89464 12.5196 10 12.2652 10 12C10 11.7348 9.89464 11.4804 9.70711 11.2929C9.51957 11.1054 9.26522 11 9 11H7ZM12 11C11.7348 11 11.4804 11.1054 11.2929 11.2929C11.1054 11.4804 11 11.7348 11 12C11 12.2652 11.1054 12.5196 11.2929 12.7071C11.4804 12.8946 11.7348 13 12 13H17C17.2652 13 17.5196 12.8946 17.7071 12.7071C17.8946 12.5196 18 12.2652 18 12C18 11.7348 17.8946 11.4804 17.7071 11.2929C17.5196 11.1054 17.2652 11 17 11H12Z" fill="#0e874a" />
                      </svg>


                    </div>}
                  </div>

                  <button className={leaveBtn ? "leave-btn" : "leave-btn-disable"} onClick={leaveBtn ? leaveMeeting : undefined} disabled={!leaveBtn}>Leave</button>
                </div>
                {/* <div className="chat-container">
                  <ul className="chat-messages">
                    {messages.map((message) => (
                      <li key={message.id} className={message.sender === me ? 'my-message' : 'other-message'}>
                        {message.sender}: {message.content}
                      </li>
                    ))}
                  </ul>
                  <div className="chat-input">
                    <input
                      type="text"
                      value={messageInput}
                      onChange={(e) => setMessageInput(e.target.value)}
                      placeholder="Type your message..."
                    />
                    <button onClick={sendMessage}>Send</button>
                  </div>
                </div> */}
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </main>

      {showSuggestionModal &&
        (<>
          <div
            className={`modal fade ${showSuggestionModal ? 'show' : ''}`}
            id="staticBackdrop"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex="-1"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            style={{ display: showSuggestionModal ? 'block' : 'none' }}
          >
            <div className="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered">
              <div className="modal-content ">
                <div className="modal-header border-0">

                  <div className="d-flex align-items-center justify-content-between meeting-widgets">
                    <h1 className="modal-title font_600 fs-5" id="staticBackdropLabel">
                      Suggestion
                    </h1>
                    <div onClick={handleDownloadTxtFile} className="join-other"><i class="fa-solid fa-download"></i></div>
                  </div>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeSuggestionModal}
                  />
                </div>
                <div className="modal-body modal-overflow">
                  <pre className="mb-2 text-justify white-space-pre">{getChatGptResponse}</pre>
                </div>
                <div className="d-flex p-3">
                  <input type="text" id="user-input" className="form-control" placeholder="Type your message..." value={inputText} autoComplete='off' onChange={(e) => setInputText(e.target.value)} />

                  {handleLoader ?
                    <button className="chatbutton flex-1">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </button>
                    :
                    <button className="chatbutton flex-1" onClick={handleSendMessage} disabled={inputText === ''}>
                      <i className="fa-solid fa-paper-plane"></i>
                    </button>
                  }

                  <label htmlFor="fileInput" className="filebutton flex-1 fa-solid fa-file-arrow-up">
                    <input id="fileInput" ref={fileInputRef} type="file" className="d-none" onChange={onImageChange} />
                  </label>
                </div>

                {/* <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={closeSuggestionModal}
            >
              Close
            </button>
            
          </div> */}
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>)}
    </>
  )
}

export default MeetingRoomTrsln;