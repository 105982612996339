import React, { useEffect, useState } from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { updateSafetyQcChecked } from '../../../api/apiHandler';
import Swal from 'sweetalert2';

function DoctorPoc() {

    const navigate = useNavigate();
    const location = useLocation();
    const prescriptionData = location.state;
    console.log('prescriptionData: ', prescriptionData);

    const [seeAllModal, setSeeAllModal] = useState(false);
    const [seeRxDetailModal, setSeeRxDetailModal] = useState(false);
    const [changedRxModal, setChangedRxModal] = useState(false);

    const [summary, setSummary] = useState(null);
    const [rxDetails, setRxDetails] = useState(null);
    const [changeRxDetails, setChangeRxDetails] = useState(null);

    useEffect(() => {
        var highestBox = 0;
        document.querySelectorAll('.poc-card .title').forEach((title) => {
            if (title.clientHeight > highestBox) {
                highestBox = title.clientHeight;
            }
        });
        document.querySelectorAll('.poc-card .title').forEach((title) => {
            title.style.height = highestBox + 'px';
        });
    }, []);

    const updateSafetyQcCheck = () => {
        if (prescriptionData != null) {

            const updateSafetyObj = {
                "prescription_id": prescriptionData.prescription_id,
                "is_safety_qc_checked": 1,
            }
            console.log("updateSafetyObj", updateSafetyObj);
            updateSafetyQcChecked(updateSafetyObj).then((response) => {
                response = JSON.parse(response);
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                    setTimeout(() => {
                        navigate('/doctor/prescription', { state: prescriptionData })
                    }, 1000)

                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
        }
    }

    const openSeeAllModal = () => {
        setSeeAllModal(true)
    }

    const closeSeeAllModal = () => {
        setSeeAllModal(false)
    }

    const openSeeRxDetailModal = () => {
        setSeeRxDetailModal(true)
    }

    const closeSeeRxDetailModal = () => {
        setSeeRxDetailModal(false)
    }

    const openChangedRxModal = () => {
        setChangedRxModal(true)
    }

    const closeChangedRxModal = () => {
        setChangedRxModal(false)
    }

    const handleSummary = () => {
        if (prescriptionData != null) {

            // updateSafetyQcChecked({"prescription_id" : prescriptionData.prescription_id}).then((response)=>{
            //     response = JSON.parse(response);
            //     if(response.code == process.env.REACT_APP_SUCCESS){
            //         openSeeAllModal()
            //         console.log("response.data",response.data);
            //         setSummary(response.data)
            //     } else {
            //         Swal.fire({
            //             position: 'top-end',
            //             icon: 'error',
            //             title: response.message,
            //             toast: true,
            //             showConfirmButton: false,
            //             timer: 2000
            //         })
            //     }       
            // })
        }

    }

    const handleRxDetails = () => {
        if (prescriptionData != null) {

            // updateSafetyQcChecked({"prescription_id" : prescriptionData.prescription_id}).then((response)=>{
            //     response = JSON.parse(response);
            //     if(response.code == process.env.REACT_APP_SUCCESS){
            //         openSeeRxDetailModal()
            //         console.log("response.data",response.data);
            //         setRxDetails(response.data)
            //     } else {
            //         Swal.fire({
            //             position: 'top-end',
            //             icon: 'error',
            //             title: response.message,
            //             toast: true,
            //             showConfirmButton: false,
            //             timer: 2000
            //         })
            //     }       
            // })
        }

    }

    const handleChangeRxDetails = () => {
        if (prescriptionData != null) {

            // updateSafetyQcChecked({"prescription_id" : prescriptionData.prescription_id}).then((response)=>{
            //     response = JSON.parse(response);
            //     if(response.code == process.env.REACT_APP_SUCCESS){
            //         openChangedRxModal()
            //         console.log("response.data",response.data);
            //         setChangeRxDetails(response.data)
            //     } else {
            //         Swal.fire({
            //             position: 'top-end',
            //             icon: 'error',
            //             title: response.message,
            //             toast: true,
            //             showConfirmButton: false,
            //             timer: 2000
            //         })
            //     }       
            // })
        }

    }

    return (
        <>
            <main className="bg-color ">
                <Header />
                <section className="poc-sec">
                    <div className="container">
                        <div className="mb-4 mt-3">
                            <p className="font_22 font_600 mb-0">Point of care</p>
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to="#" className="font_14">
                                            Home
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active font_14" aria-current="page">
                                        Point of care
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {prescriptionData != null ?
                            <div className="row justify-content-end">
                                <div className="col-lg-3 col-md-5 col-12">
                                    <div className="dropdown">
                                        <button
                                            className=" dropdown-safety mb-4 mt-3 dropdown-toggle"
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            QC/Safety Changes
                                        </button>
                                        <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="dropdown-item"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#see-all"
                                                    onClick={handleSummary}
                                                >
                                                    Summary
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="dropdown-item"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#see-rxdetail"
                                                    onClick={handleRxDetails}
                                                >
                                                    See All Rx Detail
                                                </Link>
                                            </li>
                                            <li>
                                                <Link
                                                    to="#"
                                                    className="dropdown-item"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#changed-rx"
                                                    onClick={handleChangeRxDetails}
                                                >
                                                    See Changes
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-5 col-12">
                                    <button
                                        className="submit-btn green-btn mb-4 mt-3 w-100"
                                        onClick={updateSafetyQcCheck}
                                    >
                                        Completed Rx Safety/QC Check
                                    </button>
                                </div>
                            </div> : ""}
                        <div className="row">
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/carepoint/1" className="poc-card d-block">
                                    <div className="poc-img carepoint-icon">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/starpilot.png"} alt="" />
                                    </div>
                                    <p className="title d-block">Carepoint  <span className='d-block orangeClr px-2'> AutoRx</span> </p>
                                    <p className="text">
                                        Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/share-medical-list" className="poc-card d-block">
                                    <div className="poc-img carepoint-icon">
                                        <img src={"/assets/images/editDoc.svg"} alt="" />
                                    </div>
                                    <p className="title">Share Records Analyze & Query</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/radiation-illustration/3" className="poc-card d-block">
                                    {/* <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/chemistry_3297186.png"} alt="" />
                                    </div> */}
                                    {/* <p className="title">Laboratory Manual</p> */}
                                    {/* <p className="title">Radiology Scans</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please assist me as requested on laboratory manuals.”
                                    </p> */}
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/camera_3193058.png"} alt="" />
                                    </div>
                                    <p className="title">Illustrations &amp; Radiographic Images</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please assist me as requested for the following medical illustrations and radiographic images”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/differential-diagnosis-generator/4" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/magnify.svg"} alt="" />
                                    </div>
                                    <p className="title">Differential Diagnosis Generator</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/indication-checker/5" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/indication-check.png"} alt="" />
                                    </div>
                                    <p className="title">Indications Checker</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please check these drugs for indications”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/drug-interaction-revision/6" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/drug-interactions.png"} alt="" />
                                    </div>
                                    <p className="title d-block">Drug Interactions 
                                   <span className='d-block orangeClr '>  & AutoRx Revisions</span>
                                    
                                    </p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please check these drugs for drug interactions and revise prescription to eliminate interactions”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/drug-allergy/7" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/drug-allergy.png"} alt="" />
                                    </div>
                                    <p className="title">Drug Allergy</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please check these drugs for drug-allergy interactions”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/dosing-medical-calculation/8" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/dosing-medical.png"} alt="" />
                                    </div>
                                    <p className="title">Dosing &amp; Medical Calculations</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please check these drugs and calculate dosing or confirm dosing calculations”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/pill-identification/9" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/pill-identification.png"} alt="" />
                                    </div>
                                    <p className="title">Pill Identification Tool</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please indicate how to identify the pills for the following drugs”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/iv-compatibility-checker/10" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/iv-compatibility.png"} alt="" />
                                    </div>
                                    <p className="title">IV Compatibility Checker</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please check these drugs for IV compatibility”
                                    </p>
                                </Link>
                            </div>
                            {/* <div className="col-lg-3 col-6">
                                <Link to="/doctor/search-chat-gpt/9" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/camera_3193058.png"} alt="" />
                                    </div>
                                    <p className="title">Illustrations &amp; Radiographic Images</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please assist me as requested for the following medical illustrations and radiographic images”
                                    </p>
                                </Link>
                            </div> */}
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/medical-videos-articles/11" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/video-call.svg"} alt="" />
                                    </div>
                                    <p className="title">Medical Videos &amp; Articles</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please assist me as requested for the following medical videos and articles”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/laboratory-manual/12" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/chemistry_3297186.png"} alt="" />
                                    </div>
                                    <p className="title">Laboratory Manual</p>
                                    {/* <p className="title">Radiology Scans</p>  */}
                                    <p className="text">
                                        Click and enter prompt e.g. “Please assist me as requested on laboratory manuals.”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/icd-11-lookup-tool/13" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/password_1691861.png"} alt="" />
                                    </div>
                                    <p className="title">ICD-11 Lookup Tool</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please assist me as requested to look up ICD-11 codes.”
                                    </p>
                                </Link>
                            </div>
                            <div className="col-lg-3 col-6">
                                <Link to="/doctor/notes-favorites/14" className="poc-card d-block">
                                    <div className="poc-img">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/bookmarks_8110100.png"} alt="" />
                                    </div>
                                    <p className="title">Notes &amp; Favorites</p>
                                    <p className="text">
                                        Click and enter prompt e.g. “Please assist me as requested to analyze and query the following notes and documents”
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                {seeAllModal &&
                    (<>
                        <div
                            className={`modal fade ${seeAllModal ? 'show' : ''}`}
                            id="see-all"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            tabIndex="-1"
                            style={{ display: seeAllModal ? 'block' : 'none' }}
                        >
                            <div className="modal-dialog prescription-modal table-modal modal-dialog-centered modal-md">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={closeSeeAllModal}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered prescribe-table">
                                                <thead>
                                                    <tr>
                                                        <th className="heading" scope="col" colSpan={6}>
                                                            TABLE OF PRESCRIPTION CHNAGES
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th className="prescribe-green" scope="row">
                                                            Medicine
                                                        </th>
                                                        <th className="prescribe-green">Changes</th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Aspirin</td>
                                                        <td className="text-uppercase">No</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Paracetamol</td>
                                                        <td className="table-active text-uppercase">Yes</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Ventolyn</td>
                                                        <td className="table-active text-uppercase"> Yes</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div class="modal-footer">
                    
                    <button type="button" data-bs-dismiss="modal" class="btn submit-btn font_18">Close</button>
                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>)
                }

                {seeRxDetailModal &&
                    (<>
                        <div
                            className={`modal fade ${seeRxDetailModal ? 'show' : ''}`}
                            id="see-rxdetail"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            style={{ display: seeRxDetailModal ? 'block' : 'none' }}
                        >
                            <div className="modal-dialog prescription-modal table-modal modal-dialog-centered modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={closeSeeRxDetailModal}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            <table className="table table-bordered prescribe-table">
                                                <thead>
                                                    <tr>
                                                        <th className="heading" scope="col" colSpan={5}>
                                                            PRESCRIPTIONS
                                                        </th>
                                                        <th className="heading bg-white text-black" scope="col">
                                                            ACCEPT
                                                        </th>
                                                        <th className="heading bg-white text-black" scope="col">
                                                            REJECT
                                                        </th>
                                                        <th className="heading alter-heading" scope="col" colSpan={5}>
                                                            ALTERATIONS/CORRECTIONS
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th className="prescribe-green" scope="row">
                                                            Medicine
                                                        </th>
                                                        <th className="prescribe-green">Form</th>
                                                        <th className="prescribe-green">Dose</th>
                                                        <th className="prescribe-green">Unit</th>
                                                        <th className="prescribe-green">Frequency</th>
                                                        <th className="bg-white text-center text-black">
                                                            CORRECTION
                                                        </th>
                                                        <th className="bg-white text-center text-black">
                                                            CORRECTION
                                                        </th>
                                                        <th className="prescribe-red">Medicine</th>
                                                        <th className="prescribe-red">Form</th>
                                                        <th className="prescribe-red">Dose</th>
                                                        <th className="prescribe-red">Unit</th>
                                                        <th className="prescribe-red">Frequency</th>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Aspirin</td>
                                                        <td>Tablet</td>
                                                        <td>1</td>
                                                        <td>3</td>
                                                        <td>Daily</td>
                                                        <td className="p-0">
                                                            <div className="form-group">
                                                                <input type="radio" name="mark" id="true" />
                                                                <label className="correct-true" htmlFor="true">
                                                                    <i className="fa-solid fa-check" />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="p-0">
                                                            <div className="form-group">
                                                                <input type="radio" name="mark" id="false" />
                                                                <label className="correct-false" htmlFor="false">
                                                                    <i className="fa-solid fa-xmark" />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>Aspirin</td>
                                                        <td>Tablet</td>
                                                        <td>1</td>
                                                        <td>3</td>
                                                        <td>Daily</td>
                                                    </tr>
                                                    <tr>
                                                        <td scope="row">Paracetamol</td>
                                                        <td>Tablet</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>Daily</td>
                                                        <td className="p-0">
                                                            <div className="form-group">
                                                                <input type="radio" name="mark2" id="true1" />
                                                                <label className="correct-true" htmlFor="true1">
                                                                    <i className="fa-solid fa-check" />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="p-0">
                                                            <div className="form-group">
                                                                <input type="radio" name="mark2" id="false1" />
                                                                <label className="correct-false" htmlFor="false1">
                                                                    <i className="fa-solid fa-xmark" />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="altered">Tylenol</td>
                                                        <td className="altered">Capsule</td>
                                                        <td className="altered">2</td>
                                                        <td className="altered">3</td>
                                                        <td>Daily</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ventolyn</td>
                                                        <td>Tablet</td>
                                                        <td>1</td>
                                                        <td>2</td>
                                                        <td>Daily</td>
                                                        <td className="p-0">
                                                            <div className="form-group">
                                                                <input type="radio" name="mark3" id="true2" />
                                                                <label className="correct-true" htmlFor="true2">
                                                                    <i className="fa-solid fa-check" />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="p-0">
                                                            <div className="form-group">
                                                                <input type="radio" name="mark3" id="false2" />
                                                                <label className="correct-false" htmlFor="false2">
                                                                    <i className="fa-solid fa-xmark" />
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td>Ventolyn</td>
                                                        <td className="altered">Puffs</td>
                                                        <td>1</td>
                                                        <td className="altered">3</td>
                                                        <td>Daily</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div class="modal-footer">
                    
                    <button type="button" data-bs-dismiss="modal" class="btn submit-btn font_18">Close</button>
                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>)
                }

                {changedRxModal &&
                    (<>
                        <div
                            className={`modal fade ${changedRxModal ? 'show' : ''}`}
                            id="changed-rx"
                            data-bs-backdrop="static"
                            data-bs-keyboard="false"
                            tabIndex="-1"
                            aria-labelledby="staticBackdropLabel"
                            aria-hidden="true"
                            style={{ display: changedRxModal ? 'block' : 'none' }}
                        >
                            <div className="modal-dialog prescription-modal table-modal modal-dialog-centered modal-xl">
                                <div className="modal-content">
                                    <div className="modal-header border-0">
                                        <button
                                            type="button"
                                            className="btn-close"
                                            data-bs-dismiss="modal"
                                            aria-label="Close"
                                            onClick={closeChangedRxModal}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <div className="table-responsive">
                                            <div className="table-responsive">
                                                <table className="table table-bordered prescribe-table">
                                                    <thead>
                                                        <tr>
                                                            <th className="heading" scope="col" colSpan={5}>
                                                                PRESCRIPTIONS
                                                            </th>
                                                            <th
                                                                className="heading alter-heading"
                                                                scope="col"
                                                                colSpan={5}
                                                            >
                                                                ALTERATIONS/CORRECTIONS
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <th className="prescribe-green" scope="row">
                                                                Medicine
                                                            </th>
                                                            <th className="prescribe-green">Form</th>
                                                            <th className="prescribe-green">Dose</th>
                                                            <th className="prescribe-green">Unit</th>
                                                            <th className="prescribe-green">Frequency</th>
                                                            <th className="prescribe-red">Medicine</th>
                                                            <th className="prescribe-red">Form</th>
                                                            <th className="prescribe-red">Dose</th>
                                                            <th className="prescribe-red">Unit</th>
                                                            <th className="prescribe-red">Frequency</th>
                                                        </tr>
                                                        <tr>
                                                            <td scope="row">Paracetamol</td>
                                                            <td>Tablet</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>Daily</td>
                                                            <td className="altered">Tylenol</td>
                                                            <td className="altered">Capsule</td>
                                                            <td className="altered">2</td>
                                                            <td className="altered">3</td>
                                                            <td>Daily</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Ventolyn</td>
                                                            <td>Tablet</td>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>Daily</td>
                                                            <td>Ventolyn</td>
                                                            <td className="altered">Puffs</td>
                                                            <td>1</td>
                                                            <td className="altered">3</td>
                                                            <td>Daily</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-backdrop fade show"></div>
                    </>)
                }

            </main>
        </>
    )
}

export default DoctorPoc;