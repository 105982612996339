import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import styles from "./DoctorInfoPop.module.scss"

const DoctorInfoPop = ({patiendInfo, setPatientInfo}) => {
    const handlePatientInfo = () => setPatientInfo(!patiendInfo);
    const [patientDataObj,setPatientDataObj] = useState();
    // console.log("patientData==>",patientData)

    // useEffect(()=>{
    //   setPatientDataObj(patientData)
    // },[patientData])


  //   {
  //     "id": 47,
  //     "first_name": "Mit",
  //     "middle_name": "D",
  //     "last_name": "Patel",
  //     "email": "test111@yopmail.com",
  //     "country_code": "+93",
  //     "phone_no": "1234567896",
  //     "patient_code": "PT-S0FLPA6X0Z",
  //     "doctor_code": null,
  //     "chemist_code": null,
  //     "role": 1,
  //     "createdAt": "2024-03-14T07:05:59.000Z",
  //     "is_verified": 1,
  //     "country": "India",
  //     "country_id": 155,
  //     "state": "Gujarat",
  //     "city": "Surat",
  //     "age": 22,
  //     "address": "Varachha",
  //     "is_married": 0,
  //     "have_children": 0,
  //     "how_many_children": 0,
  //     "id_national": "cdcdcd",
  //     "id_other": "dcdcdc",
  //     "nationality": "dcdcdc",
  //     "biometric_id": "cdcdcd",
  //     "insurance_id": "cdcdcd",
  //     "profile_image_name": "1712901990331.jpeg",
  //     "token": "5xuyvpf1ifedtws2bk5ta9q1nulngums4f89xt45uxwpcjlbacziqkok81t58a0s",
  //     "share_medical_download_code": "EJCF0L12rmlI8qfz69JOXA==",
  //     "prescription_code": "Lz9tTiEUvfH1KkVRKdcMbg==",
  //     "code_expiration_date": "2024-05-13 14:20:09",
  //     "profile_image": "https://msh-staging.s3.amazonaws.com/profile_images/1712901990331.jpeg"
  // }


  return (
    <>
    <Modal
        show={patiendInfo}
        onHide={handlePatientInfo}
        backdrop="static"
        keyboard={false}
        centered
        size='xl'
        className={`${styles.PatientInfoPop} PatientInfoPop`}
        scrollable="true"
      >
        <Modal.Header className='' closeButton>
          <Modal.Title className='font_30 font_500 text-blue text-download d-flex align-items-center gap-2 mb-0'>Doctor Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row className=''>
              <Col md="6" className="my-2">
                <label htmlFor="" className="form-label">First Name:</label>
                <input   type="text" className="form-control " />
              </Col>
              <Col md="6" className="my-2">
                <label htmlFor="" className="form-label">Last Name:</label>
                <input   type="text" className="form-control " />
              </Col>
              <Col md="6" className="my-2">
                <label htmlFor="" className="form-label">Address:</label>
                <input   type="text" className="form-control " />
              </Col>
              <Col md="6" className="my-2">
                <label htmlFor="" className="form-label">City:</label>
                <input   type="text" className="form-control " />
              </Col>
              <Col md="6" className="my-2">
                <label htmlFor="" className="form-label">State:</label>
                <input   type="text" className="form-control " />
              </Col>
              <Col md="6" className="my-2">
                <label htmlFor="" className="form-label">Country:</label>
                <input    type="text" className="form-control " />
              </Col>
              <Col md="6" className="my-2">
                <label htmlFor="" className="form-label">Phone:</label>
                <input    type="number" className="form-control " />
              </Col>
            </Row>
            <Row className=''>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DoctorInfoPop