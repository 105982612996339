import React,{useState,useEffect} from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { Link, useNavigate } from 'react-router-dom';

function DoctorHome() {
    const navigate = useNavigate();

      const [highestBoxHeight, setHighestBoxHeight] = useState(0);

    useEffect(() => {
        if (!localStorage.getItem("user_login_type_role", false)) {
          navigate('/');
        }
        const calculateHighestBoxHeight = () => {
          let highestBox = 0;
          document.querySelectorAll('.card-select--1 .text').forEach((element) => {
            if (element.clientHeight > highestBox) {
              highestBox = element.clientHeight;
            }
          });
          setHighestBoxHeight(highestBox);
        };
    
        calculateHighestBoxHeight();
    
        // Re-calculate on window resize, if needed
        window.addEventListener('resize', calculateHighestBoxHeight);
    
        return () => {
          // Remove event listener when component unmounts
          window.removeEventListener('resize', calculateHighestBoxHeight);
        };
      }, []);
  return (
    <>
    <main className="flex-column bg-white">
        <Header/>
            {/* <div className="banner-img">
            <p className="font_28 font_600 text-center text-center mb-0">Health Feedback Form</p>
        </div> */}
            <section>
            <div className="home-sec pb-0 pt-0 bg-transparent">
                <div className="container">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-7 col-xl-6 col-md-12 col-12">
                    <div className="banner-slider">
                        {/* <p className="text-white font_48 mb-2 font_400">Medical Services
                                    <br> You Can
                                    Trust.
                                </p>
                                <p className="text-white font_25 ">&#x2022; Start your care with our
                                    professionals.
                                </p> */}
                        <div className="card-draiva p-0">
                        {/* <div className=" justify-content-between d-flex gap-4 p-2"> */}
                        {/* <img src="assets/images/logo--a.png" className="icon" alt=""> */}
                        <p className="mb-0 font_40 font_500 title text-blue text-end">
                            {" "}
                            Doc. Aiva
                        </p>
                        {/* <img src="assets/images/header-icon.svg" className="plus-medical-icon" alt=""
                                            className="plus-medical-icon"> */}
                        {/* </div> */}
                        <div className="ai-img">
                            {" "}
                            <img src={process.env.PUBLIC_URL + "/assets/images/ai.png"} className="mb-2" alt="" />
                        </div>
                        {/* <div className="d-flex justify-content-between align-items-center"> */}
                        <Link
                            to="/doctor/choose-options"
                            className="start-btn d-flex justify-content-center text-uppercase"
                        >
                            Start
                        </Link>
                        {/* </div> */}
                        <p className="text mb-0" data-bs-toggle="collapse">
                            SILVA ● HEALTH ● MKT
                        </p>
                        </div>
                        {/* <a href="ai.php"
                                    className="start-btn d-flex justify-content-center mb-4 text-uppercase">Start</a> */}
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className="subsec ">
                <div className="container">
                {/* <p className="font_30 font_500 text-blue">Market Place
                        <img src="assets/images/header-icon.svg" alt="" className="plus-medical-icon">
                    </p> */}
                <div className="row justify-content-center">
                    <div className="col-lg-7 col-xl-6 col-12">
                    <div className="row g-2">
                        <div className="col-lg-6 col-md-6 col-6">
                        <div
                            className="card-select--2"
                            data-bs-toggle="modal"
                            data-bs-target="#modal-pharmacy"
                        >
                            <div className="icon-design ">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/icon/pharmacy-medicine.png"}
                                alt=""
                            />
                            </div>
                            <p className="mb-0 font_22 text" style={{ height: highestBoxHeight }}>Pharmacy</p>
                        </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                        <Link to="/doctor/meeting-status" className="card-select--2 ">
                            <div className="icon-design ">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/icon/online-telimedicine.png"}
                                alt=""
                            />
                            </div>
                            <p className="mb-0 font_22 text" style={{ height: highestBoxHeight }}>Initiate telemedicine</p>
                        </Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                        <Link to="/doctor/share-medical-list" className="card-select--2">
                            <div className="icon-design ">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/icon/share-medical-icon.png"}
                                alt=""
                            />
                            </div>
                            <p className=" mb-0 font_22 text" style={{ height: highestBoxHeight }}>Share Medical Record</p>
                        </Link>
                        </div>
                        <div className="col-lg-6 col-md-6 col-6">
                        <Link to="/doctor/patient-appointment" className="card-select--2">
                            <div className="icon-design ">
                            <img
                                src={process.env.PUBLIC_URL + "/assets/images/icon/calendar_7231748.png"}
                                alt=""
                            />
                            </div>
                            <p className=" mb-0 font_22 text" style={{ height: highestBoxHeight }}>Appointments</p>
                        </Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </section>
        <Footer/>
    </main>
        {/* Button trigger modal */}
        {/* Modal */}
        <div
            className="modal fade"
            id="modal-emergency"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
                <div className="modal-body pb-0">
                <p className="modal-msg text-blue">
                    You have successfully dialed Emergency 112!
                </p>
                </div>
                <div className="modal-footer border-0">
                <button
                    type="button"
                    className="modal-button"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                </div>
            </div>
            </div>
        </div>
        <div
            className="modal fade"
            id="modal-pharmacy"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
                <div className="modal-body pb-0">
                <p className="text-blue font_20 font_500 text-center">
                    Go to pharmacy
                </p>
                <a href="https://medplusnig.com/" className="link_join text-blue">
                    <span className="text-blue pe-2">Medplus-</span>
                    https://medplusnig.com/
                </a>
                <a href="https://healthplusnigeria.com/" className="link_join text-blue">
                    <span className="text-blue pe-2">Healthplus-</span>
                    https://healthplusnigeria.com/
                </a>
                <a href="https://primuspharmacy.com/" className="link_join text-blue">
                    <span className="text-blue pe-2">Primus-</span>
                    https://primuspharmacy.com/
                </a>
                <a href="https://alphapharmacy.com.ng/" className="link_join text-blue">
                    <span className="text-blue pe-2">Alpha-</span>
                    https://alphapharmacy.com.ng/
                </a>
                {/* <a href="https://bydowpharmacy.com/" className="link_join text-blue">
                    <span className="text-blue pe-2">bydowpharmacy-</span>
                    https://bydowpharmacy.com/
                </a> */}
                </div>
                <div className="modal-footer border-0">
                <button
                    type="button"
                    className="modal-button"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                </div>
            </div>
            </div>
        </div>
        <div
            className="modal fade"
            id="modal-download"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content modal-custom">
                <div className="modal-body pb-0">
                <p className="modal-msg text-blue">
                    You don't have any details to download!
                </p>
                </div>
                <div className="modal-footer border-0">
                <button
                    type="button"
                    className="modal-button"
                    data-bs-dismiss="modal"
                >
                    Close
                </button>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default DoctorHome;