import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { login } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import { loginStoreData } from '../../patient/common/RedirectPathMange';

function App() {

//   useEffect(() => {
//     if (localStorage.getItem("user_login", true)) {
//       let path= window.location.protocol+"//"+window.location.host+"/home" 
//         window.location.href = path;
//     }

// }, [])
const [passwordVisible, setPasswordVisible] = useState(false);
const togglePasswordVisibility = () => {
  setPasswordVisible((prev) => !prev);
};

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email('Please enter valid email')
      .required('Please enter email'),
    password: Yup.string()
      .required('Please enter password')
      .min(6, 'Password must be at least 6 characters'),
    login_id: Yup.string()
      .required('Please enter login id')
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
          Swal.showLoading()
      }
  })
    var newData = {
      "email": data.email,
      "password": data.password,
      "code": data.login_id
    }

    login(newData).then((res) => {
      var response = JSON.parse(res)
      console.log("response-->",res)
      Swal.close()
      if (response.code == process.env.REACT_APP_SUCCESS) {
        console.log('response.data',JSON.stringify(response.data))
        loginStoreData(response.data) 
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });
  }

  return (
    <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >
      <main className="msh--main bg-img" style={{ height: 100 + 'vh' }}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-6 col-xl-5 col-xxl-5 col-12">
              <div className="login-sec">
                <div className="logo-size">
                  <img src={process.env.PUBLIC_URL + "/assets/images/logo--a.png"} alt="logo" />
                </div>
                <p className="font_26 font_600 text-center mb-md-4 mt-md-3">Login</p>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/mail.png"} alt="mail" />
                  </span>
                  <input type="email" {...register('email')} className="form-control ps-0" id="email" placeholder="Enter your email" />
                  <div className="invalid-feedback">{errors.email?.message}</div>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/lock.png"} alt="lock" />
                  </span>
                  <input type={passwordVisible ? 'text' : 'password'} {...register('password')} className="form-control ps-0" id="password" placeholder="Enter your password" />
                  <span className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                    <i className={`fa-solid fa-eye${passwordVisible ? '' : '-slash'}`}></i>
                  </span>
                  <div className="invalid-feedback">{errors.password?.message}</div>
                </div>
                <div className="input-group mb-3">
                  <span className="input-group-text ps-0" id="basic-addon1">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/id.png"} alt="lock" />
                  </span>
                  <input type="text" {...register('login_id')} className="form-control ps-0" id="login_id" placeholder="Enter your login id" />
                  <div className="invalid-feedback">{errors.login_id?.message}</div>
                </div>
                <div className="d-flex justify-content-between mb-5">
                  <div className="text-end w-100">
                    <Link to="/forgot-password" className="text-blue font_14"><i className="mdi mdi-lock"></i> Forgot your password?</Link>
                  </div>
                </div>
                <button className="login-btn w-100 border-0 mb-3" type="submit">Login now</button>
                <p className="text-center font_14 text-dark mb-0">
                  Not a member?<Link to="/signup" className="text-blue ps-1">Signup now</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    </form>
  );
}

export default App;