import axios from "axios";
import CryptoJS from 'crypto-js';
import {logOutRedirectCall} from '../pages/patient/common/RedirectPathMange'

var key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_KEY);
var iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_API_ENC_IV);

const showMessage = (msg) => {
  // console.log(msg)
}

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers : { 
        'api-key' : CryptoJS.AES.encrypt(process.env.REACT_APP_API_KEY, key, { iv: iv }).toString(),
        'accept-language' : 'en',
        'Content-Type' : 'text/plain',
    }
});

 

// Body Encryption Request
axiosClient.interceptors.request.use(function (request) {
  // console.log('request.data: ', request.data);
  request.data = bodyEncryption(request.data, true)
    if(localStorage.getItem("user_token") !== undefined || localStorage.getItem("user_token") !== null){
      request.headers['token']=bodyEncryption(localStorage.getItem("user_token"))
      // console.log(request.headers['token']);
    }
  return request;
});
  
axiosClient.interceptors.response.use(
  function (response) {
    response = bodyDecryption(response.data);
    if(response.code!=process.env.REACT_APP_SUCCESS){
      showMessage(response.message)
    }
    return response;
  }, 
  function (error) {
    let res = error.response;
    console.log(error)
   
    if (res.status == process.env.REACT_APP_UNAUTHORIZED) {
      logOutRedirectCall()
    }else if(res.status == process.env.REACT_APP_VALIDATION_ERROR){
      const response = bodyDecryption(res.data);
      return response 
    }else if(res.status == process.env.REACT_APP_DUPLICATE_VALUE){
      const response = bodyDecryption(res.data);
      return response 
    }else{
      //console.error("Looks like there was a problem. Status Code: " + res.status);
      return Promise.reject(error);
    }
    
  }
);  

function bodyEncryption(request, isStringify)
{
    var request = (isStringify) ? JSON.stringify(request) : request;
    var encrypted = CryptoJS.AES.encrypt(request, key, { iv: iv });
    return encrypted.toString();
}

function bodyDecryption(request)
{
    var decrypted = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
    return decrypted.toString(CryptoJS.enc.Utf8);
}

export {axiosClient};