import React,{useState,useEffect,useRef} from 'react'
import AWS from 'aws-sdk';
import Swal from 'sweetalert2';
import { editDoctorProfile, getDoctorProfile, getCountryList, getDoctorSpecialities, getIsabelCountryList } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import $ from 'jquery';
import moment from 'moment';
import Select from 'react-select';


function DoctorProfile() {
    const [profileDetails, setProfileDetails] = useState(null)
    const [countryList, setCountryList] = useState([])
    const [doctorSpecialities,setDoctorSpecialities] = useState([])
    const [selectedSpecialities,setSelectedSpecialities] = useState("")
    const [image, setImage] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);
    const [uploadFinancials, setUploadFinancials] = useState(null);
    const [dept,setDept] = useState("1")
    const [branch,setBranch] = useState("1")
    const [businessType,setBusinessType] = useState("1")
    const [disciplinaryIssue,setDisciplinaryIssue] = useState("1")
    const [litigation,setLitigation] = useState("1")
    const [isabelCountryList, setIsabelCountryList] = useState([]);
    const [countryId, setCountryId] = useState("");

    const handleChange = (event) => {
        console.log(event.target.name);
        if (event.target.name == 'departments') {
            setDept(event.target.value);
        }
        if (event.target.name == 'branches') {
            setBranch(event.target.value);
        }
        if (event.target.name == 'business') {
            console.log(event.target.value);
            setBusinessType(event.target.value);
        }
        if (event.target.name == 'disciplinaryIssue'){
            setDisciplinaryIssue(event.target.value);
        }
        if (event.target.name == 'litigation'){
            setLitigation(event.target.value);
        }

    };

    const uploadData = async (file,folder_type) => {
        const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
        const REGION = process.env.REACT_APP_AWS_S3_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
            region: REGION,
          });
          const ext = file.name.split('.').pop()
          const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
          });

        try {
            const newFileName = new Date().getTime() + '.'+ext;
            const params = {
                Bucket: S3_BUCKET + folder_type,
                Key: newFileName,
                Body: file,
                ACL: "public-read",
            };
            return new Promise((resolve, reject) => {
                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(newFileName);
                    }
                });
            });
        } catch (error) {
            console.error("Error in UploadData:", error);
        }
    };

    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getCountryList({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setCountryList(response.data)
            }
            Swal.close()
        })

        getDoctorSpecialities({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                response.data.map((item)=>{
                    const specilityObj = {
                        label: item.name, 
                        value: item.id,
                        icon: item.icon,
                    }
                    doctorSpecialities.push(specilityObj)
                })
            }
            Swal.close()
        })

        getDoctorProfile({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setProfileDetails(response.data)
                //console.log('profileDetails=',profileDetails.profile_image)
                setImage(response.data.profile_image);
                setDept(response.data.departments != null ? response.data.departments : "1");
                setBranch(response.data.branches != null ? response.data.branches : "1");
                setBusinessType(response.data.business_type != null ? response.data.business_type : "1");
                setDisciplinaryIssue(response.data.disciplinary_issues != null ? response.data.disciplinary_issues : "1");
                setLitigation(response.data.litigations != null ? response.data.litigations : "1");
               
                const matchedSpecialitiesSet = new Set(
                    doctorSpecialities
                        .filter(item => response.data.specialityIds.includes(item.value))
                        .map(item => JSON.stringify(item))
                );
                
                const uniqueMatchedSpecialities = Array.from(matchedSpecialitiesSet).map(item => JSON.parse(item));
                
                setSelectedSpecialities(uniqueMatchedSpecialities);
                if(uniqueMatchedSpecialities.length > 0){
                    setValue("specializations", uniqueMatchedSpecialities[0].value, { shouldValidate: true });  
                }
                setCountryId(response.data.country_id)
                if(response.data.country_id != 0){
                    setValue('isabel_country_name', response.data.country_id);
                }
            }
            Swal.close()
        })

        getIsabelCountryList({}).then((response) => {
            response = JSON.parse(response)
            console.log('response: ', response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                // const country = response.data.find(country => country.country_id == countryId);
                // console.log('country: ', country);
                // setSelectedCountry(country);
                setIsabelCountryList(response.data)
            }
            Swal.close()
        })

    }, [])

    const handleSpecialityChange = (data) => {
        if(data.length > 0){
            setSelectedSpecialities(data)
            setValue("specializations", data[0].value, { shouldValidate: true });
        } else {
            setSelectedSpecialities("")
            setValue("specializations", "", { shouldValidate: false });
        }
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setUploadImage(event.target.files[0]);
        }
    }

    const handleFinancial = (event) => {
        if (event.target.files && event.target.files[0]) {
            setUploadFinancials(event.target.files[0]);
        }
    }

    
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required("Please enter first name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "first name must be at most 128 character"),
        middle_name: Yup.string()
            .required("Please enter middle initial")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "middle initial must be at most 128 character"),
        last_name: Yup.string()
            .required("Please enter last name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "last name must be at most 128 character"),
        country_name: Yup.string()
            .optional("Please enter country name"),
        state_name: Yup.string().optional("Please enter state name"),
        city_name: Yup.string().optional("Please enter city name"),
        isabel_country_name: Yup.string()
            .required("Please select isabel country name"),
        age: Yup.number()
            .optional("Please enter age"),
        ceo: Yup.string()
            .optional("Please enter ceo name"),
        tax_id: Yup.string()
            .optional("Please enter tax id"),
        address: Yup.string()
            .optional("Please enter address"),
        hospital_or_clinic: Yup.string()
            .optional("Please enter hospital or clinic"),
        specializations: Yup.string()
            .optional("Please select specializations"),
        departments: Yup.string()
            .optional("please select departments option"),
        dept_desc: Yup.string().when('departments', {
            is: (value) => value === '1',
            then: Yup.string().optional('Please enter department description'),
            otherwise: Yup.string(),
            }),
        branches: Yup.string()
            .optional("Please select branches option"),
        branch_desc: Yup.string().when('branches', {
            is: (value) => value === '1',
            then: Yup.string().optional('Please enter branch description'),
            otherwise: Yup.string(),
            }),               
        staff: Yup.string()
            .optional("Please enter list of staff"),
        registration_no: Yup.string()
            .optional("Please enter corporate registration no"),
        business: Yup.string()
            .optional("Please select type of business"),
        year_business: Yup.string()
            .optional("Please enter year in business"),
        disciplinaryIssue: Yup.string()
            .optional("Please select disciplinary issue"),
        disciplinary_desc: Yup.string().when('disciplinaryIssue', {
            is: (value) => value === '1',
            then: Yup.string().optional('Please enter disciplinary issue description'),
            otherwise: Yup.string(),
          }),
        litigation: Yup.string()
            .optional("Please select litigation"),
        litigation_desc: Yup.string().when('litigation', {
            is: (value) => value === '1',
            then: Yup.string().optional('Please enter litigation description'),
            otherwise: Yup.string(),
            }),
        licenseNumber: Yup.string()
            .required("Please enter license number"),
        license_expiration: Yup.date()
            .typeError("Please select license expiry date").nullable().optional("Please select license expiry date").min(new Date(), 'License expiry date not in the past'),
        link_to_web: Yup.string()
            .optional("Please enter website link")
            .url("Please enter valid website link "),
        // financials: Yup.mixed()
        //     .test(
        //         'fileRequired',
        //         'Please upload a financials file',
        //         (value) => {
        //             if (value && value.length > 0) {
        //                 return true; // File is provided, the field is valid
        //             }
        //             return false; // No file provided, the field is required
        //         }
        //     )
        //     .test(
        //         'fileType',
        //         'Only PDF or Images files are allowed.',
        //         (value) => {
        //             if (value && value.length > 0) {
        //                 // console.log(value)
        //                 const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
        //                 return allowedTypes.includes(value[0].type);
        //             }
        //         }
        //     ),
        financials: Yup.mixed()
            .test(
                'fileType',
                'Only PDF or Images files are allowed.',
                (value) => {
                    // Check if a file is provided
                    if (value && value.length > 0) {
                        const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
                        return allowedTypes.includes(value[0].type);
                    }

                    // No file provided, it is considered valid as the field is optional
                    return true;
                }
            ),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState, setValue } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = async (data) => {
        if (uploadImage != null) {
            console.log("uploadImage------",uploadImage);
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadData(uploadImage,"/profile_images").then((newImage)=>{
                Swal.close()
                console.log('newImagenewImagenewImage',newImage)
                profileDetails.profile_image_name = newImage;
            }).catch((e)=>{
                Swal.close()
                
            });
        }

        if (uploadFinancials != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadData(uploadFinancials,"/financials_uploads").then((newFile)=>{
                Swal.close()
                console.log('newFilenewFilenewFile',newFile)
                profileDetails.financials_name = newFile;
            }).catch((e)=>{
                Swal.close()
                
            });
        }
        const specialitiesIdsArray = selectedSpecialities.map(item => item.value);
        var params = {
            "first_name": data.first_name,
            "middle_name": data.middle_name,
            "last_name": data.last_name,
            "country": data.country_name,
            "state": data.state_name,
            "city": data.city_name,
            "country_id": parseInt(data.isabel_country_name),
            "age": data.age,
            "ceo": data.ceo,
            "tax_id": data.tax_id,
            "address": data.address,
            "hospital_or_clinic": data.hospital_or_clinic,
            "speciality": specialitiesIdsArray,
            "departments": data.departments,
            "department_description":dept == 1 ? data.dept_desc : "",
            "branches": data.branches,
            "branch_description":branch == 1 ? data.branch_desc : "",
            "staff_name": data.staff,
            "corporate_reg_no": data.registration_no,
            "business_type": data.business,
            "years_in_business": data.year_business,
            "disciplinary_issues": data.disciplinaryIssue,
            "disciplinary_issues_description": disciplinaryIssue == 1 ? data.disciplinary_desc : "",
            "litigations": data.litigation,
            "litigations_description": litigation == 1 ? data.litigation_desc : "",
            "license_no": data.licenseNumber,
            "license_expiration_date": moment(data.license_expiration).format('YYYY-MM-DD'),
            "website_link": data.link_to_web,
            "financials": profileDetails.financials_name,
            "profile_image": profileDetails.profile_image_name,
        }
        console.log('params', params)
        editDoctorProfile(params).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                localStorage.setItem("user_name",data.first_name);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                // setTimeout(() => {
                //     navigate("/")
                // }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if (image == null) return <div className="container"><div className="mt-5"></div></div>
  return (
    <>
        <main className="bg-color h-set">
            <Header/>
            {/* <div className="banner-img">
                <p className="font_28 font_600 text-center text-center mb-0">Health Feedback Form</p>
            </div> */}
            <section className="share-medical">
                <div className="container ">
                <div className="">
                    <p className="font_22 font_600 mb-0">My Profile</p>
                    <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                        <Link to="/" className="font_14">
                            Home
                        </Link>
                        </li>
                        <li className="breadcrumb-item active font_14" aria-current="page">
                        My profile
                        </li>
                    </ol>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <div className="card-profile feedback-sec">
                        <p className="font_22  font_600  mb-0">Personal details</p>
                        <p className="text-gray font_15">
                        Manage your personal information.
                        </p>
                        <hr />
                        <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row justify-content-center">
                        <div className="col-lg-4">
                            <div className="card-profile shadow-none p-3 border-0 mb-2">
                            <p className="text-center font_18 font_500 text-gray">
                                Profile picture
                            </p>
                            <div className="upload-files-container">
                                <div className="card-pic mb-3">
                                <img
                                    // src={process.env.PUBLIC_URL + "/assets/images/doctor-listing-1.jpg"}
                                    src={image}
                                    alt=""
                                />
                                </div>
                                <div className="drag-file-area">
                                <label className="label">
                                    {" "}
                                    <span className="browse-files">
                                    <input
                                        type="file"
                                        className="default-file-input d-none"
                                        onChange={onImageChange}
                                    />
                                    <span className="browse-files-text">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/upload-img.svg"} alt="" />
                                    </span>{" "}
                                    </span>{" "}
                                </label>
                                </div>
                            </div>
                            <p className="title mb-0">{profileDetails.first_name+" "+profileDetails.middle_name+" "+profileDetails.last_name}</p>
                            </div>
                        </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 ">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                First name
                                </label>
                                <input
                                type="text"
                                {...register('first_name')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails.first_name}
                                placeholder="Please enter first name"
                                />
                                <div className="invalid-feedback">{errors.first_name?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Middle Initial
                                </label>
                                <input
                                type="text"
                                {...register('middle_name')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.middle_name}
                                placeholder="Please enter middle initial"
                                />
                                <div className="invalid-feedback">{errors.middle_name?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Last name
                                </label>
                                <input
                                type="text"
                                {...register('last_name')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.last_name}
                                placeholder="Please enter last name"
                                />
                                <div className="invalid-feedback">{errors.last_name?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Country
                                </label>
                                <input
                                type="text"
                                {...register('country_name')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.country}
                                placeholder="Please enter country name"
                                />
                                <div className="invalid-feedback">{errors.country_name?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                State
                                </label>
                                <input
                                type="text"
                                {...register('state_name')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.state}
                                placeholder="Please enter state name"
                                />
                                <div className="invalid-feedback">{errors.state_name?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                City
                                </label>
                                <input
                                type="text"
                                {...register('city_name')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.city}
                                placeholder="Please enter city name"
                                />
                                <div className="invalid-feedback">{errors.city_name?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Isabel Country</label>
                                    {countryId != 0 && isabelCountryList.length > 0 ? 
                                    <select className="form-control" {...register('isabel_country_name')} defaultValue={countryId}>
                                        <option value="">Select Country</option>
                                        {isabelCountryList && isabelCountryList.map((item) => (
                                            <option key={item.country_name} value={item.country_id}>
                                                {item.country_name}
                                            </option>
                                        ))}
                                    </select> : 
                                    ((countryId == "" || countryId == 0) && isabelCountryList.length > 0) ? 
                                    <select className="form-control" {...register('isabel_country_name')} defaultValue="">
                                        <option value="">Select Country</option>
                                        {isabelCountryList && isabelCountryList.map((item) => (
                                            <option key={item.country_name} value={item.country_id}>
                                                {item.country_name}
                                            </option>
                                        ))}
                                    </select> : "" }
                                    <div className="invalid-feedback">{errors.isabel_country_name?.message}</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="mb-3">
                                    <label htmlFor="exampleFormControlInput1" className="form-label">Age</label>
                                    <input type="number" {...register('age')} className="form-control" defaultValue={profileDetails.age} placeholder="Please enter age" />
                                    <div className="invalid-feedback">{errors.age?.message}</div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                CEO
                                </label>
                                <input
                                type="text"
                                {...register('ceo')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.ceo}
                                placeholder="Please enter ceo name"
                                />
                                <div className="invalid-feedback">{errors.ceo?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Tax I.D.
                                </label>
                                <input
                                type="text"
                                {...register('tax_id')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.tax_id}
                                placeholder="Please enter tax id"
                                />
                                <div className="invalid-feedback">{errors.tax_id?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Address
                                </label>
                                <input
                                type="text"
                                {...register('address')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.address}
                                placeholder="Please enter address"
                                />
                                <div className="invalid-feedback">{errors.address?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Hospital_Or_Clinic
                                </label>
                                <input
                                type="text"
                                {...register('hospital_or_clinic')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.hospital_or_clinic}
                                placeholder="Please enter hospital or clinic"
                                />
                                <div className="invalid-feedback">{errors.hospital_or_clinic?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                    htmlFor="exampleFormControlInput1"
                                    className="form-label"
                                    >
                                    Specializations
                                </label>
                               
                                <Select
                                    isMulti
                                    name="specializations"
                                    {...register('specializations')}
                                    options={doctorSpecialities}
                                    value={selectedSpecialities}
                                    onChange={handleSpecialityChange}
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    menuPortalTarget={document.body}
                                    />
                                {/* <input type="hidden" {...register('specializations')} value={selectedSpecialitiesValue} /> */}

                                {/* <select className="form-control" ref={selectRef} {...register('specializations')}>
                                    {doctorSpecialities.map((item, key) => (
                                    <option key={key} value={item.name}>
                                        {item.name}
                                    </option>
                                    ))}
                                </select> */}
                                <div className="invalid-feedback">{errors.specializations?.message}</div>
                                
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="mb-3">
                                    <label className="form-label">Do you have departments?</label>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="departments"
                                                id="deptYes"
                                                {...register('departments')}
                                                value="1"
                                                checked={dept == 1}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="deptYes">
                                                Yes
                                            </label>
                                        </div>

                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="departments"
                                                id="deptNo"
                                                {...register('departments')}
                                                value="0"
                                                checked={dept == 0}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="deptNo">
                                                No
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">{errors.departments?.message}</div>
                                    </div>
                                </div>
                            </div>
                            {dept == 1 ?
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="dept_desc"
                                className="form-label"
                                >
                                Department Description
                                </label>
                                <input
                                type="text"
                                {...register('dept_desc')}
                                className="form-control"
                                name="dept_desc"
                                id="dept_desc"
                                defaultValue={profileDetails && profileDetails.department_description}
                                placeholder="Please enter department description"
                                />
                                <div className="invalid-feedback">{errors.dept_desc?.message}</div>
                            </div>
                            </div> : ""}
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="mb-3">
                                    <label className="form-label">Do you have branches?</label>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="branches"
                                                id="branchYes"
                                                {...register('branches')}
                                                value="1"
                                                checked={branch == 1}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="branchYes">
                                                Yes
                                            </label>
                                        </div>

                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="branches"
                                                id="branchNo"
                                                {...register('branches')}
                                                value="0"
                                                checked={branch == 0}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="branchNo">
                                                No
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">{errors.branches?.message}</div>
                                    </div>
                                </div>
                            </div>
                            {branch == 1 ?
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="branch_desc"
                                className="form-label"
                                >
                                Branch Description
                                </label>
                                <input
                                type="text"
                                {...register('branch_desc')}
                                className="form-control"
                                name="branch_desc"
                                id="branch_desc"
                                defaultValue={profileDetails && profileDetails.branch_description}
                                placeholder="Please enter branch description"
                                />
                                <div className="invalid-feedback">{errors.branch_desc?.message}</div>
                            </div>
                            </div> : ""}
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                List of staff
                                </label>
                                <input
                                type="text"
                                {...register('staff')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.staff_name}
                                placeholder="Please enter List of staff"
                                />
                                <div className="invalid-feedback">{errors.staff?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Corporate registration no.
                                </label>
                                <input
                                type="text"
                                {...register('registration_no')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.corporate_reg_no}
                                placeholder="Please enter corporate registration no"
                                />
                                <div className="invalid-feedback">{errors.registration_no?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="mb-3">
                                    <label className="form-label">Type of business</label>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="business"
                                                id="privateBusiness"
                                                {...register('business')}
                                                value="1"
                                                checked={businessType == 1}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="privateBusiness">
                                                Private
                                            </label>
                                        </div>

                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="business"
                                                id="publicBusiness"
                                                {...register('business')}
                                                value="2"
                                                checked={businessType == 2}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="publicBusiness">
                                                Public
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">{errors.business?.message}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Year in business
                                </label>
                                <input
                                type="number"
                                {...register('year_business')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.years_in_business}
                                placeholder="Please enter year in business"
                                />
                                <div className="invalid-feedback">{errors.year_business?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="mb-3">
                                    <label className="form-label">Disciplinary Issues</label>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="disciplinaryIssue"
                                                id="issuesYes"
                                                {...register('disciplinaryIssue')}
                                                value="1"
                                                checked={disciplinaryIssue == 1}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="issuesYes">
                                                Yes
                                            </label>
                                        </div>

                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="disciplinaryIssue"
                                                id="issuesNo"
                                                {...register('disciplinaryIssue')}
                                                value="0"
                                                checked={disciplinaryIssue == 0}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="issuesNo">
                                                No
                                            </label>
                                        </div>
                                        <div className="invalid-feedback">{errors.disciplinaryIssue?.message}</div>
                                    </div>
                                </div>
                            </div>
                            {disciplinaryIssue == 1 ? 
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="disciplinary_desc"
                                className="form-label"
                                >
                                Disciplinary Issue Description
                                </label>
                                <input
                                type="text"
                                {...register('disciplinary_desc')}
                                className="form-control"
                                name="disciplinary_desc"
                                id="disciplinary_desc"
                                defaultValue={profileDetails && profileDetails.disciplinary_issues_description}
                                placeholder="Please enter disciplinary issue description"
                                />
                                <div className="invalid-feedback">{errors.disciplinary_desc?.message}</div>
                            </div>
                            </div> : ""}
                            <div className="col-lg-6 col-xl-6 col-12">
                                <div className="mb-3">
                                    <label className="form-label">Litigation</label>
                                    <div className="d-flex gap-3 align-items-center">
                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="litigation"
                                                id="litigationYes"
                                                {...register('litigation')}
                                                value="1"
                                                checked={litigation == 1}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="litigationYes">
                                                Yes
                                            </label>
                                        </div>

                                        <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="litigation"
                                                id="litigationNo"
                                                {...register('litigation')}
                                                value="0"
                                                checked={litigation == 0}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="litigationNo">
                                                No
                                            </label>
                                        </div>

                                        {/* <div className="form-check form-custom d-flex align-items-center gap-1">
                                            <input
                                                className="form-check-input radio"
                                                type="radio"
                                                name="litigation"
                                                id="litigationCurrent"
                                                {...register('litigation')}
                                                value="3"
                                                checked={litigation == 3}
                                                onChange={handleChange}
                                            />
                                            <label className="form-check-label" htmlFor="litigationCurrent">
                                                Current
                                            </label>
                                        </div> */}
                                        <div className="invalid-feedback">{errors.litigation?.message}</div>
                                    </div>
                                </div>
                            </div>
                            {litigation == 1 ? 
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="litigation_desc"
                                className="form-label"
                                >
                                Litigation Description
                                </label>
                                <input
                                type="text"
                                {...register('litigation_desc')}
                                className="form-control"
                                name="litigation_desc"
                                id="litigation_desc"
                                defaultValue={profileDetails && profileDetails.litigations_description}
                                placeholder="Please enter litigation description"
                                />
                                <div className="invalid-feedback">{errors.litigation_desc?.message}</div>
                            </div>
                            </div> : ""}
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                License Number
                                </label>
                                <input
                                type="text"
                                {...register('licenseNumber')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.license_no}
                                placeholder="Please enter license number"
                                />
                                <div className="invalid-feedback">{errors.licenseNumber?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                License Expiry Date
                                </label>
                                <input
                                type="date"
                                {...register('license_expiration')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.license_expiration_date}
                                placeholder="Please enter license expriration date"
                                />
                                <div className="invalid-feedback">{errors.license_expiration?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Link to Website
                                </label>
                                <input
                                type="text"
                                {...register('link_to_web')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                defaultValue={profileDetails && profileDetails.website_link}
                                placeholder="Please enter website link"
                                />
                                <div className="invalid-feedback">{errors.link_to_web?.message}</div>
                            </div>
                            </div>
                            <div className="col-lg-6 col-xl-6 col-12">
                            <div className="mb-3">
                                <label
                                htmlFor="exampleFormControlInput1"
                                className="form-label"
                                >
                                Financials
                                </label>
                                <input
                                type="file"
                                {...register('financials')}
                                className="form-control"
                                id="exampleFormControlInput1"
                                // defaultValue={profileDetails && profileDetails.financials_name}
                                placeholder="Please select image or pdf"
                                onChange={handleFinancial}
                                />
                                <div className="invalid-feedback">{errors.financials?.message}</div>
                            </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                            {/* <a href="" className="submit-btn mt-3">Update</a> */}
                            <button type="submit" className="submit-btn mt-3 w-100">Update</button>
                            </div>
                        </div>
                        </form>
                    </div>
                    </div>
                </div>
                </div>
            </section>
            <Footer/>
        </main>
    </>
  )
}

export default DoctorProfile;
