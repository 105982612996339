import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import MeetingRejoin from '../components/MeetingRejoin';
import MonteChat from '../components/MonteChat';
import StarpilotChat from '../components/StarpilotChat';
import { useNavigate, useLocation } from 'react-router-dom';
const AuthLayout = ({chat, setChat}) => {
  const location = useLocation();
  console.log("location--->",location.pathname)
  return (
    <>
      <div
              >
                { (!location.pathname.includes(`/meeting/`) && location.pathname != "/meeting" &&  location.pathname != "/meetingv2" && location.pathname != "Meetingmini") && (  <MonteChat chat={chat} setChat={setChat} />)  }
                   
                {/* { (parseInt(localStorage.getItem("user_login_type_role")) == 2) && (location.pathname == "/meeting") && (<StarpilotChat chat={chat} setChat={setChat} />)  } */}
                { (!location.pathname.includes(`/meeting/`) && location.pathname != "/meeting" &&  location.pathname != "/meetingv2" && location.pathname != "Meetingmini") && (  <MeetingRejoin  />)  }
                    
                <Outlet />
              </div>
    </>
  );
};

export default AuthLayout;
