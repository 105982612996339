import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { userSupport } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import AWS from 'aws-sdk';
import { loginStoreData } from '../common/RedirectPathMange';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function App() {
  const [useAiva, setUseAiva] = useState('1');
  const [step, setStep] = useState('1');
  const [formValueDefault, setFormValueDefault] = useState();
  console.log("step==>", (parseInt(step)))
  const [recommendAiva, setRecommendAiva] = useState('1');
  const [comments, setComments] = useState('');
  const [rating, setRating] = useState('1');
  const [showModal, setShowModal] = useState(false);



  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    navigate("/doctor/home");
  };
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
  });



  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
  const { errors } = formState;
  console.log("formValueDefault==>", formValueDefault)






  const uploadData = async (file, folder_type) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
      region: REGION,
    });
    const ext = file.name.split('.').pop()
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    try {
      const newFileName = new Date().getTime() + '.' + ext;
      const params = {
        Bucket: S3_BUCKET + folder_type,
        Key: newFileName,
        Body: file,
        ACL: "public-read",
      };
      return new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(newFileName);
          }
        });
      });
    } catch (error) {
      console.error("Error in UploadData:", error);
    }
  };


  const onSubmit = async (data) => {
    console.log("data", data.file[0]);
    let uploadfile = "";
    if (data.file[0]) {
      await uploadData(data.file[0], "/support_file").then((newImage) => {
        Swal.close()
        console.log('newImagenewImagenewImage', newImage)
        let imgUrl = newImage;
        uploadfile = `https://msh-dev.s3.amazonaws.com/support_file/${imgUrl}`
      }).catch((e) => {
        Swal.close()
      });
    }
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    userSupport({
      message: data.message,
      subject: data.subject,
      file: uploadfile
    }).then((res) => {
      Swal.close()
      var response = JSON.parse(res)
      console.log('response=>', response)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        reset()
        openModal();
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });
  }

  return (
    <>
      <main className="bg-color">
        <Header />
        <section className="share-medical">
          <div className="container">
            <div className="">
              <p className="font_22 font_600 mb-0">Support</p>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/doctor/home" className="font_14">
                      Home
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active font_14"
                    aria-current="page"
                  >
                    Support
                  </li>
                </ol>
              </nav>
            </div>
            {showModal && (
              <Modal show={openModal} onHide={closeModal} centered id="modal-emergency" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content modal-custom">
                    <div className="modal-body pb-0">
                      <p className="modal-msg text-blue">Thank You for Your Submission!</p>
                    </div>
                    <div className="modal-footer border-0">
                      <button type="button" className="modal-button" onClick={closeModal}>
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
            )}
            <div className="form-fields">
              <div className="row justify-content-center pt-3 pb-5">
                <div className="col-lg-10 col-xl-10 col-12">
                  {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                  <div className="feedback-sec profle_input_fields">
                    {
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="mt-3">
                          <p className="font_22  font_600 text-blue  mb-0">Support</p>
                          <hr className="mb-4" />
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="email1" className="form-label">Subject</label>
                              <input defaultValue={""} required={true} type="text" {...register('subject')} className="form-control" placeholder="Write a Subject here" />
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="exampleFormControlInput1" className="form-label">
                                Message
                              </label>
                              <textarea
                                className="form-control"
                                required={true}
                                placeholder="Write a Message here"
                                id="floatingTextarea2"
                                style={{ height: 100 }}
                                defaultValue={""}
                                {...register('message')}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-xl-12 col-12">
                            <div className="mb-3">
                              <label htmlFor="email1" className="form-label">Add Image</label>
                              <input type="file" accept=".png, .jpg, .jpeg" {...register('file')} className="form-control" />
                            </div>
                          </div>
                        </div>
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-6">
                            <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                          </div>
                          {/* <div className=" col-md-6">
                            <button type="button" className="submit-btn mt-3 w-100" onClick={() => setStep("7")}>Previous</button>
                          </div> */}
                        </div>
                      </form>
                    }


                    {/* <div className="row justify-content-center">
                          <div className="col-12 col-md-6">
                            <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                          </div>
                        </div> */}




                    {/* {step == "5" && <>
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-6">
                            <button type="submit" className="submit-btn mt-3 w-100">Submit</button>
                          </div>
                        </div>
                      </>} */}
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </main>
    </>
  );
}

export default App;