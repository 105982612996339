import React, { useState, useEffect } from 'react';
import { getStarPilotMonteChatHistory, getSuggestionBoxChatHistory, getPrescriptionHistory } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { useParams, useNavigate, Link } from 'react-router-dom';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';


function MeetingStatus() {
  const navigate = useNavigate();
  const [starpilotChat, setStarpilotChat] = useState([]);
  const [suggestionBoxChat, setSuggestionBoxChat] = useState([]);
  const [tempPrescription, setTempPrescription] = useState([]);
  const [mainPrescription, setMainPrescription] = useState([]);
  const { meetingId } = useParams();
  console.log('meetingId===>', meetingId)

  useEffect(() => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })

    getStarPilotMonteChatHistory({ "meeting_id": meetingId }).then((res) => {
      // console.log('response===>', res)
      var response = JSON.parse(res);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        if (response.data.response && response.data.response.length > 0) {
          setStarpilotChat((prev) => ([...response.data.response, ...prev]));
        } else {
          setStarpilotChat([])
        }
        // Swal.close();
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });



    //setTempPrescription,  setMainPrescription
    getPrescriptionHistory({ "meeting_id": meetingId }).then((res) => {
      // console.log('response===>', res)
      Swal.close();
      var response = JSON.parse(res);
      console.log('response 3434===>', response.data)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        // prescripitonTemp, prescripitonMain:
        if (response.data.prescripitonTemp && response.data.prescripitonTemp.length > 0) {
          setTempPrescription((prev) => ([...response.data.prescripitonTemp, ...prev]));
        } else {
          setTempPrescription([])
        }
        if (response.data.prescripitonMain && response.data.prescripitonMain.length > 0) {
          setMainPrescription((prev) => ([...response.data.prescripitonMain, ...prev]));
        } else {
          setMainPrescription([])
        }
        // Swal.close();
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });




    getSuggestionBoxChatHistory({ "meeting_id": meetingId }).then((res) => {
      // console.log('response===>', res)
      Swal.close();
      var response = JSON.parse(res);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        if (response.data.response && response.data.response.length > 0) {
          setSuggestionBoxChat((prev) => ([...response.data.response, ...prev]));
        } else {
          setSuggestionBoxChat([])
        }
        // Swal.close();
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    });



    // Swal.close();
  }, []);

  return (
    <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
            <p className="font_22 font_600 mb-0">Meeting Record </p>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/doctor/home" className="font_14">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active font_14" aria-current="page">Meeting Record</li>
              </ol>
            </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="py-3">
                <h4 className="m-0 fw-bold">Star Pilot Chat</h4>
                <div className="bg-light rounded p-3 overflow-auto search-sec" style={{ maxHeight: 400 }}>
                  {
                    starpilotChat.length > 0 && starpilotChat.map((item) => (<div className="py-2">
                      <div className="ans-sub-block">

                        <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                          <div className="aiicon">
                            <img src="/assets/images/logo.png" alt="profile" className="profile" />
                          </div>
                          <div>
                            <p className="mb-2 font_16 text-blue font_600">Monte Sereno</p>
                            <pre className="mb-2 text-justify white-space-pre">
                              {item}
                            </pre>
                            {/* <button className="search-btn" type="button">
                              <i className="fa-solid fa-volume-up text-white" />
                            </button> */}
                            {/* <audio controls="" style={{ height: 0, width: 0, opacity: 0 }}>
                              <source src="" type="audio/mpeg" />
                            </audio> */}
                          </div>
                        </div>
                      </div>
                    </div>))
                  }
                  {
                    starpilotChat.length <= 0 && (<p className="m-0 text-center">No Data Found!</p>)
                  }
                </div>
              </div>
              <div className="py-3">
                <h4 className="m-0 fw-bold">Suggestion Box</h4>
                <div className="bg-light rounded p-3 overflow-auto search-sec" style={{ maxHeight: 400 }}>
                  {suggestionBoxChat.length > 0 && suggestionBoxChat.map((item) => (<div className="py-2">
                    <div className="ans-sub-block">

                      <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                        <div className="aiicon">
                          <img src="/assets/images/logo.png" alt="profile" className="profile" />
                        </div>
                        <div>
                          <p className="mb-2 font_16 text-blue font_600">Monte Sereno</p>
                          <pre className="mb-2 text-justify white-space-pre">
                            {item}
                          </pre>
                          {/* <button className="search-btn" type="button">
                            <i className="fa-solid fa-volume-up text-white" />
                          </button> */}
                          {/* <audio controls="" style={{ height: 0, width: 0, opacity: 0 }}>
                            <source src="" type="audio/mpeg" />
                          </audio> */}
                        </div>
                      </div>
                    </div>
                  </div>))}
                  {
                    suggestionBoxChat.length <= 0 && (<p className="m-0 text-center">No Data Found!</p>)
                  }
                </div>
              </div>
              <div className='py-5'>
              <h4 className="m-0 fw-bold">Prescription</h4>
              <section className="doctorsec-pad share-medical pb-0">
                    <div className="container">
                        <div className="card">
                            <div className="card-body doctor-table-card">
                                <div className="table-responsive">
                                    <table className="doctor-list-table table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="row">Page</th>
                                                <th>Name of Drug</th>
                                                <th>Strength</th>
                                                <th>Strength Type</th>
                                                <th>Unit</th>
                                                <th>Form</th>
                                                <th>Dose</th>
                                                <th>Frequency</th>
                                                <th>Intakes or Applications</th>
                                                <th>Route</th>
                                                <th>Refills</th>
                                                <th>Quantity</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {mainPrescription && mainPrescription.map((item, index) => {
                                                return (
                                                    <tr id={item.id} key={index}>
                                                        <td scope="row">{index + 1}</td>
                                                        <td>
                                                            <h3 className="doc-details">{item.medication_name}</h3>
                                                        </td>
                                                        {/* <td>{item.strength}</td>
                                                        <td>{item.strength_type}</td>
                                                        <td>{item.medicine_type}</td>
                                                        <td>{item.dose}</td>
                                                        <td>{item.frequency}</td>
                                                        <td>{item.intake}</td>
                                                        <td>{item.route}</td>
                                                        <td>{item.refills}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.comments}</td> */}


                                                        <td>{item.strength}</td>
                                                        <td>{item.strength_type}</td>
                                                        <td>{item.units}</td>
                                                        <td>{item.form}</td>
                                                        <td>{item.dose}</td>
                                                        <td>{item.frequency}</td>
                                                        <td>{item.intake}</td>
                                                        <td>{item.route}</td>
                                                        <td>{item.refills}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.comments}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
              </div>
              <div className='py-5'>
              <h4 className="m-0 fw-bold">Temporary Prescription</h4>
              <section className="doctorsec-pad share-medical pb-0">
                    <div className="container">
                        <div className="card">
                            <div className="card-body doctor-table-card">
                                <div className="table-responsive">
                                    <table className="doctor-list-table table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="row">Page</th>
                                                <th>Name of Drug</th>
                                                <th>Strength</th>
                                                <th>Strength Type</th>
                                                <th>Unit</th>
                                                <th>Form</th>
                                                <th>Dose</th>
                                                <th>Frequency</th>
                                                <th>Intakes or Applications</th>
                                                <th>Route</th>
                                                <th>Refills</th>
                                                <th>Quantity</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tempPrescription && tempPrescription.map((item, index) => {
                                                return (
                                                    <tr id={item.id} key={index}>
                                                        <td scope="row">{index + 1}</td>
                                                        <td>
                                                            <h3 className="doc-details">{item.medicine_name}</h3>
                                                        </td>
                                                        {/* <td>{item.strength}</td>
                                                        <td>{item.strength_type}</td>
                                                        <td>{item.medicine_type}</td>
                                                        <td>{item.dose}</td>
                                                        <td>{item.frequency}</td>
                                                        <td>{item.intake}</td>
                                                        <td>{item.route}</td>
                                                        <td>{item.refills}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.comments}</td> */}


                                                        <td>{item.strength}</td>
                                                        <td>{item.strength_type}</td>
                                                        <td>{item.unit}</td>
                                                        <td>{item.form || item.medicine_type}</td>
                                                        <td>{item.dose}</td>
                                                        <td>{item.frequency}</td>
                                                        <td>{item.intakes_applications || item.intake}</td>
                                                        <td>{item.route}</td>
                                                        <td>{item.refills}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.comments}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default MeetingStatus;


