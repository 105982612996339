
const constantFunc = {
  "convertMarkdownLinksToHTML": async (text) => {
    // Replace Markdown links with HTML anchor tags
    // console.log("text====>",text)
    return text.replace(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g, (match, p1, p2) => {
      return `<div class="btnWrpper"><a href="${p2}" style="height: 37px" class="d-inline-flex align-items-center justify-content-center commonBtn" >${p1}</a></div>`;
    });
  },
  "convertMarkdownLinksToText": async (text) => {
    // Replace Markdown links with HTML anchor tags
    // console.log("text====>",text)
    return text.replace(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g, (match, p1, p2) => {
      return `${p1}`;
    });
  }
};

export default constantFunc;