import React, { useEffect, useState, useRef } from 'react';
import { patientMeetingStatusOngoing, checkAppointmentStatusOngoing } from '../api/apiHandler';
import Swal from 'sweetalert2';
import { useNavigate, useLocation } from 'react-router-dom';
const MeetingRejoin = ({ }) => {

  let location = useLocation();
  console.log(location.pathname)
  const navigate = useNavigate();
  const handleJoinMeeting = (patient_data) => {

    if (parseInt(localStorage.getItem("user_login_type_role")) == 2) {
      const joinMeetingObj = {
        "meeting_id": patient_data.meeting_information[0].meeting_id,
        "appointment_id": patient_data.id,
        // "user_id": patient_data.patient_information.id,
        // "name": patient_data.patient_information.patient_full_name,
        // "email": patient_data.patient_information.patient_email,
        "user_id": localStorage.getItem("user_id"),
        "name": localStorage.getItem("user_name"),
        "email": localStorage.getItem("user_email"),
        "role": parseInt(localStorage.getItem("user_login_type_role")),
        "start_meeting": 0,
        "patient_id": patient_data.patient_information.id
      }
      console.log("joinMeetingObj", joinMeetingObj);
      navigate('/meeting', { state: joinMeetingObj })
    } else {
      const joinMeetingObj = {
        "meeting_id": patient_data.meeting_information[0].meeting_id,
        "appointment_id": patient_data.id,
        // "user_id": patient_data.doctor_information.id,
        // "name": patient_data.doctor_information.doctor_full_name,
        // "email": patient_data.doctor_information.doctor_email,
        "user_id": localStorage.getItem("user_id"),
        "name": localStorage.getItem("user_name"),
        "email": localStorage.getItem("user_email"),
        "role": parseInt(localStorage.getItem("user_login_type_role")),
        "start_meeting": 0
      }
      console.log("joinMeetingObj", joinMeetingObj);
      navigate('/meeting', { state: joinMeetingObj })
    }
  }
  if (location.pathname != "/meeting" && location.pathname != "/meetingmini" ) {
    if (parseInt(localStorage.getItem("user_login_type_role")) == 2) {
      patientMeetingStatusOngoing({}).then((res) => {
        var response = JSON.parse(res);
        if (response.code == process.env.REACT_APP_SUCCESS) {
          // const responseData = response.data.response;
          Swal.fire({
            title: `Hello Dr. ${localStorage.getItem("user_name")}, Please Rejoin the Meeting`,
            imageUrl: `${process.env.PUBLIC_URL + "/assets/images/logo.png"}`,
            imageWidth: 150,
            imageHeight: 120,
            showCancelButton: true,
            confirmButtonText: "Join",
            cancelButtonText: "Cancel",
          }).then((result) => {
            // setHandleSweetAlert(false)
            if (result.isConfirmed) {
              handleJoinMeeting(response?.data?.patient_data);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // handleOptionSelection('Option 2');
            }
          });
        }
        else { }
      });
    } else {
      checkAppointmentStatusOngoing({}).then((res) => {
        var response = JSON.parse(res);
        console.log(response)
        if (response.code == process.env.REACT_APP_SUCCESS) {
          // const responseData = response.data.response;
          Swal.fire({
            title: `Hello ${localStorage.getItem("user_name")}, Please Rejoin the Meeting`,
            imageUrl: `${process.env.PUBLIC_URL + "/assets/images/logo.png"}`,
            imageWidth: 150,
            imageHeight: 120,
            showCancelButton: true,
            confirmButtonText: "Join",
            cancelButtonText: "Cancel",
          }).then((result) => {
            // setHandleSweetAlert(false)
            if (result.isConfirmed) {
              handleJoinMeeting(response?.data?.patient_data);
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              // handleOptionSelection('Option 2');
            }
          });
        }
        else { }
      });
    }

  }
  return (
    <> </>);
};

export default MeetingRejoin;
