import React from 'react';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

function ReportGeneration() {
    return (
        <>
            <Header />
            <section className="report-sec">
                <div className="container-fluid">
                    {/* <p className="font_28 text-blue font_500">Wellness overall status</p> */}
                    <div className="row">
                        <div className="col-xxl-2 col-xl-2 col-lg-4 col-12">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div
                                        className="card-report"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal-emergency"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/icon/Emergency call.svg"}
                                            className="icon"
                                            alt="icon"
                                        />
                                        <p className="mb-0 font_18">Dial Emergency call 112</p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div
                                        className="card-report"
                                        data-bs-toggle="modal"
                                        data-bs-target="#modal-pharmacy"
                                    >
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/icon/plus-medical.png"}
                                            className="icon"
                                            alt="icon"
                                        />
                                        <p className="mb-0 font_18">Go to pharmacy</p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="card-report">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/icon/medical-report.png"}
                                            className="icon"
                                            alt="icon"
                                        />
                                        <p className="mb-0 font_18">Initiate online telemedicine</p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="card-report">
                                        <img
                                            src={process.env.PUBLIC_URL + "/assets/images/icon/Download.png"}
                                            className="icon"
                                            alt="icon"
                                        />
                                        <p className="mb-0 font_18">Download &amp; Share</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-8 col-xl-8 col-lg-7 col-12">
                            {/* <div className="body-page">
                            <div className="body-wrapper">
                                <div id="collapse-button" className="d-lg-none">
                                    <img src="assets/images/icon/" className="sidebar-menu" alt="menu">
                                </div>

                            </div>
                        </div> */}
                            <div>
                                <nav>
                                    <div
                                        className="nav nav-tabs custom-tabs"
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        <button
                                            className="nav-link active"
                                            id="nav-home-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-home"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-home"
                                            aria-selected="true"
                                        >
                                            Vital signs
                                        </button>
                                        <button
                                            className="nav-link"
                                            id="nav-profile-tab"
                                            data-bs-toggle="tab"
                                            data-bs-target="#nav-profile"
                                            type="button"
                                            role="tab"
                                            aria-controls="nav-profile"
                                            aria-selected="false"
                                        >
                                            Possible conditions
                                        </button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div
                                        className="tab-pane fade show active"
                                        id="nav-home"
                                        role="tabpanel"
                                        aria-labelledby="nav-home-tab"
                                    >
                                        <div className="tab-sec">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">Heart Rate</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500">138</p>
                                                                <p className="mb-0 font_14">BPM</p>
                                                            </div>
                                                            <div className="result-icon">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/sad.svg"} alt="" />
                                                            </div>
                                                        </div>
                                                        <p className="mb-0">Normal range: 60 - 100</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/lungs.svg"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">Breathing Rate</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500">38</p>
                                                                <p className="mb-0 font_14">BPM</p>
                                                            </div>
                                                            <div className="result-icon">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/smile.svg"} alt="" />
                                                            </div>
                                                        </div>
                                                        <p className="mb-0">Normal range: 60 - 100</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">Stress Level</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500"> - -</p>
                                                                <p className="mb-0 font_14">LEVEL</p>
                                                            </div>
                                                            {/* <div className="result-icon">
                                                            <img src="assets/images/icon/smile.svg" alt="">
                                                        </div> */}
                                                        </div>
                                                        <p className="mb-0">Normal level: 1 - 3</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/hr-variability.png"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">HR Variability</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500"> - -</p>
                                                                <p className="mb-0 font_14">MA</p>
                                                            </div>
                                                            {/* <div className="result-icon">
                                                            <img src="assets/images/icon/smile.svg" alt="">
                                                        </div> */}
                                                        </div>
                                                        <p className="mb-0">Normal range: 55 - 105</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/recovery-ability.svg"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">Recovery Ability</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500"> - -</p>
                                                                <p className="mb-0 font_14">LEVEL</p>
                                                            </div>
                                                            {/* <div className="result-icon">
                                                            <img src="assets/images/icon/smile.svg" alt="">
                                                        </div> */}
                                                        </div>
                                                        <p className="mb-0">Normal level: 1 - 3</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/blood-pressure.svg"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">Blood Pressure</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500"> - -</p>
                                                                <p className="mb-0 font_14">mmHg</p>
                                                            </div>
                                                            {/* <div className="result-icon">
                                                            <img src="assets/images/icon/smile.svg" alt="">
                                                        </div> */}
                                                        </div>
                                                        <p className="mb-0">
                                                            Systolic &lt; 121 Diastolic &lt; 81
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/cells.svg"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">Hemoglobin</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500"> - -</p>
                                                                <p className="mb-0 font_14">g/dL</p>
                                                            </div>
                                                            {/* <div className="result-icon">
                                                            <img src="assets/images/icon/smile.svg" alt="">
                                                        </div> */}
                                                        </div>
                                                        <p className="mb-0">Normal range: 9 - 17</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <div className="report-result-card">
                                                        <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                            <img
                                                                src={process.env.PUBLIC_URL + "/assets/images/icon/blood-type.svg"}
                                                                className="icon"
                                                                alt="heart-rate"
                                                            />
                                                            <p className="mb-0 font_500">Hemoglobin A1c</p>
                                                        </div>
                                                        <div className="d-flex justify-content-between mb-3">
                                                            <div>
                                                                <p className="font_30 mb-0 font_500"> - -</p>
                                                                <p className="mb-0 font_14">%</p>
                                                            </div>
                                                            {/* <div className="result-icon">
                                                            <img src="assets/images/icon/smile.svg" alt="">
                                                        </div> */}
                                                        </div>
                                                        <p className="mb-0">Normal range: 3 - 6</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="tab-pane fade"
                                        id="nav-profile"
                                        role="tabpanel"
                                        aria-labelledby="nav-profile-tab"
                                    >
                                        <div className="tab-sec">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="report-result-card">
                                                        <div>
                                                            <p className="mb-1 font_18 font_500">
                                                                What is your age?
                                                            </p>
                                                        </div>
                                                        <p className="mb-0">28</p>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="report-result-card">
                                                        <div>
                                                            <p className="mb-1 font_18 font_500">
                                                                Are you male, female or other?
                                                            </p>
                                                        </div>
                                                        <p className="mb-0">Male</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-2 col-xl-2 col-lg-3 col-12">
                            <div className="row">
                                <div className="col-lg-12 col-md-6 col-12">
                                    <div className="card-progress-set">
                                        {/* <div className="progress-set">

                                </div> */}
                                        <div id="handle2"></div>
                                        <p className="font_30 font_500 text-center mb-0 mt-2">Low</p>
                                        <p className="mb-0 font_14 font_600 text-center">
                                            The measured indicators are not intended for medical use
                                        </p>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-6 col-12">
                                    <p className="text-center">8:35:43 AM - 9/20/2023</p>
                                    <div className="info-sec">
                                        <p className="font_22">Information</p>
                                        <p className="font_16 mb-1">
                                            <span className="font_500">Sex:</span> Male
                                        </p>
                                        <p className="font_16 mb-1">
                                            <span className="font_500">Age:</span> 36
                                        </p>
                                        <p className="font_16 mb-1">
                                            <span className="font_500">Pregnant:</span> No
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
            <div
                className="modal fade"
                id="modal-emergency"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-custom">
                        {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
                        <div className="modal-body pb-0">
                            <p className="modal-msg text-blue">
                                You have successfully dialed Emergency 112!
                            </p>
                        </div>
                        <div className="modal-footer border-0">
                            <button
                                type="button"
                                className="modal-button"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="modal-pharmacy"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-custom">
                        {/* <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Modal title</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div> */}
                        <div className="modal-body pb-0">
                            <p className="text-blue font_20 font_500 text-center">
                                Go to pharmacy
                            </p>
                            {/* <a href="https://medplusnig.com/" className="link_join text-blue">
                    <span className="text-blue pe-2">Medplus-</span>
                    https://medplusnig.com/
                </a> */}
                            <a href="https://medplusnig.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">Medplus-</span>
                                https://medplusnig.com/
                            </a>
                            <a href="https://healthplusnigeria.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">Healthplus-</span>
                                https://healthplusnigeria.com/
                            </a>
                            <a href="https://primuspharmacy.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">Primus-</span>
                                https://primuspharmacy.com/
                            </a>
                            <a href="https://alphapharmacy.com.ng/" className="link_join text-blue">
                                <span className="text-blue pe-2">Alpha-</span>
                                https://alphapharmacy.com.ng/
                            </a>
                            {/* <a href="https://bydowpharmacy.com/" className="link_join text-blue">
                    <span className="text-blue pe-2">bydowpharmacy-</span>
                    https://bydowpharmacy.com/
                </a> */}
                        </div>
                        <div className="modal-footer border-0">
                            <button
                                type="button"
                                className="modal-button"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ReportGeneration;