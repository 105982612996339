import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

export default function PointOfCare() {
  useEffect(() => {
    var highestBox = 0;
    document.querySelectorAll('.poc-card .title').forEach((title) => {
      if (title.clientHeight > highestBox) {
        highestBox = title.clientHeight;
      }
    });
    document.querySelectorAll('.poc-card .title').forEach((title) => {
      title.style.height = highestBox + 'px';
    });
  }, []);

  return (
    <>
      <Header />
      <section className="poc-sec">
        <div className="container">
          <div className="mb-4">
            <p className="font_22 font_600 mb-0">Point of care</p>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/patient/home" className="font_14">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active font_14" aria-current="page">
                  Point of care
                </li>
              </ol>
            </nav>
          </div>
          <div className="row">
            <div className="col-lg-3 col-6">
              <Link to="/patient/carepoint/1" className="poc-card d-block">
                <div className="poc-img carepoint-icon">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/starpilot.png"} alt="" />
                </div>
                <p className="title d-block">Carepoint <span className='d-block orangeClr px-2'> AutoRx</span></p>
                <p className="text">
                  Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.
                </p>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/share-medical" className="poc-card d-block">
                <div className="poc-img carepoint-icon">
                  <img src={"/assets/images/editDoc.svg"} alt="" />
                </div>
                <p className="title">Share Records Analyze & Query</p>
                <p className="text">
                  Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.
                </p>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/radiation-illustration/3" className="poc-card d-block">
                {/* <div className="poc-img carepoint-icon">
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/bookmarks_8110100.png"} alt="" />
                </div>
                <p className="title">Radiology Scans</p>
                <p className="text">
                  Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.
                </p> */}
                <div className="poc-img">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/camera_3193058.png"} alt="" />
                </div>
                <p className="title">Illustrations &amp; Radiographic Images</p>
                <p className="text">
                  Click and enter prompt e.g. “Please assist me as requested for the following medical illustrations and radiographic images”
                </p>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/differential-diagnosis-generator/4" className="font_14">
                <div className="poc-card">
                  <div className="poc-img">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/magnify.svg"} alt="" />
                  </div>
                  <p className="title">Differential Diagnosis Generator</p>
                  <p className="text">
                    Click and enter prompt e.g. “ Please listen to my symptoms and provide a differential diagnosis to your best ability.”
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/indication-checker/5" className="font_14">
                <div className="poc-card">
                  <div className="poc-img">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/indication-check.png"} alt="" />
                  </div>
                  <p className="title">Indications Checker</p>
                  <p className="text">
                    Click and enter prompt e.g. “Please check these drugs for indications”
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/drug-interaction-revision/6" className="font_14">
                <div className="poc-card">
                  <div className="poc-img">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/drug-interactions.png"} alt="" />
                  </div>
                  <p className="title d-block">Drug Interactions <span className=' orangeClr '>  & AutoRx Revisions</span></p>
                  <p className="text">
                    Click and enter prompt e.g. “Please check these drugs for drug interactions and revise prescription to eliminate interactions”
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/drug-allergy/7" className="font_14">
                <div className="poc-card">
                  <div className="poc-img">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/drug-allergy.png"} alt="" />
                  </div>
                  <p className="title">Drug Allergy</p>
                  <p className="text">
                    Click and enter prompt e.g. “Please check these drugs for drug-allergy interactions”
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/dosing-medical-calculation/8" className="font_14">
                <div className="poc-card">
                  <div className="poc-img">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/dosing-medical.png"} alt="" />
                  </div>
                  <p className="title">Dosing & Medical Calculations</p>
                  <p className="text">
                    Click and enter prompt e.g. “Please check these drugs and calculate dosing or confirm dosing calculations”
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/pill-identification/9" className="font_14">
                <div className="poc-card">
                  <div className="poc-img">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/pill-identification.png"} alt="" />
                  </div>
                  <p className="title">Pill Identification Tool</p>
                  <p className="text">
                    Click and enter prompt e.g. “Please indicate how to identify the pills for the following drugs”
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/iv-compatibility-checker/10" className="poc-card d-block">
                <div className="poc-img">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/iv-compatibility.png"} alt="" />
                </div>
                <p className="title">IV Compatibility Checker</p>
                <p className="text">
                  Click and enter prompt e.g. “Please check these drugs for IV compatibility”
                </p>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/medical-videos-articles/11" className="font_14">
                <div className="poc-card">
                  <div className="poc-img">
                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/video-call.svg"} alt="" />
                  </div>
                  <p className="title">Medical Videos & Articles</p>
                  <p className="text">
                    Click and enter prompt e.g. “Please assist me as requested for the following medical videos and articles”
                  </p>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/laboratory-manual/12" className="poc-card d-block">
                <div className="poc-img">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/chemistry_3297186.png"} alt="" />
                </div>
                <p className="title">Laboratory Manual</p>
                {/* <p className="title">Radiology Scans</p>  */}
                <p className="text">
                  Click and enter prompt e.g. “Please assist me as requested on laboratory manuals.”
                </p>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/icd-11-lookup-tool/13" className="poc-card d-block">
                <div className="poc-img">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/password_1691861.png"} alt="" />
                </div>
                <p className="title">ICD-11 Lookup Tool</p>
                <p className="text">
                  Click and enter prompt e.g. “Please assist me as requested to look up ICD-11 codes.”
                </p>
              </Link>
            </div>
            <div className="col-lg-3 col-6">
              <Link to="/patient/notes-favorites/14" className="poc-card d-block">
                <div className="poc-img">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/bookmarks_8110100.png"} alt="" />
                </div>
                <p className="title">Notes &amp; Favorites</p>
                <p className="text">
                  Click and enter prompt e.g. “Please assist me as requested to analyze and query the following notes and documents”
                </p>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}