import React, { useEffect, useState, useRef } from 'react'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { getReportDetails } from '../../../api/apiHandler';
import Swal from 'sweetalert2'
import moment from 'moment-timezone';
import Chart from 'react-apexcharts'

function ReportGenerate() {
    const navigate = useNavigate();
    const location = useParams();
    const [userTimezone, setUserTimezone] = useState('');
    const [isabelReport, setIsabelReport] = useState(null)
    const [binahReport, setBinahReport] = useState(null)
    const [convertedDate, setConvertedDate] = useState('');
    const chartContainerRef = useRef(null);
    const [showModel, setShowModel] = useState(false);
    const [binahResults, setBinahResults] = useState({});
    const [seriesRadial, setSeriesRadial] = useState([0]);
    // const [showLevel, setShowLevel] = useState("Low");

    const [optionsRadial, setOptionsRadial] = useState({
        chart: {
            height: 280,
            type: "radialBar"
        },
        plotOptions: {
            radialBar: {
                startAngle: -135,
                endAngle: 225,
                hollow: {
                    margin: 0,
                    size: "70%",
                    background: "#fff",
                    image: undefined,
                    imageOffsetX: 0,
                    imageOffsetY: 0,
                    position: "front",
                    dropShadow: {
                        enabled: true,
                        top: 3,
                        left: 0,
                        blur: 4,
                        opacity: 0.24
                    }
                },
                track: {
                    background: "#fff",
                    strokeWidth: "67%",
                    margin: 0,
                    dropShadow: {
                        enabled: true,
                        top: -3,
                        left: 0,
                        blur: 4,
                        opacity: 0.35
                    }
                },
                dataLabels: {
                    showOn: "always",
                    name: {
                        offsetY: -3,
                        show: true,
                        color: "#111",
                        fontSize: "15px",
                        fontWeight: "bold"
                    },
                    value: {
                        formatter: function (val) {
                            return Math.round((val * 10) / 100) + "/10";
                        },
                        color: "#111",
                        fontSize: "20px",
                        fontWeight: "bold",
                        show: true
                    }
                }
            }
        },
        fill: {
            type: "gradient",
            gradient: {
                type: "horizontal",
                shadeIntensity: 1,
                opacityFrom: 1,
                opacityTo: 1,

                colorStops: [
                    {
                        offset: 0,
                        color: "#ff6600"
                    },
                    {
                        offset: 50,
                        color: "#ff9933"
                    },
                    {
                        offset: 100,
                        color: "#66ff66"
                    }
                ]
            }
        },
        stroke: {
            lineCap: "round"
        },
        labels: ["Normal"],
        responsive: [{
            breakpoint: 576,
            options: {
                chart: {
                    height: 200,
                }
            },
        }]
    });

    const binahResultsDescription = [
        {
            heart_rate: `This is the number of times your heart beats per minute. The normal resting rate is 60 to 100 beats for a healthy adult.
            <br/><br/>
            Think of your heart as a pump that pushes blood through your body. With every beat, the heart pumps blood containing oxygen and nutrients around the body and brings back waste products. A healthy heart supplies the body with the right amount of blood at a rate proportionate to whatever activity the body is undertaking.
            <br/><br/>
            Normal resting rates can differ between people. Furthermore, heart rates are lower when at rest and increase during exercise. Moreover, this rate can change with different situations such as the weather, body position, emotions, body size, medication, and the use of caffeine and nicotine.
            <br/><br/>  
            At rest, a fast Heart Rate may indicate acute health conditions such as an infection, dehydration, stress, anxiety, thyroid disorder, shock, anemia, or certain heart conditions. Moreover, it can predict long-term risk for cardiovascular events. A low Heart Rate is common for people who exercise frequently and participate in athletics.
            <br/><br/>
            Tracking Heart Rate can provide insight into fitness levels, heart health, and emotional health.
            Moreover, for individuals taking medication for cardiovascular conditions, daily Heart Rate measurements can assist the doctor in advising on the proper course of treatment.
            <br/><br/>
            A healthy heartbeat is important in protecting cardiac health. If you feel that your heart is beating out of rhythm (too fast or too slow), speak to a doctor about your symptoms.`
        },
        {
            wellness_level: `Wellness Level is divides Wellness Score into Low, Medium and High, hence the range of 1-3.
            <br/><br/>
            The Wellness Score is a prediction risk score that is used to predict a person's cardiovascular risk for the next 5 to 10 years. The Wellness Score is based on the vital signs measured by Binah's technology, and is designed to serve as a reference when measured at rest, under similar conditions during all of the measurements, and if the score is consistent in repeated measurements over time.
            <br/><br/>
            The higher the wellness score, the lower the cardiovascular risk.
            <br/><br/>
            How is it calculated?
            <br/><br/>
            Your Wellness Score is calculated using your vitals results from any single measurement. The values of each one of the vital sign measurements affect your Wellness Score prediction.
            <br/><br/>
            Generally, a lower Heart Rate at rest implies more efficient heart function and better cardiovascular fitness. Therefore, a higher Heart Rate reduces your Wellness Score - even when the heart rate is within the normal range. For example, heart rates that are higher than 65 reduce the wellness score to a medium score, and values that are higher than 84 reduce the wellness score to a low score.
            <br/><br/>
            HRV measures the variation in time between heartbeats. The Stress Level that is calculated from this variance also affects your Wellness Score. Thus, Very High and High stress levels are correlated with a low score, while Mild and Normal stress levels are correlated with a medium score.
            <br/><br/>
            Your Oxygen Saturation level measures the amount of oxygen in the blood delivered from the lungs to the rest of the body. A higher level implies a more efficient function, thus, a lower Oxygen Saturation level reduces the Wellness Score.
            <br/><br/>
            In addition, High Blood Pressure readings at rest may pose a higher risk of health problems and therefore may reduce the Wellness Score.`
        },
        {
            breathing_rate: `The number of breaths you take per minute. The normal at-rest Breathing Rate is 12 to 20 breaths per minute for a healthy adult. In general, breathing rates are slightly faster in women than men.
            <br/><br/>
            When you inhale, oxygen enters your lungs and circulates to the various internal organs. When you exhale, carbon dioxide moves out of the body. A normal Breathing Rate plays a critical role in keeping the balance of oxygen and carbon dioxide even in the body. If the oxygen level in the blood is low, or if the carbon dioxide level in the blood is high, your Breathing Rate increases.
            <br/><br/>
            Various factors affect the Breathing Rate, including injuries, exercise, fever, anxiety, emotions, mood, alcohol, medication, metabolic issues, and medical conditions. A high or low rate might be the result of an activity and therefore does not indicate that there is anything wrong. However, in other cases, such as various diseases, injuries, dehydration, or heart problems, a change in the Breathing Rate may occur that can be considered abnormal, thereby necessitating medical attention.
            <br/><br/>
            Knowing your Breathing Rate can help your doctor provide you with medical advice. If your Breathing Rate changes or if you feel that your breathing is too fast or too slow, speak to a doctor about your symptoms.`
        },
        {
            prq: `The Pulse-Respiration Quotient (PRQ) indicates the state of cardiorespiratory activity. The normal quotient range is 4 to 5. PRQ readings are affected by one’s age, sex, physiological constitution, physical activity, psychophysical and cognitive activity, body posture, environmental influences, and state of health.
            <br/><br/>
            The PRQ is calculated by dividing the Heart Rate by the Breathing Rate. It provides additional knowledge that cannot be obtained when analyzing the Heart Rate and the Breathing Rate separately.
            <br/><br/>
            The Pulse-Respiration Quotient (PRQ) reflects emergent properties of the complex interplay between cardiac and respiratory activities. The pulse-respiration quotient metric measures to what extent this interplay is functioning normally. A low or high score would indicate that your HR and/or BR are working disproportionately, which may indicate that both the heart and the lungs are working inefficiently. Moreover, a person’s pathophysiological state (the functional changes associated with or resulting from disease or injury) is indicated by abnormal PRQ readings.
            <br/><br/>
            These results should be used as a guide only. Seek a doctor’s advice in order to obtain a valid diagnosis.`
        },
        {
            blood_pressure: `The pressure of blood exerted on the walls of the arteries, which carry blood from the heart to other parts of the body. Normal systolic pressure is from 100 to 129.
            <br/><br/>
            Blood Pressure measures the pressure of circulating blood against artery walls, and it is measured by two numbers. The first number, or systolic pressure, refers to the pressure inside the artery when the heart contracts and pumps blood throughout the body. The second number, or diastolic pressure, refers to the pressure inside the artery when the heart is at rest and is filling with blood.
            <br/><br/>
            Most people don’t know if they have high Blood Pressure – especially since there may be no noticeable warning signs or symptoms – and therefore the Blood Pressure must be measured.
            <br/><br/>
            Blood pressure changes in response to different activities and is recommended to be measured while at rest. Consistently high blood pressure readings may result in a diagnosis of high blood pressure (hypertension), which poses a higher risk for health problems such as heart disease, heart attack, and stroke. In most cases, high blood pressure has no defined cause, and it is called primary hypertension. However, it is related to unhealthy lifestyles such as physical inactivity, stressful life, obesity, shift work, pregnancy, etc. It should be emphasized that Blood Pressure can be managed through diagnosis, lifestyle changes, medication and long-term monitoring.
            <br/><br/>
            Blood Pressure is categorized as low, normal, or elevated: low blood pressure is defined as systolic pressure of less than 100, normal blood pressure is defined as systolic pressure of 100 to 129, while elevated blood pressure is defined as systolic pressure of 130 or higher.
            <br/><br/>
            These numbers should be used as a guide only. A single Blood Pressure measurement that is higher than normal is not necessarily an indication of a problem. Your doctor will want to see multiple Blood Pressure measurements over several days or weeks before making a diagnosis of high blood pressure and commencing treatment.`
        },
        {
            stress_level: `The body's reaction to a challenge or demand. There are five levels of Stress:
            <br/><br/>
            Low, Normal, Medium, High, Very High
            <br/><br/>
            The human body is designed to experience stress and react to it. When you deal with challenges and changes, your body produces physical and mental responses. Stress might be positive, keeping us alert, motivated, and ready to avoid danger. However, stress becomes a problem when stressors continue without relief or periods of relaxation.
            <br/><br/>
            The application’s Stress Level measurement is based on Baevsky’s Stress Index which is approved for use in the US and in Europe. The index is calculated using Heart Rate Variability (HRV) measurements.
            <br/><br/>
            It’s important to note that Stress Levels are indicative only and need to be corroborated with other parameters by a doctor before making a valid diagnosis. Stress Levels are highly dynamic and should be monitored over longer periods to detect abnormal trends.`
        },
        {
            stress_index: `Stress is the body's reaction to a challenge or demand.
            <br/><br/>
            The application’s Stress Level measurement is based on Baevsky’s Stress Index which is approved for use in the US and in Europe. The index is calculated using Heart Rate Variability (HRV) measurements.
            <br/><br/>
            The stress index is calculated from the Heart Rate Variability (HRV) measurements, which means that stress levels are derived from physiological conditions. HRV analysis is a globally accepted methodology and technique for evaluating the functional state of an organism and, specifically, components of the autonomic nervous system.
            <br/><br/>
            The stress Index is used to set the Stress Level.`
        },
        {
            pns_zone: `The Recovery Ability that is also known as “rest and digest'' response refers to the body’s ability to recover, accumulate energy, and regulate bodily functions after stressful occurrences. This is part of the autonomic system that consists of two sub-systems, the sympathetic (Stress Response) system and the parasympathetic (Recovery Ability) system. Your Heart Rate Variability is reflected in the balance between these two sub-systems.
            <br/><br/>
            There are three zones of Recovery Ability:
            <br/><br/>
            Low, Normal, High
            <br/><br/>
            The normal and high zones are more desirable than the low zone. In the normal and high zones, the body is able to effectively conserve energy, relax, or recover from a stressful occurrence.
            <br/><br/>
            The parasympathetic metric measures the activity of the PNS and indicates how capable a person is of relaxing or recovering after stressful events. A low zone would indicate a stressful state, while a high zone would suggest calmness.
            <br/><br/>
            The system plays an important role in alleviating stress and promoting recovery. It does so by inhibiting the activity of the sympathetic nervous system and ceasing the production of stress hormones.  It returns bodily functions to their resting state by slowing the heart rate, lowering blood pressure, reducing muscle tension, and restoring regular breathing, digestion, and glandular activity.
            <br/><br/>
            The recovery ability is derived from the parasympathetic nervous system (PNS) index. The PNS Index calculation is based on the following three parameters: Mean RRi, RMSSD, and SD1.
            <br/><br/>
            These zones should be used as a guide only. Seek a doctor’s advice in order to obtain a valid diagnosis.`
        },
        {
            pns_index: `The PNS Index calculation is based on the following three parameters: Mean RRi, RMSSD, and SD1, and is used to indicate the body’s Recovery Ability zones.`
        },
        {
            mean_rri: `Mean RRi is the average time between the RR intervals (RRi) in milliseconds. RRi is the variation of the interval between successive heartbeats. A longer Mean RR interval indicates a lower heart rate and higher parasympathetic cardiac activation.
            <br/><br/>
            The Mean RRi is one of the parameters used to calculate the PNS Index, along with RMSSD and SD1.`
        },
        {
            rmssd: `An important measure of the Heart Rate Variability. RMSSD is the root mean square of successive RR interval differences. It reflects the beat-to-beat variance in the heart rate. RMSSD can help identify a general level of fatigue. In addition, a higher RMSSD is linked to parasympathetic control, a sign that you are in the “rest and digest” mode. A lower RMSSD is linked to elevated sympathetic activity, an indication of a Stress Response.
            <br/><br/>
            RMSSD is one of the parameters used to calculate the PNS Index, along with Mean RRi and SD1.`
        },
        {
            sd1: `SD1 is a poincaré plot standard deviation perpendicular to the line of identity.
            <br/><br/>
            SD1 is one of the parameters used to calculate the PNS Index, along with RRi and RMSSD.`
        },
        {
            sns_zone: `The Stress Response, which is also known as “fight or flight” response, refers to a physiological reaction to imminent danger that occurs when we are scared, anxious, stressed, attacked, or threatened. Essentially, it prepares our body to either deal with a threat or to run for safety. This is part of the autonomic system that consists of two sub-systems, the sympathetic (Stress Response) system and the parasympathetic (recovery ability) system. Your Heart Rate Variability is reflected in the balance between those two sub-systems.
            <br/><br/>
            There are three zones of Stress Response:
            <br/><br/>
            Low, Normal, High
            <br/><br/>
            The normal and low zones are more desirable than the high zone. In the normal and low zones, the body is able to effectively respond to stressful situations and emergencies.
            <br/><br/>
            When preparing for an emergency, the sympathetic nervous system (SNS) activates numerous complex pathways and components. These physiological activities help to achieve a faster heart rate, breathing rate, and blood pressure. Noticeable changes include blood flow that moves away from the skin and stomach, and is redirected from the intestines to the brain, heart, and muscles, as well as sweating, “goose-bumps”, dilation of the pupils, and a host of other feelings that appear during the Stress Response. In addition, there is a psychological aspect to the Stress Response. Automated responses include quick thinking and focusing on salient targets such as the source of the threat and escape options.
            <br/><br/>
            The stress created by a situation is helpful and increases the chances of coping effectively with the threat. This type of stress can help you perform better in situations where you are under pressure to do well.
            <br/><br/>
            This Stress Response is derived from the sympathetic nervous system (SNS) Index. The SNS Index is calculated based on the following three parameters: Heart Rate, Baevsky’s Stress Index, and SD2.
            <br/><br/>
            These zones should be used as a guide only. Seek a doctor’s advice in order to obtain a valid diagnosis.`
        },
        {
            sns_index: `The SNS index is calculated based on the following three parameters: Heart Rate, Baevsky’s stress index, SD2, and is used to set the stress response zone.`
        },
        {
            sd2: `SD2 is a poincaré plot standard deviation along the line of identity.
            <br/><br/>
            SD2 is one of the parameters used to calculate the SNS Index, along with Heart Rate and Baevsky’s Stress Index.`
        },
        {
            sdnn: `SDNN is a calculated parameter of Heart Rate Variability (HRV) that represents the standard deviation of normal-to-normal R-R-intervals. SDNN is expressed in milliseconds, and a normal value for this is over 50. However, SDNN values are also dependent on age and gender and normally become lower with age.
            <br/><br/>
            An individual's heartbeats do not occur at constant intervals, but rather with a small variance between them. HRV measures the variation in time between the heartbeats.
            <br/><br/>
            High levels of HRV generally indicate aerobic and general fitness. Athletes may track HRV to adjust their training program. They can learn when the body is being overworked, which often results in a drop in HRV, and can learn how fast they recover. Moreover, persons with high HRV may be more resilient to stress.
            <br/><br/>
            HRV measurements provide feedback about your lifestyle and can help inspire taking steps toward a healthier life. If you are implementing changes in your lifestyle such as meditation, better sleep, better nutrition, and participation in sports and physical activity, you may notice changes in the HRV. In addition, this could help to track your nervous system's reactions to the environment, emotions, thoughts, and feelings.
            <br/><br/>
            The sympathetic system (Stress Response) is activated when the body is under stress, causing the heart to beat faster and more regularly, and causing HRV to decrease. The parasympathetic system (Recovery Ability) manages the heart’s activity to help the body reach a relaxed state and to recover from a stressful event. This relaxation response results in a slower and less regular heartbeat and is indicated by a higher HRV.
            <br/><br/>
            If you have questions about your results, seek a doctor’s advice and they will let you know what’s normal for your specific condition.`
        },
        {
            lfhf: `LF and HF stand for Low-Frequency and High-Frequency bands, which represent the Sympathetic and Parasympathetic activity, respectively.
            <br/><br/>
            The LF/HF ratio reflects the balance between sympathetic and parasympathetic activity. The normal range is between LF(ms2)/HF(ms2) = 0.27 - 0.38. A lower ratio of LF/HF indicates a high Parasympathetic stress level, and a higher ratio indicates an increased Sympathetic activity which is a biomarker of stress.`
        },
        {
            hemoglobin: `Hemoglobin is a protein in a person’s red blood cells that carries oxygen to the human body's organs and tissues and transports carbon dioxide from your organs and tissues back to your lungs. 
            Hemoglobin is measured in g/dL and in resolution up to 0.1 g/dL
            <br/><br/>
            The category is based on your profile gender. The healthy ranges are:
            <br/><br/>
            <ul>
            <li>Men: 14 to 18 g/dL</li>
            <li>Women: 12 to 16 g/dL</li>
            </ul>`
        },
        {
            hemoglobin_a1c: `Hemoglobin A1C (or HbA1c) represents the average blood glucose (sugar) level for the last two to three months. HbA1c is measured in percentage with resolution up to 0.01%.
            <br/><br/>
            HbA1c ranges:
            <br/><br/>
            <ul>
            <li>Normal &lt; 5.6 %</li>
            <li>Prediabetes risk 5.7-6.4 %</li>
            <li>Diabetes risk > 6.5 %</li>
            </ul>`
        },
        {
            wellness_score: `The Wellness Score is a prediction risk score that is used to predict a person's cardiovascular risk for the next 5 to 10 years. The Wellness Score is based on the vital signs measured by Binah's technology, and is designed to serve as a reference when measured at rest, under similar conditions during all of the measurements, and if the score is consistent in repeated measurements over time.
            <br/><br/>
            The higher the wellness score, the lower the cardiovascular risk.
            <br/><br/>
            How is it calculated?
            <br/><br/>
            Your Wellness Score is calculated using your vitals results from any single measurement. The values of each one of the vital sign measurements affect your Wellness Score prediction.
            <br/><br/>
            Generally, a lower Heart Rate at rest implies more efficient heart function and better cardiovascular fitness. Therefore, a higher Heart Rate reduces your Wellness Score - even when the heart rate is within the normal range. For example, heart rates that are higher than 65 reduce the wellness score to a medium score, and values that are higher than 84 reduce the wellness score to a low score.
            <br/><br/>
            HRV measures the variation in time between heartbeats. The Stress Level that is calculated from this variance also affects your Wellness Score. Thus, Very High and High stress levels are correlated with a low score, while Mild and Normal stress levels are correlated with a medium score.
            <br/><br/>
            Your Oxygen Saturation level measures the amount of oxygen in the blood delivered from the lungs to the rest of the body. A higher level implies a more efficient function, thus, a lower Oxygen Saturation level reduces the Wellness Score.
            <br/><br/>
            In addition, High Blood Pressure readings at rest may pose a higher risk of health problems and therefore may reduce the Wellness Score.`
        }
    ]

    useEffect(() => {

        // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezone = moment.tz.guess();
        setUserTimezone(timezone);
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        console.log("location", location.uuid);
        // location.uuid = "41e4a33e-7e30-4d80-a0bb-ec29aebc67c5"
        getReportDetails({ uuid: location.uuid }).then((response) => {
            response = JSON.parse(response)
            console.log("res", response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setIsabelReport(response.data.possible_conditions != null ? response.data.possible_conditions : null)
                setBinahReport(response.data.vital_signs != null ? JSON.parse(response.data.vital_signs.vital_signs) : null)
                if (response.data.vital_signs != null) {
                    let parseResponse = JSON.parse(response.data.vital_signs.vital_signs)
                    if (parseResponse.wellnessIndex != undefined) {
                        setSeriesRadial([parseResponse.wellnessIndex.value * 10])
                        const newLevel = (parseResponse.wellnessIndex.value <= 4) ? "LOW" : (parseResponse.wellnessIndex.value >= 5 && parseResponse.wellnessIndex.value <= 7) ? "NORMAL" : "HIGH";

                        setOptionsRadial({
                            ...optionsRadial,
                            labels: [newLevel]
                        });
                    }
                }
            }
            Swal.close()
        })

    }, [])

    useEffect(() => {
        if (isabelReport) {
            const convertedMoment = moment.utc(isabelReport.createdAt).tz(userTimezone);
            const formattedConvertedDate = convertedMoment.format('hh:mm:ss A MM/DD/YYYY');
            setConvertedDate(formattedConvertedDate);
        }
    }, [isabelReport, userTimezone]);

    if (isabelReport == null && binahReport == null) return <div className="container"><div className="mt-5"></div></div>

    const handleShareMedical = () => {
        navigate('/doctor/share-medical-list')
    }

    const openModel = () => {
        setShowModel(true)
    }

    const closeModel = () => {
        setBinahResults({})
        setShowModel(false)
    }

    const handleModel = (result_value, result_name, result_level, start_value, end_value) => {
        let binahResultsObj = {
            "result_value": result_value,
            "result_name": result_name,
            "result_level": result_level,
            "start_value": start_value,
            "end_value": end_value,
        }
        if (result_name === "Heart Rate") {
            binahResultsObj.description = binahResultsDescription[0].heart_rate
        }
        if (result_name === "Hemoglobin A1c") {
            binahResultsObj.description = binahResultsDescription[18].hemoglobin_a1c
        }
        if (result_name === "Breathing Rate") {
            binahResultsObj.description = binahResultsDescription[2].breathing_rate
        }
        if (result_name === "Hemoglobin") {
            binahResultsObj.description = binahResultsDescription[17].hemoglobin
        }
        if (result_name === "Stress Level") {
            binahResultsObj.description = binahResultsDescription[5].stress_level
        }
        if (result_name === "LFHF") {
            binahResultsObj.description = binahResultsDescription[16].lfhf
        }
        if (result_name === "Wellness SCORE") {
            binahResultsObj.description = binahResultsDescription[19].wellness_score
        }
        if (result_name === "Heart Variability") {
            binahResultsObj.description = binahResultsDescription[15].sdnn
        }
        if (result_name === "Wellness Level") {
            binahResultsObj.description = binahResultsDescription[1].wellness_level
        }
        if (result_name === "Mean RR Interval") {
            binahResultsObj.description = binahResultsDescription[9].mean_rri
        }
        if (result_name === "PNS Zone") {
            binahResultsObj.description = binahResultsDescription[7].pns_zone
        }
        if (result_name === "SNS Zone") {
            binahResultsObj.description = binahResultsDescription[12].sns_zone
        }
        if (result_name === "PNS Index") {
            binahResultsObj.description = binahResultsDescription[8].pns_index
        }
        if (result_name === "SNS Index") {
            binahResultsObj.description = binahResultsDescription[13].sns_index
        }
        if (result_name === "Stress Index") {
            binahResultsObj.description = binahResultsDescription[6].stress_index
        }
        if (result_name === "PRQ") {
            binahResultsObj.description = binahResultsDescription[3].prq
        }
        if (result_name === "Blood Pressure") {
            binahResultsObj.description = binahResultsDescription[4].blood_pressure
        }
        if (result_name === "RMSSD") {
            binahResultsObj.description = binahResultsDescription[10].rmssd
        }
        if (result_name === "SD1") {
            binahResultsObj.description = binahResultsDescription[11].sd1
        }
        if (result_name === "SD2") {
            binahResultsObj.description = binahResultsDescription[14].sd2
        }
        setBinahResults(binahResultsObj)
        openModel()
    }

    return (
        <>
            <main className="bg-color">
                <Header />
                <section className="report-sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-xxl-3 col-xl-3 col-lg-3 col-12 bg-white rounded-1 mb-3 p-3">
                                <div className="">
                                    <div className="row">
                                        {binahReport != null && binahReport.wellnessLevel != undefined ?
                                            <div className="col-lg-12 col-md-6 col-12">
                                                <div className="card-progress-set">
                                                    <div id="handle2">
                                                        {/* <Chart options={optionsRadial} series={seriesRadial} type="radialBar" width={200}/> */}
                                                        <Chart options={optionsRadial} series={seriesRadial} type="radialBar" />
                                                    </div>
                                                    <p className="font_22 font_500 text-center mb-0 mt-2">
                                                        Wellness Score
                                                    </p>
                                                    <p className="mb-0 font_14 font_600 text-center">
                                                        The measured indicators are not intended for medical use.
                                                    </p>
                                                </div>
                                            </div> : ""}
                                        {isabelReport != null ?
                                            <div className="col-lg-12 col-md-6 col-12">
                                                <p className="text-center font_20 mb-0">
                                                    {convertedDate}
                                                </p>
                                                <div className="info-sec">
                                                    <p className="font_18 mb-1">Information</p>
                                                    <hr className="mt-2 mb-2" />
                                                    <p className="font_18 mb-1">
                                                        <span className="font_500">Sex:</span> {isabelReport != null ? (isabelReport.sex ? "Male" : "Female") : "Male"}
                                                    </p>
                                                    <p className="font_18 mb-1">
                                                        <span className="font_500">Age Group:</span> {isabelReport != null ? isabelReport.age_group.charAt(0).toUpperCase() + isabelReport.age_group.slice(1) : 0}
                                                    </p>
                                                    <p className="font_18 mb-1">
                                                        <span className="font_500">Pregnant:</span> {isabelReport != null ? (isabelReport.pregnant) ? "Yes" : "No" : "No"}
                                                    </p>
                                                </div>
                                            </div> : ""}
                                    </div>
                                </div>
                            </div>
                            <div className="col-xxl-9 col-xl-9 col-lg-9 col-12">
                                <div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                            <Link
                                                // to="/patient/doctor-appointment"
                                                className="card-report make-apointment-btn d-flex justify-content-center"
                                            >
                                                <p className="mb-0 font_20 font_600">
                                                    Make Appointment With Doctor
                                                </p>
                                            </Link>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div
                                                className="card-report"
                                                data-bs-toggle="modal"
                                                data-bs-target="#modal-emergency"
                                            >
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/Emergency-call-new.png"}
                                                    className="icon"
                                                    alt=""
                                                />
                                                <p className="mb-0 font_18">Dial 112 Emergency </p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <div
                                                className="card-report"
                                                data-bs-toggle="modal"
                                                data-bs-target="#modal-pharmacy"
                                            >
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/pharmacy-medicine.png"}
                                                    className="icon"
                                                    alt=""
                                                />
                                                <p className="mb-0 font_18">Pharmacy</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6">
                                            <Link to="/patient/doctor-list" className="card-report">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/online-telimedicine.png"}
                                                    className="icon"
                                                    alt=""
                                                />
                                                <p className="mb-0 font_18">Online Telemedicine</p>
                                            </Link>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-6" onClick={handleShareMedical}>
                                            <div className="card-report">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/images/icon/share-medical-icon.png"}
                                                    className="icon"
                                                    alt=""
                                                />
                                                <p className="mb-0 font_18">Share Medical Record</p>
                                            </div>
                                        </div>
                                    </div>
                                    <nav>
                                        <div
                                            className="nav nav-tabs custom-tabs"
                                            id="nav-tab"
                                            role="tablist"
                                        >
                                            <button
                                                className="nav-link active"
                                                id="nav-home-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-home"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-home"
                                                aria-selected="true"
                                            >
                                                Vital signs
                                            </button>
                                            <button
                                                className="nav-link"
                                                id="nav-profile-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#nav-profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-profile"
                                                aria-selected="false"
                                            >
                                                Possible conditions
                                            </button>
                                        </div>
                                    </nav>
                                    <div className="tab-content" id="nav-tabContent">
                                        <div
                                            className="tab-pane fade show active"
                                            id="nav-home"
                                            role="tabpanel"
                                            aria-labelledby="nav-home-tab"
                                        >
                                            <div className="tab-sec">
                                                {binahReport != null ?
                                                    <div className="row">
                                                        {binahReport != null && binahReport.heartRate != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.heartRate.value, "Heart Rate", "BPM", 60, 100)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Heart Rate</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.heartRate.value : 0}</p>
                                                                            <p className="mb-0 font_14">BPM</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.heartRate.value >= 60 && binahReport.heartRate.value <= 100) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal range: 60 - 100</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.hemoglobinA1c != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.hemoglobinA1c.value, "Hemoglobin A1c", "%", 3, 6)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/blood-type.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Hemoglobin A1c</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.hemoglobinA1c.value : 0}</p>
                                                                            <p className="mb-0 font_14">%</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.hemoglobinA1c.value >= 3 && binahReport.hemoglobinA1c.value <= 6) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal range: 3 - 6</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.bloodPressure != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(0, "Blood Pressure", "mmHg", binahReport.bloodPressure.value.systolic, binahReport.bloodPressure.value.diastolic)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/blood-pressure.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Blood Pressure</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.bloodPressure.value.systolic + "/" + binahReport.bloodPressure.value.diastolic : 0}</p>
                                                                            <p className="mb-0 font_14">mmHg</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.bloodPressure.value.systolic <= 120 && binahReport.bloodPressure.value.diastolic <= 80) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">
                                                                        Systolic &lt; 120 Diastolic &lt; 80
                                                                    </p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.breathingRate != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.breathingRate.value, "Breathing Rate", "BRPM", 12, 20)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Breathing Rate</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.breathingRate.value : 0}</p>
                                                                            <p className="mb-0 font_14">BRPM</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.breathingRate.value >= 12 && binahReport.breathingRate.value <= 20) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal range: 12 - 20</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.hemoglobin != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.hemoglobin.value, "Hemoglobin", "g/dL", 9, 17)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/cells.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Hemoglobin</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.hemoglobin.value : 0}</p>
                                                                            <p className="mb-0 font_14">g/dL</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.hemoglobin.value >= 9 && binahReport.hemoglobin.value <= 17) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal range: 9 - 17</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.stressLevel != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.stressLevel.value, "Stress Level", "LEVEL", 1, 3)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Stress Level</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.stressLevel.value : 0}</p>
                                                                            <p className="mb-0 font_14">LEVEL</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.stressLevel.value >= 1 && binahReport.stressLevel.value <= 3) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 1 - 3</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.lfhf != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.lfhf.value, "LFHF", "MA", 0, 1)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/hr-variability.png"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">LFHF</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.lfhf.value : 0}</p>
                                                                            <p className="mb-0 font_14">MA</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.lfhf.value >= 0 && binahReport.lfhf.value <= 1) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal range: 0 - 1</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.wellnessIndex != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.wellnessIndex.value, "Wellness SCORE", "LEVEL", 5, 7)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/recovery-ability.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Wellness SCORE</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.wellnessIndex.value + "/10" : 0}</p>
                                                                            <p className="mb-0 font_14">LEVEL</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.wellnessIndex.value >= 5 && binahReport.wellnessIndex.value <= 7) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 5 - 7</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.sdnn != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.sdnn.value, "Heart Variability", "%", 20, 100)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Heart Variability</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.sdnn.value : 0}</p>
                                                                            <p className="mb-0 font_14">%</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.sdnn.value >= 20 && binahReport.sdnn.value <= 100) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal range: 20 - 100</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.wellnessLevel != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.wellnessLevel.value, "Wellness Level", "LEVEL", 1, 3)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Wellness Level</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.wellnessLevel.value : 0}</p>
                                                                            <p className="mb-0 font_14">LEVEL</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.wellnessLevel.value >= 1 && binahReport.wellnessLevel.value <= 3) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 1 - 3</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.rmssd != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.rmssd.value, "RMSSD", "ms", 0, 20)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">RMSSD</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.rmssd.value : 0}</p>
                                                                            <p className="mb-0 font_14">ms</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.rmssd.value >= 20) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: Above 20</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.meanRri != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.meanRri.value, "Mean RR Interval", "ms", 600, 1000)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Mean RR Interval</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.meanRri.value : 0}</p>
                                                                            <p className="mb-0 font_14">ms</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.meanRri.value >= 600 && binahReport.meanRri.value <= 1000) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 600 - 1000</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.sd1 != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.sd1.value, "SD1", "ms", 0, 10)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">SD1</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.sd1.value : 0}</p>
                                                                            <p className="mb-0 font_14">ms</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.sd1.value >= 10) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: Above 10</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.sd2 != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.sd2.value, "SD2", "ms", 0, 20)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">SD2</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.sd2.value : 0}</p>
                                                                            <p className="mb-0 font_14">ms</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.sd2.value >= 20) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: Above 20</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.pnsZone != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.pnsZone.value, "PNS Zone", "Level", 1, 3)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">PNS Zone</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.pnsZone.value : 0}</p>
                                                                            <p className="mb-0 font_14">Level</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.pnsZone.value >= 1 && binahReport.pnsZone.value <= 3) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 1 - 3</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.snsZone != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.snsZone.value, "SNS Zone", "Level", 1, 3)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">SNS Zone</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.snsZone.value : 0}</p>
                                                                            <p className="mb-0 font_14">Level</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.snsZone.value >= 1 && binahReport.snsZone.value <= 3) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 1 - 3</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.pnsIndex != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.pnsIndex.value, "PNS Index", "Level", -1, 1)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">PNS Index</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.pnsIndex.value : 0}</p>
                                                                            <p className="mb-0 font_14">Level</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.pnsIndex.value >= -1 && binahReport.pnsIndex.value <= 1) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: -1 - 1</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.snsIndex != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.snsIndex.value, "SNS Index", "Level", 1, 3)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">SNS Index</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.snsIndex.value : 0}</p>
                                                                            <p className="mb-0 font_14">Level</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.snsIndex.value >= 1 && binahReport.snsIndex.value <= 3) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 1 - 3</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.stressIndex != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.stressIndex.value, "Stress Index", "Level", 100, 200)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/mind.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">Stress Index</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.stressIndex.value : 0}</p>
                                                                            <p className="mb-0 font_14">Level</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.stressIndex.value >= 100 && binahReport.stressIndex.value <= 200) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal level: 100 - 200</p>
                                                                </div>
                                                            </div> : ""}
                                                        {binahReport != null && binahReport.prq != undefined ?
                                                            <div className="col-lg-4 col-md-4 col-6">
                                                                <div className="report-result-card" onClick={() => handleModel(binahReport.prq.value, "PRQ", "LEVEL", 4, 5)}>
                                                                    <div className="d-flex gap-2 justify-content-center align-items-center mb-4">
                                                                        <img
                                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/heart-attack.svg"}
                                                                            className="icon"
                                                                            alt="heart-rate"
                                                                        />
                                                                        <p className="mb-0 font_500 title">PRQ</p>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-3">
                                                                        <div>
                                                                            <p className="font_30 mb-0 font_500">{binahReport != null ? binahReport.prq.value : 0}</p>
                                                                            <p className="mb-0 font_14">LEVEL</p>
                                                                        </div>
                                                                        <div className="result-icon">
                                                                            <img
                                                                                src={process.env.PUBLIC_URL + ((binahReport.prq.value >= 4 && binahReport.prq.value <= 5) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <p className="mb-0 font_14">Normal range: 4 - 5</p>
                                                                </div>
                                                            </div> : ""}
                                                    </div> : ""}
                                            </div>
                                        </div>
                                        <div
                                            className="tab-pane fade"
                                            id="nav-profile"
                                            role="tabpanel"
                                            aria-labelledby="nav-profile-tab"
                                        >
                                            <div className="tab-sec">
                                                <div className="row">
                                                    {isabelReport != null ?
                                                        (JSON.parse(isabelReport.result).map((entry, index) => (
                                                            <div className="col-lg-6" key={index}>
                                                                <div className="report-result-card">
                                                                    <div>
                                                                        <p className="mb-1 font_18 font_500">
                                                                            Diagnosis Name : {entry.diagnosis_name}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))) : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
            <div
                className="modal fade"
                id="modal-emergency"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-custom">

                        <div className="modal-body pb-0">
                            <p className="modal-msg text-blue">
                                You have successfully dialed Emergency 112!
                            </p>
                        </div>
                        <div className="modal-footer border-0">
                            <button
                                type="button"
                                className="modal-button"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                className="modal fade"
                id="modal-pharmacy"
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content modal-custom">

                        <div className="modal-body pb-0">
                            <p className="text-blue font_20 font_500 text-center">
                                Go to pharmacy
                            </p>
                            {/* <a href="https://medplusnig.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">Medplus-</span>
                                https://medplusnig.com/
                            </a> */}
                            <a href="https://medplusnig.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">Medplus-</span>
                                https://medplusnig.com/
                            </a>
                            <a href="https://healthplusnigeria.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">Healthplus-</span>
                                https://healthplusnigeria.com/
                            </a>
                            <a href="https://primuspharmacy.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">Primus-</span>
                                https://primuspharmacy.com/
                            </a>
                            <a href="https://alphapharmacy.com.ng/" className="link_join text-blue">
                                <span className="text-blue pe-2">Alpha-</span>
                                https://alphapharmacy.com.ng/
                            </a>
                            {/* <a href="https://bydowpharmacy.com/" className="link_join text-blue">
                                <span className="text-blue pe-2">bydowpharmacy-</span>
                                https://bydowpharmacy.com/
                            </a> */}
                        </div>
                        <div className="modal-footer border-0">
                            <button
                                type="button"
                                className="modal-button"
                                data-bs-dismiss="modal"
                            >
                                Close
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showModel &&
                (<>
                    <div
                        className={`modal fade ${showModel ? 'show' : ''}`}
                        id="result-modal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        style={{ display: showModel ? 'block' : 'none' }}
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className={`modal-content result-modal-content
                        ${binahResults.result_name === "Blood Pressure" ?
                                    (binahResults.start_value <= 120 && binahResults.end_value <= 80 ? '' : 'highrate-modal')
                                    :
                                    (binahResults.result_name === "RMSSD" || binahResults.result_name === "SD1" || binahResults.result_name === "SD2" ?
                                        (binahResults.result_value >= binahResults.end_value ? '' : 'highrate-modal')
                                        :
                                        (binahResults.result_value >= binahResults.start_value && binahResults.result_value <= binahResults.end_value ? '' : 'highrate-modal')
                                    )
                                }`}
                            >


                                <div className="modal-header flex-column">
                                    <button
                                        type="button"
                                        className="btn-close"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                        onClick={closeModel}
                                    />
                                    <div className="flex-grow-1 text-center">
                                        <h4 className="modal-title" id="exampleModalLabel">
                                            {binahResults.result_name}
                                        </h4>

                                        <p className="mb-0 font_20 font_600">
                                            {/* {(binahResults.result_name === "Blood Pressure" ? binahResults.start_value +"/"+ binahResults.end_value : binahResults.result_value) +" "+binahResults.result_level} */}
                                            {(binahResults.result_name === "Blood Pressure" ? binahResults.start_value + "/" + binahResults.end_value + " " + binahResults.result_level : (binahResults.result_name === "Wellness SCORE" ? binahResults.result_value + "/10 " + binahResults.result_level : binahResults.result_value + " " + binahResults.result_level))}
                                        </p>

                                        {binahResults.result_name === "Blood Pressure" ?
                                            <img
                                                className="smile-icon"
                                                src={process.env.PUBLIC_URL + ((binahResults.start_value <= 120 && binahResults.end_value <= 80) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                alt=""
                                            /> : binahResults.result_name === "RMSSD" || binahResults.result_name === "SD1" || binahResults.result_name === "SD2" ?
                                                <img
                                                    className="smile-icon"
                                                    src={process.env.PUBLIC_URL + ((binahResults.result_value >= binahResults.end_value) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                    alt=""
                                                /> :
                                                <img
                                                    className="smile-icon"
                                                    src={process.env.PUBLIC_URL + ((binahResults.result_value >= binahResults.start_value && binahResults.result_value <= binahResults.end_value) ? "/assets/images/icon/smile.svg" : "/assets/images/icon/sad.svg")}
                                                    alt=""
                                                />}

                                    </div>
                                </div>
                                <div className="modal-body">
                                    <div className="text-center">

                                        {binahResults.result_name === "Blood Pressure" ?
                                            <>
                                                {binahResults.start_value <= 120 && binahResults.end_value <= 80 ?
                                                    <p className="normal-label">Normal</p> :
                                                    <p className="normal-label high-label">High</p>
                                                }
                                            </> : binahResults.result_name === "RMSSD" || binahResults.result_name === "SD1" || binahResults.result_name === "SD2" ?
                                                <>
                                                    {binahResults.result_value >= binahResults.end_value ?
                                                        <p className="normal-label">Normal</p> :
                                                        <p className="normal-label high-label">Low</p>
                                                    }
                                                </> :
                                                <>
                                                    {binahResults.result_value < binahResults.start_value ?
                                                        <p className="normal-label high-label">Low</p> :
                                                        binahResults.result_value >= binahResults.start_value && binahResults.result_value <= binahResults.end_value ?
                                                            <p className="normal-label">Normal</p> :
                                                            <p className="normal-label high-label">High</p>
                                                    }
                                                </>
                                        }

                                        {binahResults.result_name === "Blood Pressure"
                                            ? <p className="font_500">Systolic &lt; 120 Diastolic &lt; 80</p>
                                            : binahResults.result_name === "RMSSD" || binahResults.result_name === "SD1" || binahResults.result_name === "SD2"
                                                ? <p className="font_500">Normal level: Above {binahResults.end_value}</p>
                                                : <p className="font_500">Normal range: {binahResults.start_value} - {binahResults.end_value}</p>
                                        }

                                        {/* <p className="text-start">
                            {binahResults.description}
                        </p> */}

                                        <p className="text-start" dangerouslySetInnerHTML={{ __html: binahResults.description }}></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </>)
            }

        </>

    )
}

export default ReportGenerate;