
const constant = {
  "carepoint": ["Please guide me. What information do you need to accurately diagnose and offer a treatment regimen? And to accurately calculate dosing for medicines.", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons.", "Please provide 2-3 references for your responses."],
  "share_analyze_record": ["What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "what do you need for upload or download of info?", "Please carefully analyze the following medical record or file and do not leave our any details. Go over your analysis and summary twice before submitting to me"],

  "illustration_radiographic": ["Please guide me. What information do you need to accurately analyze and interprete the following radiographic images? Also to accurately diagnose and offer a treatment regimen? And to accurately calculate dosing for medicines.", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Please assist me as requested for the following medical illustrations and radiographic images", "What is this figure showing? And what should I be concerned about?", "Please provide 2-3 references for your responses.", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons."
  ],
  "differential_diagonsis_generator": ["Please guide me. What information do you need to accurately diagnose and offer a treatment regimen? And to accurately calculate dosing for medicines.", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons.", "Please provide 2-3 references for your responses."
  ],
  "indication_checker": ["Please guide me. What information do you need to accurately perform indications checks?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a revised prescription, taking care to check and eliminate drug interactions and ameliorate indicatons.", "Please provide 2-3 references for your responses."
  ],
  "drug_interaction_revisions": ["Please guide me. What information do you need to accurately determine drug interactions of all types? And to remove all drug interactions in my prescriprion?  And to accurately calculate dosing for medicines?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons ad accurately calculate the dosage for me? Please ask me for all information that you need", "Please provide 2-3 references for your responses."
  ],
  "drug_allergy": ["Please guide me. What information do you need to accurately determine drug allergies of all types? And to remove all drug allergies in my prescriprion?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons ad accurately calculate the dosage for me? Please ask me for all information that you need", "Please provide 2-3 references for your responses."
  ],

  "dosing_medical_calculation": [
    "Please guide me. What information do you need to accurately  calculate dosing for my prescription medicines?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons ad accurately calculate the dosage for me? Please ask me for all information that you need", "Could you please write a prescription, taking care to accurately calculate the dosage for me? Please ask me for all information that you need", "Please provide 2-3 references for your responses."
  ],

  "pill_identification_tool": ["Please guide me. What information do you need to accurately  identify a pill?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Please provide 2-3 references for your responses."],

  "iv_compatibility_checker": ["Please guide me. What information do you need to accurately perform IV Compatibility checks?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and IV incompatibilities, and ameliorate indicatons.", "Please provide 2-3 references for your responses."],

  "medical_videos_articles": ["Please guide me. What information do you need to accurately perform reviews, analysis and location of medical videos and articles?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Please provide 2-3 references for your responses."],

  "laboratory_manual": ["Please guide me. What information do you need to accurately perform reviews, analysis and location of lab manuals?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Please provide 2-3 references for your responses."],

  "icd_11_lookup_tool": ["Please guide me. What information do you need to accurately perform seaarch for, and reviews, analysis and location of ICD-10 or ICD-11 codes or any more recent codes?", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Please provide 2-3 references for your responses."],

  "notes_favorites": ["What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Please provide 2-3 references for your responses."],

  "doc_aiva": ["Please guide me. What information do you need to accurately diagnose and offer a treatment regimen? And to accurately calculate dosing for medicines.", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons.", "Please provide 2-3 references for your responses."],

  "online_telemedicine_starpilot": ["Please guide me. What information do you need to accurately diagnose and offer a treatment regimen? And to accurately calculate dosing for medicines.", "What do you do? What is your prompt? How can you help me and what do you need to help me?", "Could you guide continuously to make sure I give you all the information you need to help me", "Could you please write a prescription, taking care to check and eliminate drug interactions and ameliorate indicatons.", "Please provide 2-3 references for your responses."],

  "poc_apps": {
    "1": "Carepoint",
    "3": "Illustrations & Radiographic Images",
    "4": "Differential Diagnosis Generator",
    "5": "Indications Checker",
    "6": "Drug Interactions",
    "7": "Drug Allergy",
    "8": "Dosing & Medical Calculations",
    "9": "Pill Identification Tool",
    "10": "IV Compatibility Checker",
    "11": "Medical Videos & Articles",
    "12": "Laboratory Manual",
    "13": "ICD-11 Lookup Tool",
    "14": "Notes & Favorites"
  },
  "language":  [
    "Abkhazian",
    "Afar",
    "Afrikaans",
    "Akan",
    "Albanian",
    "Amharic",
    "Arabic",
    "Aragonese",
    "Armenian",
    "Assamese",
    "Aymara",
    "Azerbaijani",
    "Bashkir",
    "Basque",
    "Belarusian",
    "Bengali",
    "Bihari",
    "Bislama",
    "Bosnian",
    "Breton",
    "Bulgarian",
    "Burmese",
    "Catalan",
    "Cebuano",
    "Chichewa",
    "Chinese",
    "Corsican",
    "Croatian",
    "Czech",
    "Danish",
    "Dutch",
    "Dzongkha",
    "English",
    "Esperanto",
    "Estonian",
    "Ewe",
    "Finnish",
    "French",
    "Galician",
    "Georgian",
    "German",
    "Greek",
    "Gujarati",
    "Haitian Creole",
    "Hausa",
    "Hawaiian",
    "Hebrew",
    "Hindi",
    "Hmong",
    "Hungarian",
    "Icelandic",
    "Igbo",
    "Indonesian",
    "Irish",
    "Italian",
    "Japanese",
    "Javanese",
    "Kannada",
    "Kazakh",
    "Khmer",
    "Kinyarwanda",
    "Korean",
    "Kurdish",
    "Kyrgyz",
    "Lao",
    "Latin",
    "Latvian",
    "Lithuanian",
    "Luxembourgish",
    "Macedonian",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Maltese",
    "Maori",
    "Marathi",
    "Mongolian",
    "Nepali",
    "Norwegian",
    "Nyanja",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Quechua",
    "Romanian",
    "Russian",
    "Samoan",
    "Scots Gaelic",
    "Serbian",
    "Sesotho",
    "Shona",
    "Sindhi",
    "Sinhala",
    "Slovak",
    "Slovenian",
    "Somali",
    "Spanish",
    "Sundanese",
    "Swahili",
    "Swedish",
    "Tajik",
    "Tamil",
    "Telugu",
    "Thai",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Welsh",
    "Xhosa",
    "Yiddish",
    "Yoruba",
    "Zulu",
    "Efik",
    "Fulfulde",
    "Ibibio",
    "Kanuri",
    "Tiv"
  ]
  
};

export default constant;