import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import AWS from 'aws-sdk';
import { getTranscriptConversation, addTranscriptLanguage } from '../api/apiHandler';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import queryString from 'query-string';
import 'react-h5-audio-player/lib/styles.css';
import chatLoader from '../assets/chatloader.gif';
import talkto from '../assets/talkto.png';
import constantFunc from '../constant/constantFunc';
import constant from '../constant/constant';

import { Button } from 'react-bootstrap';
import pdficon from '../assets/pdf.png'
import txtIcon from '../assets/txtIcon.png'
import docIcon from '../assets/doc.png'
import xlsxIcon from '../assets/xlsx.jpg'
import { Brightness1 } from '@material-ui/icons';

const TranscriptChatPop = ({ transcriptChat, setTranscriptChat, transacriptName, setLanguage, language, chat, meetingid,  leaveBtn, leaveMeeting, autoPrescMain, audioStatus, handleAudio, callCreated,  screenShareBtn, handleScreenShare, handleScreenShareBtn, videoBtn, videoTiles, handleOtherUserJoin, buttonClick, guest, setInvite, invite }) => {
  const location = useParams();
  const audioRef = useRef(null);
  const fileInputRef = useRef(null);
  const [conversation, setConversation] = useState([]);

  const [loadingSpeaker, setLoadingSpeaker] = useState({
    status: false,
    index: ""
  });
  const [audioData, setAudioData] = useState({
    url: "",
    text: "",
    playStatus: false
  });
  const [uuid, setUuid] = useState('');
  // console.log("uuid usestae", uuid);
  const [isNewchat, setIsNewChat] = useState(1);
  const [handleSweetAlert, setHandleSweetAlert] = useState(true);
  const [showListeningModal, setListeningModal] = useState(false);
  const [handleStartStopListening, setStartStopListening] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const { interimTranscript, resetTranscript, transcript, listening } = useSpeechRecognition();

  const validationSchema = Yup.object().shape({
    prompt: Yup.string()
      .required('prompt is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(formOptions);
  const { errors } = formState;
  console.log("trx language==>", language)
  const selectLangFunc = async (lng) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    addTranscriptLanguage({
      language: lng,
      "meeting_id": meetingid
    }).then(async (res) => {
      // console.log('response===>', res)
      var response = JSON.parse(res);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        if (response.data.language) {
          setLanguage(response.data.language)
        }
        Swal.close()
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })

      }
    }).catch(e => {
      console.log("error select lang ==>", e)
      Swal.close()
    })
  }


  const getTrsxpt = async () => {
    try {
      getTranscriptConversation({
        language,
        "meeting_id": meetingid, language
      }).then(async (res) => {
        // console.log('response===>', res)
        var response = JSON.parse(res);
        // console.log('response===>', response.data)
        if (response.code == process.env.REACT_APP_SUCCESS) {
          if (response.data.data && response.data.data.length > 0) {
            // let newMsgT = await constantFunc.convertMarkdownLinksToHTML(response.data.response)
            setConversation(response.data.data);
          }
          // console.log("responseData-->", response)
        }
      })

    } catch (error) {

    }
  }

  useEffect(() => {
    try {
      if (language) {
        const id = setInterval(() => {
          getTrsxpt()
        }, 3000); // 10 seconds
        setIntervalId(id); // Store the interval ID
        // Cleanup function to clear the interval
        return () => {
          clearInterval(id);
          setIntervalId(null); // Reset intervalId to null
        };
      }
    } catch (error) {
      console.log("error--->", error)
    }
  }, [transcriptChat, language])

  return (
    <>

      <div className={`${chat && "w-100"} position-fixed search-sec mx-auto`} style={{
        bottom: 10, right: 10, zIndex: 999999, maxWidth: "calc(100% - 30px)", left: 10
      }}>
        {<>
          <div className="chatMain position-relative bg-light shadow" style={{ borderRadius: 20 }}>
            <div className="top d-flex align-items-center justify-content-center p-3 position-relative" style={{ background: "#216fc8", borderTopLeftRadius: 20, borderTopRightRadius: 20 }}>

              <button onClick={() => setTranscriptChat(!transcriptChat)} className="position-absolute bg-transparent border-0 p-0 text-white" style={{ top: 10, right: 10, zIndex: 9 }}>

                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M3.64001 2.27L7.50001 6.13L11.34 2.29C11.4248 2.19972 11.527 2.12749 11.6404 2.07766C11.7538 2.02783 11.8761 2.00141 12 2C12.2652 2 12.5196 2.10536 12.7071 2.29289C12.8947 2.48043 13 2.73478 13 3C13.0023 3.1226 12.9796 3.24439 12.9332 3.35788C12.8867 3.47138 12.8176 3.57419 12.73 3.66L8.84001 7.5L12.73 11.39C12.8948 11.5512 12.9915 11.7696 13 12C13 12.2652 12.8947 12.5196 12.7071 12.7071C12.5196 12.8946 12.2652 13 12 13C11.8726 13.0053 11.7454 12.984 11.6266 12.9375C11.5078 12.8911 11.4 12.8204 11.31 12.73L7.50001 8.87L3.65001 12.72C3.56552 12.8073 3.46457 12.8769 3.35301 12.925C3.24145 12.9731 3.12147 12.9986 3.00001 13C2.73479 13 2.48044 12.8946 2.2929 12.7071C2.10537 12.5196 2.00001 12.2652 2.00001 12C1.99768 11.8774 2.02043 11.7556 2.06686 11.6421C2.11328 11.5286 2.18242 11.4258 2.27001 11.34L6.16001 7.5L2.27001 3.61C2.1052 3.44876 2.00855 3.23041 2.00001 3C2.00001 2.73478 2.10537 2.48043 2.2929 2.29289C2.48044 2.10536 2.73479 2 3.00001 2C3.24001 2.003 3.47001 2.1 3.64001 2.27Z" fill="#fff" />
                </svg>

              </button>
              <div className="content text-center py-2" style={{ fontFamily: "Aharoni" }}>
                <h2 className="m-0 fw-bold text-white" style={{ fontSize: 50, lineHeight: "40px" }}>Transcript</h2>
                {/* <h5 className="m-0 text-white">Concierge</h5> */}
              </div>
            </div>
            <div className="p-3" style={{ fontSize: 12 }}>

              <div className="ans-block overflow-auto border-0"
                style={{ maxHeight: "calc(-310px + 100vh)" }}

              >



                {/* {activeChat && ()} */}
                {/* input */}
                {
                  (
                    <>
                      <div className=" py-3">
                        {/* start */}
                        <div className="form-horizontal m-t-30 position-relative">
                        
                          <div className="container py-3 border rounded-3" style={{ maxWidth: 600 }}>
                            <div className="d-flex gap-2">
                              <div className="d-flex align-items-center  justify-content-center w-100 ">
                                <div className="right d-flex align-items-center gap-10 meeting-widgets" style={{ flex: "unset" }}>
                                  {!callCreated ? "" :
                                    <div className="mute-widget" style={{ background: "#216fc8" }}>
                                      <i className={audioStatus ? "fa-solid fa-microphone" : "fa-solid fa-microphone-slash"} onClick={handleAudio} />
                                    </div>}
                                  {(!callCreated && (localStorage.getItem("user_login_type_role") == 1)) ? "" :
                                    <div className="mute-widget" style={{ background: "#216fc8" }}>
                                      <i className={"fa-solid fa-plus"} onClick={handleOtherUserJoin} />
                                    </div>}

                                  {videoTiles && (
                                    <>
                                      <div className="mute-widget" style={{ background: "#216fc8" }}>
                                        <i className={videoBtn ? "fa-solid fa-video" : "fa-solid fa-video-slash"} onClick={buttonClick} />
                                      </div>
                                      <div className="mute-widget" style={{ background: "#216fc8" }}>
                                        {handleScreenShareBtn ?
                                          <img src="/assets/images/icon/desktop.png" style={{ height: 20, filter: "brightness(500)" }} disabled={!screenShareBtn} onClick={handleScreenShare} /> : <img src="/assets/images/icon/desktop-slash.png" className='img-fluid object-fit-contain' style={{ height: 20, filter: "brightness(500)" }} disabled={!screenShareBtn} onClick={handleScreenShare} />}
                                      </div>
                                    </>)}
                                  {
                                    callCreated && (localStorage.getItem("user_login_type_role") != 1) ? <div className="mute-widget" onClick={autoPrescMain} style={{ background: "#216fc8" }}>
                                      {/* <i className={"fa-solid fa-clipboard-list"} onClick={autoPrescMain}/> */}

                                      <img src="/assets/images/icon/trxn.png" className='img-fluid object-fit-contain' style={{ height: 20, }} />
                                    </div> : ""
                                  }
                                  {callCreated && ((!guest && localStorage.getItem("user_login_type_role") == 2)) ? <div onClick={() => setInvite(!invite)} className="mute-widget" style={{ background: "#216fc8" }}>

                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M19 17V19H7V17C7 17 7 13 13 13C19 13 19 17 19 17ZM16 8C16 7.40666 15.8241 6.82664 15.4944 6.33329C15.1648 5.83994 14.6962 5.45542 14.1481 5.22836C13.5999 5.0013 12.9967 4.94189 12.4147 5.05764C11.8328 5.1734 11.2982 5.45912 10.8787 5.87868C10.4591 6.29824 10.1734 6.83279 10.0576 7.41473C9.94189 7.99667 10.0013 8.59987 10.2284 9.14805C10.4554 9.69623 10.8399 10.1648 11.3333 10.4944C11.8266 10.8241 12.4067 11 13 11C13.7956 11 14.5587 10.6839 15.1213 10.1213C15.6839 9.55871 16 8.79565 16 8ZM19.2 13.06C19.7466 13.5643 20.1873 14.1724 20.4964 14.8489C20.8054 15.5254 20.9766 16.2566 21 17V19H24V17C24 17 24 13.55 19.2 13.06ZM18 5C17.6979 5.00002 17.3976 5.04725 17.11 5.14C17.6951 5.97897 18.0087 6.97718 18.0087 8C18.0087 9.02282 17.6951 10.021 17.11 10.86C17.3976 10.9527 17.6979 11 18 11C18.7956 11 19.5587 10.6839 20.1213 10.1213C20.6839 9.55871 21 8.79565 21 8C21 7.20435 20.6839 6.44129 20.1213 5.87868C19.5587 5.31607 18.7956 5 18 5ZM8 10H5V7H3V10H0V12H3V15H5V12H8V10Z" fill="#fff" />
                                    </svg>
                                  </div> : ""}


                                  <button className={leaveBtn ? "leave-btn" : "leave-btn-disable"} onClick={leaveBtn ? leaveMeeting : undefined} disabled={!leaveBtn} >Leave</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end */}
                        <div className="d-flex justify-content-end align-items-center gap-2 pe-2">
                          {
                            (language ? (<>
                              <label htmlFor="" className="form-label m-0 fw-bold text-blue">Language: {language}</label>
                            </>) : (<>
                              <label htmlFor="" className="form-label m-0 fw-bold">Select Language:</label>
                              <Form.Select className='meeting-status status-ongoing text-white border-white font_16' aria-label="Default select example" onChange={(e) => (selectLangFunc(e.target.value))}  >
                                {
                                  constant?.language.map((item) => (<option value={item} >{item}</option>))
                                }
                              </Form.Select></>))
                          }

                        </div>
                      </div>
                      {/* End  input */}
                      {/* chat button */}

                      {/*  end chat button */}
                    </>
                  )
                }


                <div className="textmessageWrpper"
                // style={{ maxHeight: "calc(-310px + 100vh)" }}
                >
                  <div className="ans-sub-block border-0">
                    {(language && (conversation?.length == 0) && <p className="mb-2 font_18 text-blue font_600">Loading...</p>)}
                    {(!language && (<p className="mb-2 font_18 text-blue font_600">Please Select Language</p>))
                    }
                    {conversation.map((entry, index) => (
                      <div key={index} >
                        {/* {entry.is_new_chat == 0 ?
                        <div className="que-block px-0">

                          <img src={(localStorage.getItem("profile_image")) || "../assets/images/user-dummy.png"} alt="profile" className="profile" />

                          <div>
                            <p className='m-0 font_16 text-blue font_600 '>You</p>
                            <p className="mb-0">{entry.prompt}</p>
                          </div>
                        </div> : ""} */}
                        <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">

                          <div className="aiicon">
                            <img
                              src="/assets/images/logo.png"
                              alt="profile"
                              className="profile"
                            />
                          </div>
                          <div>
                            <p className="mb-2 font_16 text-blue font_600">{parse(entry?.username)}</p>
                            <pre className="mb-2 text-justify white-space-pre">{parse(entry?.response)}</pre>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </>}
      </div>
    </>);
};

export default TranscriptChatPop;
