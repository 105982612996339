import React, { useEffect, useState } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import { createChatCompletionForDocsumo } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
// import randtoken from 'rand-token'
import { v4 as uuidv4 } from 'uuid';

function Chatgpt() {

  const location = useLocation();
  const [docsumoData,setDocsumoData] = useState(location.state);
  const [conversation, setConversation] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [optionSelected, setOptionSelected] = useState(false);
  const [uuid, setUuid] = useState('');
  
  const [isNewchat,setIsNewChat] = useState(1);

  const validationSchema = Yup.object().shape({
    prompt: Yup.string()
      .required('prompt is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  useEffect(()=>{
    generateUuidIfEmpty()
    showSweetAlert()
  },[])

  useEffect(() => {
    let timeoutId;
    if (optionSelected) {
      timeoutId = setTimeout(() => {
        if (!searchValue.trim()) {
          Swal.fire({
            title: 'Please request for your requirement',
            icon: 'warning',
          });
        }
      }, 60000);
    }

    return () => clearTimeout(timeoutId);
  }, [searchValue, optionSelected]);

  useEffect(() => {
    if(optionSelected){
      docsumoCreateChatCompletion("Hello")
    }
  },[optionSelected])

  const generateUuidIfEmpty = () => {
    if (!uuid) {
      // const newUuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
      const newUuid = uuidv4();
      console.log("Generated uuid:", newUuid);
      setUuid(newUuid);
    }
  };

  const showSweetAlert = () => {
    Swal.fire({
      title: `Hello ${localStorage.getItem("user_name")}, How are you today?`,
      imageUrl: `${process.env.PUBLIC_URL + "/assets/images/logo.png"}`,
      imageWidth: 150,
      imageHeight: 120,
      showCancelButton: true,
      confirmButtonText: "I'm feeling great, thank you!",
      cancelButtonText: "I'm doing okay, could be better",
    }).then((result) => {
      if (result.isConfirmed) {
        handleOptionSelection('Option 1');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleOptionSelection('Option 2');
      }
    });
    
  }

  const handleOptionSelection = (option) => {
    console.log(`User selected option: ${option}`);
    setOptionSelected(true);
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
  };
  
  const onSubmit = (data) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })

    docsumoCreateChatCompletion(data.prompt)
    
  }

  const docsumoCreateChatCompletion = (prompt) => {
    console.log("prompt",prompt);

    const docsumoChatData = {
      "doctor_id" : docsumoData.doctor_id,
      "patient_id" : docsumoData.patient_id,
      "chemist_id" : 0,
      "uuid" : uuid,
      "doc_id" : docsumoData.doc_id,
      "user_type" : process.env.REACT_APP_USER_TYPE_PATIENT,
      "prompt" : prompt,

      "is_new_chat" : isNewchat
    }

    createChatCompletionForDocsumo(docsumoChatData).then((res) => {
      console.log('response===>', res)
      var response = JSON.parse(res);
  
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setIsNewChat(0)
        setConversation([]);
        setSearchValue('');
        const responseData = response.data.response;
       
        const newMessages = responseData.map(item => {
          let messageContent = JSON.parse(item.response);
          return {
            role: messageContent.choices[0].message.role,
            message: messageContent.choices[0].message.content,
            prompt: item.prompt,
            is_new_chat: item.is_new_chat
          }
      });
      

        setConversation(prevConversation => [...prevConversation, ...newMessages]);
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      Swal.close()
    });
  }

  return (
    <>
      <Header />
      <section className="search-sec">
        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >
          <div className="container">
            <div className="d-flex gap-2">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-solid fa-magnifying-glass"> </i>
                </span>
                <input type="text" {...register('prompt')} className="form-control" id="prompt" value={searchValue} placeholder=" Send your message..." onChange={(e)=>{setSearchValue(e.target.value)}}/>
                <div className="invalid-feedback">{errors.prompt?.message}</div>
              </div>
              <div className="">
                <button className="search-btn" type="submit">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/send1.svg"} alt="search" />
                </button>
              </div>
            </div>


            <div className="ans-block">
              {conversation.map((entry, index) => (
                <div key={index}>
                  {entry.is_new_chat == 0 ? 
                    <div className="que-block">
                      <img src={localStorage.getItem("profile_image")} alt="profile" className="profile" />
                      <div>
                      <p className='mb-0 font_16 font_600'>You</p>
                      <p className="mb-0 font_16">{entry.prompt}</p>
                      </div>
                    
                    </div> : ""}
                 
                    <div className="ans-sub-block">
                      <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                      <div className='aiicon'>
                      <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="profile" className="profile" />
                      </div>
                       <div>
                       <p className="mb-2 font_16 text-blue font_600">Monte Sereno</p>
                       <pre className="mb-2 text-justify white-space-pre">{parse(entry.message)}</pre>
                       </div>
                       
                      </div>
                    </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </section>
      <Footer />
    </>
  )
}

export default Chatgpt;
