import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { patientMeetingStatus } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import constant from '../../../constant/constant';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';
import { Dropdown, Form } from 'react-bootstrap';


function MeetingStatus() {
  const navigate = useNavigate();
  const [patientMeetingStatusList, setPatientMeetingStatusList] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [userTimezone, setUserTimezone] = useState('');
  const [selectLang, setSelectLang] = useState({});
  const [currentTime, setCurrentTime] = useState(new Date());
  // console.log("currentTime",moment(currentTime).format('DD/MM/YYYY hh:mm A'));
console.log("selectLang-->",selectLang)
  const selectLangFunc = async (e, id) => {
    try {
      console.log(e.target.value, id);
      let obj = { ...selectLang, [id]: e.target.value }
      console.log("testtt==>", obj)
      setSelectLang(obj);
    } catch (error) {

    }
  }

  useEffect(() => {
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezone = moment.tz.guess();
    setUserTimezone(timezone);
    if (searchQuery == '') {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading();
        }
      });
    }
    patientMeetingStatus({ "page": pageNo, "record_count": perPage, "search_text": searchQuery }).then((response) => {
      Swal.close();
      response = JSON.parse(response)
      console.log("response", response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        console.log("response.data.patient_data-->",response.data.patient_data)
        const defaultSelections = {};
        response.data.patient_data.forEach((item) => {
          defaultSelections[item.id] =  (item.doctor_language || "English")
        });
        setSelectLang(defaultSelections);
        setPatientMeetingStatusList(response.data.patient_data)
        setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
      } else {
        setPatientMeetingStatusList([]);
      }
    })

    // Swal.close();
  }, [searchQuery, pageNo]);


  const handlePageClick = (event) => {
    setPageNo(event.selected + 1)
  };


  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleStartMeeting = (patient_data) => {
    const startMeetingObj = {
      "name": localStorage.getItem("user_name"),
      "email": localStorage.getItem("user_email"),
      "appointment_id": patient_data.id,
      "role": parseInt(localStorage.getItem("user_login_type_role")),
      "start_meeting": 1,
      "patient_id": patient_data.patient_information.id,
      // "language": (selectLang?.[patient_data.id] || "English")
    }
    navigate('/meeting', { state: startMeetingObj })
  }

  const handleJoinMeeting = (patient_data, meetType = "") => {
    console.log("patient_data", patient_data);
    const joinMeetingObj = {
      "meeting_id": patient_data.meeting_information[0].meeting_id,
      "appointment_id": patient_data.id,
      // "user_id": patient_data.patient_information.id,
      // "name": patient_data.patient_information.patient_full_name,
      // "email": patient_data.patient_information.patient_email,
      "user_id": localStorage.getItem("user_id"),
      "name": localStorage.getItem("user_name"),
      "email": localStorage.getItem("user_email"),
      "role": parseInt(localStorage.getItem("user_role")),
      "start_meeting": 0,
      "patient_id": patient_data.patient_information.id,
      "language": (selectLang?.[patient_data.id] || "English")
    }
    console.log("joinMeetingObj", joinMeetingObj);
    if (meetType == "Gemini") {
      navigate('/meetingv2', { state: joinMeetingObj })
    } else if (meetType == "mini") {
      navigate('/meetingmini', { state: joinMeetingObj })
    } else if (meetType == "trsln") {
      navigate('/meeting-trsln', { state: joinMeetingObj })
    } else if (meetType == "trspt") {
      navigate('/meeting-trspt', { state: joinMeetingObj })
    } else {
      navigate('/meeting', { state: joinMeetingObj })
    }

  }

  const handleAutoRx = async () => {
    try {
      navigate('/doctor/carepoint/1')
    } catch (error) {

    }
  }

  const handleMeetiRecord = async (id) => {
    try {
      console.log("item.meeting_information[0].meeting_id,", id)
      navigate(`/doctor/meeting-record/${id}`)
    } catch (error) {

    }
  }

  const handleWritePrescription = (patient_data) => {
    const prescriptionObj = {
      "patient_id": patient_data.patient_information.id,
      "patient_email": patient_data.patient_information.patient_email,
      "appointment_id": patient_data.id,
      "meeting_id": 0
    }
    navigate('/doctor/prescription', { state: prescriptionObj })
  }

  return (
    <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
            <p className="font_22 font_600 mb-0">Meeting Status </p>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/doctor/home" className="font_14">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item active font_14" aria-current="page">Meeting Status</li>
              </ol>
            </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
                <div className="row justify-content-end">
                  <div className="search-bar col-lg-3 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="search-bar">
                </div>
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                  <thead>
                    <tr>
                      <th >ID</th>
                      <th >Patient Name</th>
                      <th >Phone Number</th>
                      <th >Email Address</th>
                      <th >Date Time</th>
                      <th >Status</th>
                    </tr>
                  </thead>

                  <tbody>

                    {
                      patientMeetingStatusList && patientMeetingStatusList.map((item, index) => {
                        return (
                          <tr id={item.id} key={index}>
                            <td >
                              {item.id}
                            </td>

                            <td>
                              <h3 className="doc-details">
                                <div className="doc-profile">
                                  <img src={item.patient_information.profile_image} alt="" />
                                </div>
                                {item.patient_information.patient_full_name}
                              </h3>
                            </td>
                            <td >
                              {item.patient_information.country_code + " " + item.patient_information.patient_phone_no}
                            </td>
                            <td >
                              {item.patient_information.patient_email}
                            </td>
                            <td >
                              {/* {moment.utc(item.appointment_date + " " + item.appointment_time).tz(userTimezone).format('DD/MM/YYYY hh:mm A')} */}
                              {moment.tz(`${item.appointment_date} ${item.appointment_time}`, 'YYYY-MM-DD hh:mm A', 'UTC').tz(userTimezone).format('DD/MM/YYYY hh:mm A')}
                            </td>
                            <td >
                              <div className="d-flex gap-2 flex-wrap align-items-center" style={{ maxWidth: 300 }}>
                                <button>
                                  {item.appointment_status === 1 ? (
                                    <p className="meeting-status status-pending font_600 ">Pending</p>
                                  ) : item.appointment_status === 2 ? (
                                    <p className="meeting-status status-accept font_600 ">Accept</p>
                                  ) : item.appointment_status === 3 ? (
                                    <p className="meeting-status status-reject font_600 ">Reject</p>
                                  ) : item.appointment_status === 4 ? (
                                    <p className="meeting-status status-ongoing font_600 ">Ongoing</p>
                                  ) : item.appointment_status === 5 ? (
                                    <p className="meeting-status status-accept font_600 ">Done</p>
                                  ) : (
                                    <p className="meeting-status status-reject font_600 ">Cancel</p>
                                  )}
                                </button>
                                {item.appointment_status === 5 ?
                                  <>
                                    {/* <button className="submit-btn" onClick={()=>handleWritePrescription(item)}> AutoRx</button> */}
                                    <button className="commonBtn btn-sm px-2" onClick={() => handleAutoRx()}> AutoRx</button>
                                    <button className="commonBtn btn-sm px-2" onClick={() => handleMeetiRecord(item.meeting_information[0].meeting_id)}> Meeting Record</button>

                                  </> :
                                  <>

                                    {/* {item.appointment_status === 5 ? */}
                                    {item.is_schedule === 1 && item.appointment_status === 4 ?
                                      (<>
                                      {
                                        (item?.doctor_language)?(<button>
                                          <p className="meeting-status status-ongoing font_600 ">{item?.doctor_language}</p>
                                        </button>) :(<Form.Select className='meeting-status status-ongoing text-white border-white font_16' aria-label="Default select example" value={selectLang?.[item.id] || "English"} onChange={(e) => (selectLangFunc(e, item.id))}>
                                        {
                                          constant?.language.map((item) => (<option value={item} >{item}</option>))
                                        }
                                      </Form.Select>)
                                      }
                                        
                                        <button className="meeting-status status-ongoing font_16 " style={{ whiteSpace: "nowrap" }} onClick={() => handleJoinMeeting(item)}>
                                          Join GPT
                                        </button>
                                        {/* <button className="meeting-status status-ongoing font_16 " style={{whiteSpace: "nowrap"}} onClick={()=>handleJoinMeeting(item, "Gemini")}>
                                      Join Gemini
                                      </button> */}

                                        <button className="meeting-status status-ongoing font_16 " style={{ whiteSpace: "nowrap" }} onClick={() => handleJoinMeeting(item, "mini")}>
                                          Join o1-mini
                                        </button>
                                        <button className="meeting-status status-ongoing font_16 " style={{ whiteSpace: "nowrap" }} onClick={() => handleJoinMeeting(item, "trsln")}>Join GPT Transl
                                        </button>
                                        <button className="meeting-status status-ongoing font_14 " style={{ whiteSpace: "nowrap" }} onClick={() => handleJoinMeeting(item, "trspt")}>Join GPT Lingua
                                        </button>

                                      </>)
                                      : <button className="meeting-status status-ongoing font_14" style={{ whiteSpace: "nowrap" }} onClick={() => handleStartMeeting(item)}>
                                        Start
                                      </button>}
                                  </>
                                }
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageCount={totalRecordCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  activeClassName="active"
                  activeLinkClassName="active-link"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default MeetingStatus;


