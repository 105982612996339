import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import { createChatCompletion, createTextToSpeechCompletion } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import queryString from 'query-string';
import AWS from 'aws-sdk';
// import randtoken from 'rand-token'
import AudioPlayer from 'react-h5-audio-player';
import ReactAudioPlayer from 'react-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { v4 as uuidv4 } from 'uuid';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { Button } from 'react-bootstrap';

export default function BotChatGpt() {

  const location = useParams();
  const audioRef = useRef(null);
  const [pocType, setPocType] = useState(location.poc_type);
  const [conversation, setConversation] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [optionSelected, setOptionSelected] = useState(false);
  const [uuid, setUuid] = useState('');
  console.log("uuid usestae", uuid);
  const [image, setImage] = useState("");
  const [fileType, setFileType] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [isNewchat, setIsNewChat] = useState(1);
  const [handleSweetAlert, setHandleSweetAlert] = useState(true);
  const [showListeningModal, setListeningModal] = useState(false);
  const [handleStartStopListening, setStartStopListening] = useState(true);

  const { interimTranscript, resetTranscript, transcript, listening } = useSpeechRecognition();
  const [audioData, setAudioData] = useState({
    url: "",
    text: "",
    playStatus: false
  });
  const inputRef = useRef(null);
  const validationSchema = Yup.object().shape({
    prompt: Yup.string()
      .required('prompt is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    generateUuidIfEmpty()
    // showSweetAlert()
  }, [])

  // useEffect(() => {
  //   let timeoutId;
  //   if (optionSelected) {
  //     timeoutId = setTimeout(() => {
  //       if (!searchValue.trim()) {
  //         Swal.fire({
  //           title: 'Please request for your requirement',
  //           icon: 'warning',
  //         });
  //       }
  //     }, 60000);
  //   }

  //   return () => clearTimeout(timeoutId);
  // }, [searchValue, optionSelected]);

  useEffect(() => {
    if (optionSelected) {
      pocCreateChatCompletion("Hello", uuid)
    }
  }, [optionSelected])


  function convertMarkdownLinksToHTML(text) {
    // Replace Markdown links with HTML anchor tags
    // console.log("text====>",text)
    return text.replace(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g, (match, p1, p2) => {
      return `<div class="btnWrpper"><a href="${p2}" style="height: 37px" class="d-inline-flex align-items-center justify-content-center commonBtn" >${p1}</a></div>`;
    });
  }

  function convertMarkdownLinksToText(text) {
    // Replace Markdown links with HTML anchor tags
    // console.log("text====>",text)
    return text.replace(/\[([^\]]+)\]\((https?:\/\/[^\)]+)\)/g, (match, p1, p2) => {
      return `${p1}`;
    });
  }


  const generateUuidIfEmpty = () => {
    if (!uuid) {
      // const newUuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
      const newUuid = uuidv4();
      console.log("Generated uuid:", newUuid);
      setUuid(newUuid);
      setTimeout(() => {
        Swal.fire({
          title: 'Please wait...',
          didOpen: () => {
            Swal.showLoading()
          }
        })

        pocCreateChatCompletion("Hello", newUuid)
      }, 500)
    }
  };

  const showSweetAlert = () => {
    Swal.fire({
      title: `Hello ${localStorage.getItem("user_name")}, How are you today?`,
      imageUrl: `${process.env.PUBLIC_URL + "/assets/images/logo.png"}`,
      imageWidth: 150,
      imageHeight: 120,
      showCancelButton: true,
      confirmButtonText: "I'm feeling great",
      cancelButtonText: "I'm doing okay",
    }).then((result) => {
      setHandleSweetAlert(false)
      if (result.isConfirmed) {
        handleOptionSelection('Option 1');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleOptionSelection('Option 2');
      }
    });
  }

  const handleOptionSelection = (option) => {
    console.log(`User selected option: ${option}`);
    setOptionSelected(true);
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log("event.target.files[0]==>", event.target.files[0])
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (allowedTypes.includes(event.target.files[0].type)) {
        const fileSizeLimit = 10 * 1024 * 1024;
        if (event.target.files[0].size > fileSizeLimit) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: "File size exceeds the limit of 10 MB",
            toast: true,
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          setImage(URL.createObjectURL(event.target.files[0]));
          setUploadImage(event.target.files[0]);
          setFileType("image")
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Only Image File(png, jpeg, jpg) Type Allowed!",
          toast: true,
          showConfirmButton: false,
          timer: 3000
        })
      }

    }
  }

  const handleAudioEnd = () => {
    // console.log('Audio has ended');
    setAudioData((prev) => ({ ...prev, playStatus: false }))
    // Add any additional logic you want to execute when audio ends here
  };

  const stopAudio = () => {
    // console.log('Audio has Stoped');
    setAudioData((prev) => ({ ...prev, playStatus: false }))
    audioRef.current.pause();
    audioRef.current.currentTime = 0; // Resets audio to the start
  };


  const onSubmit = async (data) => {
    if (!uploadImage) {
      console.log("searchValue--->", searchValue)
      pocCreateChatCompletion(data.prompt, uuid)
      setSearchValue("")
    } else {
      console.log('data: ',);
      let imgUrl = ""
      await uploadData(uploadImage, "/analysis_file").then((newImage) => {
        Swal.close()
        console.log('newImagenewImagenewImage', newImage)
        imgUrl = newImage;
      }).catch((e) => {
        Swal.close()
      });
      // poccreateChatAnalysisCompletion(imgUrl)
      pocCreateChatCompletion(data.prompt, uuid, `https://msh-dev.s3.amazonaws.com/analysis_file/${imgUrl}`)
    }


  }


  const uploadData = async (file, folder_type) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
      region: REGION,
    });
    const ext = file.name.split('.').pop()
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    try {
      const newFileName = new Date().getTime() + '.' + ext;
      const params = {
        Bucket: S3_BUCKET + folder_type,
        Key: newFileName,
        Body: file,
        ACL: "public-read",
      };
      return new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(newFileName);
          }
        });
      });
    } catch (error) {
      console.error("Error in UploadData:", error);
    }
  };

  const emptyImage = async () => {
    try {
      setImage("")
      setUploadImage(null)
    } catch (error) {

    }
  }
  const pocCreateChatCompletion = (prompt, uuid, image = "") => {
    console.log("prompt-->",prompt)
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    createChatCompletion({ "prompt": prompt, "uuid": uuid, "imgUrl": image, "is_new_chat": isNewchat, "type": parseInt(pocType), "login_type_role": parseInt(localStorage.getItem("user_login_type_role")) }).then((res) => {
      // console.log('response===>', res)
      var response = JSON.parse(res);

      if (response.code == process.env.REACT_APP_SUCCESS) {
        setIsNewChat(0)
        setConversation([]);
        setSearchValue('');
        setImage("")
        setUploadImage(null)
        reset()
        const responseData = response.data.response;

        const newMessages = responseData.map(item => {
          if (item.is_new_chat == 1) {
            let messageContent = JSON.parse(item.response);

            const newMsgObj = {
              role: messageContent.choices[0].message.role,
              message: convertMarkdownLinksToHTML(messageContent.choices[0].message.content),
              readMessage: convertMarkdownLinksToText(messageContent.choices[0].message.content),
              prompt: item.prompt,
              is_new_chat: item.is_new_chat
            }
            setConversation(prevConversation => [...prevConversation, newMsgObj]);
            if (handleSweetAlert) {
              // showSweetAlert()
            }
            // return {}
          } else {
            let messageContent = JSON.parse(item.response);

            const newMsgObj = {
              role: messageContent.choices[0].message.role,
              message: convertMarkdownLinksToHTML(messageContent.choices[0].message.content),
              readMessage: convertMarkdownLinksToText(messageContent.choices[0].message.content),
              prompt: item.prompt,
              is_new_chat: item.is_new_chat
            }
            setConversation(prevConversation => [...prevConversation, newMsgObj]);
          }
        });

        //   const newMessages = responseData.map(item => {
        //     let messageContent = JSON.parse(item.response);
        //     return {
        //       role: messageContent.choices[0].message.role,
        //       message: messageContent.choices[0].message.content,
        //       prompt: item.prompt,
        //       is_new_chat: item.is_new_chat
        //     }
        // });


        // setConversation(prevConversation => [...prevConversation, ...newMessages]);
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      // if (!handleSweetAlert) {
      //   Swal.close()
      // }
      Swal.close()
    });
  }

  const autoPresc = async () => {
    try {
      const meetingDataObj = {
        "uuid": uuid
      }
      const serializedObject = queryString.stringify(meetingDataObj);
      const url = `/patient/auto-prescription-poc?${serializedObject}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 500);
    } catch (error) {

    }
  }


  const openListeningModal = () => {
    setListeningModal(true)
  }

  const pocCreateTextToSpeechCompletion = (msg) => {

    if (audioData?.text && audioData?.text == msg) {
      setAudioData((prev) => ({ ...prev, playStatus: true }))
      audioRef.current.src = audioData?.url
      audioRef.current.controls = true
      audioRef.current.play();
    } else {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      createTextToSpeechCompletion({ "text": msg }).then((res) => {
        // console.log('response===>', res)
        var response = JSON.parse(res);
        if (response.code == process.env.REACT_APP_SUCCESS) {
          const arrayBuffer = response.data.buffer.data;
          console.log("arrayBuffer==>", arrayBuffer)
          // Convert buffer data to Blob
          const byteArray = new Uint8Array(arrayBuffer);
          const blob = new Blob([byteArray], { type: 'audio/mpeg' });
          // const blob = new Blob([arrayBuffer], { type: 'audio/mp3' });
          const url = URL.createObjectURL(blob);
          // setAudioSrc(url) 
          // const audio = new Audio(url);
          console.log("url==>", url)
          if (audioRef.current) {
            console.log("test")
            audioRef.current.src = url
            audioRef.current.controls = true
            audioRef.current.play();
            setAudioData({
              url: url,
              text: msg,
              playStatus: true
            })
          } else {
            // console.log("testfsed")
          }
        }
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
        if (!handleSweetAlert) {
          Swal.close()
        }
      });
    }
  }

  const closeListeningModal = () => {
    SpeechRecognition.stopListening();
    resetTranscript()
    setStartStopListening(true)
    setListeningModal(false)
    setValue('prompt', searchValue, { shouldValidate: false })
  }

  const handleListening = () => {
    if (handleStartStopListening) {
      SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
      setStartStopListening(false)
    } else {
      // SpeechRecognition.stopListening();
      // resetTranscript()
      // setStartStopListening(true)
      closeListeningModal()
    }
  }

  useEffect(() => {
    let timeout;
    if (transcript) {
      console.log('transcript: ', transcript);

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearchValue(transcript);
      }, 1000);
    } else {
      console.log("transscrpt functirn calluued");
    }
    return () => clearTimeout(timeout);
  }, [transcript, resetTranscript]);

  return (
    <>
      <Header />
      <section className="search-sec">
        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >
          <div className="container">
            <div className=" pb-3">
              <div className="d-flex justify-content-center align-items-center gap-2">
                {/* <div className="position-relative p-3 rounded w-100" style={{ border: "2px dashed #9d9d9d", maxWidth: 400 }}>
                  {(image) && 
                  <Button onClick={emptyImage} className='border-0 position-absolute text-white  rounded-circle p-2 d-flex align-items-center justify-content-center' style={{ top: 0, right: 0, zIndex: 999, transform: "translateY(-50%)", background: "#216fc8", height: 30, width: 30 }} variant='transparent'>
                    X
                  </Button>}

                  <input type="file" onChange={onImageChange} style={{ left: 0, top: 0, zIndex: 99, opacity: 0 }} className="position-absolute h-100 w-100 file" />
                  <div className="text-center">
                    {(image) && (<img src={image} alt="" className="img-fluid mx-auto object-fit-contain" style={{ height: 100, width: 100 }} />)}
                    <p className="m-0 text-muted">Upload File</p>
                    <p className="m-0 text-muted" style={{ fontSize: 10 }}>Upload File for Analysis</p>
                  </div>
                </div> */}
                {/* <button className="search-btn" type="button" onClick={onSubmit12}>
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/send1.svg"} alt="search" />
              </button> */}
              </div>
            </div>
            <div className="d-flex gap-2">
              <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-solid fa-magnifying-glass"> </i>
                </span>
                <input type="text" {...register('prompt')} className="form-control" id="prompt" value={searchValue}
                  placeholder={parseInt(location.poc_type) == 7 ? "Hello I have a fever and a headache and some fatigue. What might be the problem? what do you need for accurate differential diagnosis?" :
                    parseInt(location.poc_type) == 2 ? "Please check the following prescription for interactions and indications. Please revise the prescription to remove interactions and ameliorate severe indications." :
                      " Send your message..."}
                  onChange={(e) => { setSearchValue(e.target.value) }} />
                <div className="invalid-feedback">{errors.prompt?.message}</div>
              </div>
              <div className="d-flex gap-2">
                <button className="search-btn" type="submit">
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/send1.svg"} alt="search" />
                </button>
                <button className="search-btn" type="button" onClick={openListeningModal}>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/mdi_microphone.svg"} alt="microphone" />
                </button>
                <button className="search-btn" type="button" onClick={autoPresc}>
                  {/* <i class="fa-solid fa-clipboard-list text-white" ></i> */}
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/trxn.png"} alt="search" />
                </button>
              </div>
            </div>


            <div className="ans-block">
              {conversation.map((entry, index) => (
                <div key={index}>
                  {entry.is_new_chat == 0 ?
                    <div className="que-block">
                      <img src={localStorage.getItem("profile_image")} alt="profile" className="profile" />
                      <div>
                        <p className='mb-0 font_16 font_600'>You</p>
                        <p className="mb-0 font_16">{entry.prompt}</p>
                      </div>

                    </div> : ""}

                  <div className="ans-sub-block">
                    <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                      <div className='aiicon'>
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="profile" className="profile" />
                      </div>
                      <div>
                        <p className="mb-2 font_16 text-blue font_600">Monte Sereno</p>
                        <pre className="mb-2 text-justify white-space-pre">{parse(entry.message)}</pre>
                        {(
                          audioData?.playStatus && audioData?.text == (entry.message) && (<button className="search-btn" type="button"
                            onClick={() => {
                              stopAudio()
                            }}>
                            <i className="fa-solid fa-pause text-white" />
                          </button>)
                        ) ||
                          (<button className="search-btn" type="button" onClick={() => {
                            pocCreateTextToSpeechCompletion(entry.message)
                          }}>
                            <i className="fa-solid fa-volume-up text-white" />
                          </button>)}
                        <audio onEnded={handleAudioEnd} style={{ height: 0, width: 0, opacity: 0 }} ref={audioRef} controls>
                          <source src="" type="audio/mpeg" />
                        </audio>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </form>
      </section>
      <Footer />

      {showListeningModal &&
        (<>
          <div
            className={`modal fade ${showListeningModal ? 'show' : ''}`}
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            tabIndex="-1"
            style={{ display: showListeningModal ? 'block' : 'none' }}
          >
            <div className="modal-dialog prescription-modal modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title" id="staticBackdropLabel">Listening</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeListeningModal}
                  />
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    {!handleStartStopListening ? <img src={process.env.PUBLIC_URL + "/assets/images/icon/icons8-sound.gif"} alt="search" /> : ""}
                  </div>
                  {/* <div className="d-flex gap-3">
                        <pre className="mb-2 mt-3 text-justify white-space-pre">{searchValue}</pre>
                      </div> */}
                </div>
                <div className="modal-footer">
                  {/* <button type="button" className="btn btn-secondary" onClick={handleListening}>{handleStartStopListening ? "Start" : "Stop"}</button> */}
                  <button
                    type="button"
                    className="btn submit-btn font_18"
                    onClick={handleListening}
                  >
                    {handleStartStopListening ? "Start" : "Stop"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>)
      }
    </>
  )
}