import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import Swal from 'sweetalert2';
import { inviteUsersListMeeting, sendMeetingInvite } from '../../../src/api/apiHandler';
export function InviteListPop({ meetType="", invite, setInvite, meeting_id, appointment_id }) {
  const [inviteList, setInviteList] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  // useEffect(() => {
  //   apiHandler("")
  // }, [])
  const sendIvite = async (email) => {
    try {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      const res = await sendMeetingInvite({
        email, meeting_id, appointment_id, meetType
      });
      const response = JSON.parse(res);
      // console.log("response-->", response)
      Swal.close()
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: response?.message,
        toast: true,
        showConfirmButton: false,
        timer: 2000
      });
      // Swal.fire({
      //   position: 'top-end',
      //   icon: 'error',
      //   title: response.message,
      //   toast: true,
      //   showConfirmButton: false,
      //   timer: 2000
      // })
     
    } catch (error) {
      console.log("error send invite-->", error)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      apiHandler(searchTerm);
    }, 2000);

    // Clear the timer when searchTerm changes or component unmounts
    return () => clearTimeout(timer);
  }, [searchTerm]);
  const apiHandler = async (text = "") => {
    try {
      const res = await inviteUsersListMeeting({
        "search_text": text
      });
      const response = JSON.parse(res);
      console.log("response-->", response)
      setInviteList(response.data.users)
    } catch (error) {
      console.log("error ap ahdler--<", error)
    }
  }
  const handleInvite = () => {
    setInvite(!invite)
  }
  return (
    <>
      <Modal centered show={invite} onHide={handleInvite}>
        <Modal.Header className="border-0" closeButton>
          <Modal.Title className="fw-bold" style={{ fontSize: 20 }}>Invite List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="top">
            <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder="search" className="form-control" />
          </div>
          <div className="content pt-3">
            <ul className="list-unstyled ps-0 mb-0">
              {inviteList?.map((item) => (<> <li className="py-2 d-flex align-items-center justify-content-between">
                <div className="left d-flex align-items-center gap-10">
                  <div className="imgWrp flex-shrink-0 me-2">
                    {/* <img src="" style={{ height: 40, width: 40 }} alt="" className="img-fluid rounded-circle object-fit-cover" /> */}
                  </div>
                  <div className="content">

                    <h6 className="m-0">{item?.email}</h6>
                  </div>
                </div>
                <button onClick={() => {
                  sendIvite(item?.email)
                }} className="btn d-flex align-items-center justify-content-center rounded-pill commonBtn">
                  Send
                </button>
              </li></>))}

            </ul>
          </div>

        </Modal.Body>      </Modal>
    </>
  )
}

// export default InviteListPop