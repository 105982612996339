import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { patientAppointmentRequests, updateAppoinmentStatus } from '../../../api/apiHandler';
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';

import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import moment from 'moment-timezone';



function PatientAppoinment() {
  const navigate = useNavigate();
  const [patientList, setPatientList] = useState([]);
  const [totalRecordCount, setTotalRecordCount] = useState(0)
  const [pageNo, setPageNo] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [searchQuery, setSearchQuery] = useState('');
  const [userTimezone, setUserTimezone] = useState('');


  useEffect(() => {
    // const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezone = moment.tz.guess();
    setUserTimezone(timezone);
    patientAppointmentRequestsList()
  }, [searchQuery, pageNo]);

  const patientAppointmentRequestsList = () => {
    if(searchQuery == ''){
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
            Swal.showLoading();
        }
      });
    }
    patientAppointmentRequests({ "page": pageNo, "record_count": perPage, "search_text": searchQuery}).then((response) => {
      Swal.close();
      response = JSON.parse(response)
      // console.log("response",response);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        setPatientList(response.data.patient_data)
        setTotalRecordCount(Math.ceil(response.data.total_record_count / perPage))
      } else {
        setPatientList([]);
      }
    })

    // Swal.close();
  }

  
  const handlePageClick = (event) => {
    setPageNo(event.selected + 1)
  };

  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleAppointmentRequest = (appointment_id,request_type) => {

    const updateStatusObj = {
        "appointment_id" : appointment_id,
        "appointment_status" : request_type
    }

    updateAppoinmentStatus(updateStatusObj).then((response) => {
        response = JSON.parse(response)
        console.log("response--->",response)
        if (response.code == process.env.REACT_APP_SUCCESS) {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: response.message,
              toast: true,
              showConfirmButton: false,
              timer: 2000
            })

            setTimeout(()=>{
              patientAppointmentRequestsList()
            },2000)

        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
    })
  }

  return (
    <>
      <Header />
      <section className="doctorsec-pad">
        <div className="container">
          <div className="mt-4">
              <p className="font_22 font_600 mb-0">Appointments</p>
              <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                  <Link to="/" className="font_14">
                      Home
                  </Link>
                  </li>
                  <li className="breadcrumb-item active font_14" aria-current="page">
                  Appointments
                  </li>
              </ol>
              </nav>
          </div>
          <div className="card">
            <div className="card-body doctor-table-card">
              <div className="table-responsive">
                <div className="row justify-content-end">
                  <div className="search-bar col-lg-3 col-md-3">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search..."
                      value={searchQuery}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
                <div className="search-bar">
                </div>
                <table id="mangeUser" className="table table-hover dataTable js-exportable">
                  <thead>
                    <tr>
                      <th >ID</th>
                      <th >Patient Name</th>
                      <th >Phone Number</th>
                      <th >Email Address</th>
                      <th >Date Time</th>
                      <th >Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {
                        patientList && patientList.map((item, index) => {
                        return (
                          <tr id={item.id} key={index}>
                            <td >
                              {item.id}
                            </td>

                            <td>
                              <h3 className="doc-details">
                                <div className="doc-profile">
                                  <img src={item.patient_information.profile_image} alt="" />
                                </div>
                                {/* {item.first_name} {item.middle_name} {item.last_name} */}
                                {item.patient_information.patient_full_name}
                              </h3>
                            </td>
                            <td >
                              {item.patient_information.country_code + " " + item.patient_information.patient_phone_no}
                              {/* {item.patient_information.patient_phone_no} */}
                            </td>
                            <td >
                              {item.patient_information.patient_email}
                            </td>
                            <td >
                              {/* {moment.utc(item.appointment_date + " " + item.appointment_time).tz(userTimezone).format('DD/MM/YYYY hh:mm A')} */}
                              {moment.tz(`${item.appointment_date} ${item.appointment_time}`, 'YYYY-MM-DD hh:mm A', 'UTC').tz(userTimezone).format('DD/MM/YYYY hh:mm A')}
                            </td>
                           
                            <td >
                                <div className="d-flex gap-2">
                                    <button className="btn btn-sm bg-success-light font_16" onClick={()=>handleAppointmentRequest(item.id,process.env.REACT_APP_MEETING_ACCEPT)}>
                                        <i className="fas fa-check"></i> Accept
                                    </button>
                                    <button className="btn btn-sm bg-danger-light font_16" onClick={()=>handleAppointmentRequest(item.id,process.env.REACT_APP_MEETING_REJECT)}>
                                        <i className="fas fa-times"></i> Reject
                                    </button>
                                </div>
                            </td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel=" > "
                  onPageChange={handlePageClick}
                  pageCount={totalRecordCount}
                  previousLabel=" < "
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  disabledClassName="disabled"
                  activeClassName="active"
                  activeLinkClassName="active-link"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default PatientAppoinment;

