import React, { useEffect, useState } from 'react'
import AWS from 'aws-sdk';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { editProfile, getProfile, getCountryList, getIsabelCountryList } from '../../../api/apiHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';


export default function Profile() {
    const navigate = useNavigate();
    const [profileDetails, setProfileDetails] = useState(null)
    const [countryList, setCountryList] = useState([])
    const [image, setImage] = useState(null);
    const [uploadImage, setUploadImage] = useState(null);
    const [is_married, setMarried] = useState("1");
    const [have_children, setChildren] = useState("1");
    const [isabelCountryList, setIsabelCountryList] = useState([]);
    const [countryId, setCountryId] = useState("");

    const handleChange = (event) => {
        if (event.target.name == 'is_married') {
            setMarried(event.target.value);
        }
        if (event.target.name == 'have_children') {
            setChildren(event.target.value);
        }
    };
    const uploadData = async (file) => {
        const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
        const REGION = process.env.REACT_APP_AWS_S3_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
            secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
            region: REGION,
          });
          const ext = file.name.split('.').pop()
          const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
          });

        try {
            const newFileName = new Date().getTime() + '.'+ext;
            const params = {
                Bucket: S3_BUCKET + "/profile_images",
                Key: newFileName,
                Body: file,
                ACL: "public-read",
            };

            return new Promise((resolve, reject) => {
                s3.putObject(params, (err, data) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(newFileName);
                    }
                });
            });
        } catch (error) {
            console.error("Error in UploadData:", error);
        }
    };
    useEffect(() => {
        Swal.fire({
            title: 'Please wait...',
            didOpen: () => {
                Swal.showLoading()
            }
        })
        getCountryList({}).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setCountryList(response.data)
            }
            Swal.close()
        })

        getProfile({}).then((response) => {
            response = JSON.parse(response)
            console.log("response",response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                setProfileDetails(response.data)
                setImage(response.data.profile_image);
                localStorage.setItem("profile_image",response.data.profile_image);
                setMarried(response.data.is_married);
                setChildren(response.data.have_children);
                setCountryId(response.data.country_id)
                if(response.data.country_id != 0){
                    setValue('isabel_country_name', response.data.country_id);
                }
            }
            Swal.close()
        });

        getIsabelCountryList({}).then((response) => {
            response = JSON.parse(response)
            console.log('response: ', response);
            if (response.code == process.env.REACT_APP_SUCCESS) {
                // const country = response.data.find(country => country.country_id == countryId);
                // console.log('country: ', country);
                // setSelectedCountry(country);
                setIsabelCountryList(response.data)
            }
            Swal.close()
        })

    }, [])

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImage(URL.createObjectURL(event.target.files[0]));
            setUploadImage(event.target.files[0]);
        }
    }
    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required("Please enter first name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "first name must be at most 128 character"),
        middle_name: Yup.string()
            .required("Please enter middle initial")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "middle initial must be at most 128 character"),
        last_name: Yup.string()
            .required("Please enter last name")
            .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field')
            .max(128, "last name must be at most 128 character"),
        country_name: Yup.string()
            .optional("Please enter country name"),
        state_name: Yup.string()
            .optional("Please enter state name"),
        city_name: Yup.string()
            .optional("Please enter city name"),
        isabel_country_name: Yup.string()
            .required("Please select isabel country name"),
        is_married: Yup.string()
            .optional("Please select married option"),
        have_children: Yup.string()
            .optional("Please select children option"),
        how_many_children: Yup.number()
            .optional("Please enter how many children"),
        id_national: Yup.string()
            .optional("Please enter national id"),
        id_other: Yup.string()
            .optional("Please enter other id"),
        nationality: Yup.string()
            .optional("Please enter nationality"),
        biometric_id: Yup.string()
            .optional("Please enter biometric id"),
        insurance_id: Yup.string()
            .optional("Please enter insurance id"),
        age: Yup.number()
            .optional("Please enter age"),
        address: Yup.string()
            .optional("Please enter address"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, setValue, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = async (data) => {
        console.log('data: ', data);
        if (uploadImage != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            await uploadData(uploadImage).then((newImage)=>{
                Swal.close()
                console.log('newImagenewImagenewImage',newImage)
                profileDetails.profile_image_name = newImage;
                
            }).catch((e)=>{
                console.log("error----------",e);
                Swal.close()
                
            });
        }
        console.log('uploadImage',uploadImage)
        var params = {
            "first_name": data.first_name,
            "middle_name": data.middle_name,
            "last_name": data.last_name,
            "country": data.country_name,
            "state": data.state_name,
            "city": data.city_name,
            "country_id": parseInt(data.isabel_country_name),
            "is_married": is_married.toString(),
            "have_children": have_children.toString(),
            "how_many_children": data.how_many_children,
            "id_national": data.id_national,
            "id_other": data.id_other,
            "nationality": data.nationality,
            "biometric_id": data.biometric_id,
            "insurance_id": data.insurance_id,
            "profile_image": profileDetails.profile_image_name,
            ...(data.age != undefined) && { "age": data.age },
            ...(data.address != undefined) && { "address": data.address },
        }
        console.log('params', params)
        editProfile(params).then((response) => {
            response = JSON.parse(response)
            if (response.code == process.env.REACT_APP_SUCCESS) {
                localStorage.setItem("user_name",data.first_name);
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
                // setTimeout(() => {
                //     navigate("/")
                // }, 2000)
            } else {
                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: response.message,
                    toast: true,
                    showConfirmButton: false,
                    timer: 2000
                })
            }
        })
    }

    if (image == null) return <div className="container"><div className="mt-5"></div></div>

    return (
        <>
        <Header />
        <section className="share-medical">
            <div className="container">
                <div className="">
                    <p className="font_22 font_600 mb-0">My Profile</p>
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><Link to="/patient/home" className="font_14">Home</Link></li>
                            <li className="breadcrumb-item active font_14" aria-current="page">My profile</li>
                        </ol>
                    </nav>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card-profile feedback-sec">
                            <p className="font_22 font_600 mb-0">Personal details</p>
                            <p className="text-gray font_15">Manage your personal information.</p>
                            <hr />
                            <form onSubmit={handleSubmit(onSubmit)} >
                                <div className="row justify-content-center">

                                    <div className="col-lg-4">
                                        <div className="card-profile shadow-none">
                                            <p className="text-center font_18 font_500 text-gray">Profile picture</p>
                                            <div className="upload-files-container">
                                                <div className="card-pic mb-3">
                                                    <img src={image} alt="profile-picture" />
                                                </div>
                                                <div className="drag-file-area">
                                                    <label className="label"> <span className="browse-files">
                                                        <input type="file" className="default-file-input d-none" onChange={onImageChange} />
                                                        <span className="browse-files-text">
                                                            <img src={process.env.PUBLIC_URL + "/assets/images/upload-img.svg"} alt="upload-icon" />
                                                        </span> </span> </label>
                                                </div>
                                            </div>
                                            <p className="title mb-0">{profileDetails.first_name} {profileDetails.last_name}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">First name</label>
                                            <input type="text" {...register('first_name')} className="form-control" defaultValue={profileDetails.first_name} placeholder="Please enter first name" />
                                            <div className="invalid-feedback">{errors.first_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Middle Initial</label>
                                            <input type="text" {...register('middle_name')} className="form-control" defaultValue={profileDetails.middle_name} placeholder="Please enter middle initial" />
                                            <div className="invalid-feedback">{errors.middle_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Last name</label>
                                            <input type="text" {...register('last_name')} className="form-control" defaultValue={profileDetails.last_name} placeholder="Please enter last name" />
                                            <div className="invalid-feedback">{errors.last_name?.message}</div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Country</label>
                                            <input type="text" {...register('country_name')} className="form-control" defaultValue={profileDetails.country} placeholder="Please enter country name" />
                                            <div className="invalid-feedback">{errors.country_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">State</label>
                                            <input type="text" {...register('state_name')} className="form-control" defaultValue={profileDetails.state} placeholder="Please enter state name" />
                                            <div className="invalid-feedback">{errors.state_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">City</label>
                                            <input type="text" {...register('city_name')} className="form-control" defaultValue={profileDetails.city} placeholder="Please enter city name" />
                                            <div className="invalid-feedback">{errors.city_name?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Isabel Country</label>
                                            {countryId != 0 && isabelCountryList.length > 0 ? 
                                            <select className="form-control" {...register('isabel_country_name')} defaultValue={countryId}>
                                                <option value="">Select Country</option>
                                                {isabelCountryList && isabelCountryList.map((item) => (
                                                    <option key={item.country_name} value={item.country_id}>
                                                        {item.country_name}
                                                    </option>
                                                ))}
                                            </select> : 
                                            ((countryId == "" || countryId == 0) && isabelCountryList.length > 0) ? 
                                            <select className="form-control" {...register('isabel_country_name')} defaultValue="">
                                                <option value="">Select Country</option>
                                                {isabelCountryList && isabelCountryList.map((item) => (
                                                    <option key={item.country_name} value={item.country_id}>
                                                        {item.country_name}
                                                    </option>
                                                ))}
                                            </select> : "" }
                                            <div className="invalid-feedback">{errors.isabel_country_name?.message}</div>
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Age</label>
                                            <input type="number" {...register('age')} className="form-control" defaultValue={profileDetails.age} placeholder="Please enter age" />
                                            <div className="invalid-feedback">{errors.age?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Address</label>
                                            <input type="text" {...register('address')} className="form-control" defaultValue={profileDetails.address} placeholder="Please enter address" />
                                            <div className="invalid-feedback">{errors.address?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">How Many Children</label>
                                            <input type="number" {...register('how_many_children')} className="form-control" defaultValue={profileDetails.how_many_children} placeholder="Please enter how many children" />
                                            <div className="invalid-feedback">{errors.how_many_children?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Married?</label>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="form-check form-custom d-flex align-items-center gap-1">
                                                    <input
                                                        className="form-check-input radio"
                                                        type="radio"
                                                        name="is_married"
                                                        id="is_marriedYes"
                                                        {...register('is_married')}
                                                        value="1"
                                                        checked={is_married == 1}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="is_marriedYes">
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check form-custom d-flex align-items-center gap-1">
                                                    <input
                                                        className="form-check-input radio"
                                                        type="radio"
                                                        name="is_married"
                                                        id="is_marriedNo"
                                                        {...register('is_married')}
                                                        value="0"
                                                        checked={is_married == 0}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="is_marriedNo">
                                                        No
                                                    </label>
                                                </div>
                                                <div className="invalid-feedback">{errors.is_married?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label className="form-label">Children?</label>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="form-check form-custom d-flex align-items-center gap-1">
                                                    <input
                                                        className="form-check-input radio"
                                                        type="radio"
                                                        name="have_children"
                                                        id="have_childrenYes"
                                                        {...register('have_children')}
                                                        value="1"
                                                        checked={have_children == 1}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="have_childrenYes">
                                                        Yes
                                                    </label>
                                                </div>

                                                <div className="form-check form-custom d-flex align-items-center gap-1">
                                                    <input
                                                        className="form-check-input radio"
                                                        type="radio"
                                                        name="have_children"
                                                        id="have_childrenNo"
                                                        {...register('have_children')}
                                                        value="0"
                                                        checked={have_children == 0}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label" htmlFor="have_childrenNo">
                                                        No
                                                    </label>
                                                </div>
                                                <div className="invalid-feedback">{errors.have_children?.message}</div>
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">I.D. (National)</label>
                                            <input type="text" {...register('id_national')} className="form-control" defaultValue={profileDetails.id_national} placeholder="Please enter id national" />
                                            <div className="invalid-feedback">{errors.id_national?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">I.D. (Other)</label>
                                            <input type="text" {...register('id_other')} className="form-control" defaultValue={profileDetails.id_other} placeholder="Please enter id other" />
                                            <div className="invalid-feedback">{errors.id_other?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Nationality</label>
                                            <input type="text" {...register('nationality')} className="form-control" defaultValue={profileDetails.nationality} placeholder="Please enter nationality" />
                                            <div className="invalid-feedback">{errors.nationality?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Biometric ID</label>
                                            <input type="text" {...register('biometric_id')} className="form-control" defaultValue={profileDetails.biometric_id} placeholder="Please enter biometric id" />
                                            <div className="invalid-feedback">{errors.biometric_id?.message}</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-xl-6 col-12">
                                        <div className="mb-3">
                                            <label htmlFor="exampleFormControlInput1" className="form-label">Insurance ID</label>
                                            <input type="text" {...register('insurance_id')} className="form-control" defaultValue={profileDetails.insurance_id} placeholder="Please enter biometric id" />
                                            <div className="invalid-feedback">{errors.insurance_id?.message}</div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            <button type="submit" className="submit-btn mt-3 w-100">
                                                Update
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
 <Footer />
 </>
    )
}