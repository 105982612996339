import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import * as Yup from 'yup';
import AWS from 'aws-sdk';
import { createChatAnalysisCompletion, createTextToSpeechCompletion } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import styles from "./Anaylysischat.module.scss"
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
// import randtoken from 'rand-token'
import AudioPlayer from 'react-h5-audio-player';
import ReactAudioPlayer from 'react-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { v4 as uuidv4 } from 'uuid';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import queryString from 'query-string';
import { Button } from 'react-bootstrap';
export default function SearchChatGpt() {

  const location = useParams();
  const audioRef = useRef(null);
  const [pocType, setPocType] = useState(location.poc_type);
  const [conversation, setConversation] = useState();
  // const [audioSrc, setAudioSrc] = useState("");
  const [searchValue, setSearchValue] = useState('');
  console.log('searchValue: ', searchValue);
  const [optionSelected, setOptionSelected] = useState(false);
  const [uuid, setUuid] = useState('');
  const [showChat, setShowChat] = useState(true);
  const [isNewchat, setIsNewChat] = useState(1);
  const [handleSweetAlert, setHandleSweetAlert] = useState(true);
  const [showListeningModal, setListeningModal] = useState(false);
  const [handleStartStopListening, setStartStopListening] = useState(true);
  const [image, setImage] = useState("");
  const [fileType, setFileType] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const { interimTranscript, resetTranscript, transcript, listening } = useSpeechRecognition();

  const validationSchema = Yup.object().shape({
    prompt: Yup.string()
      .required('prompt is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset, setValue } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    generateUuidIfEmpty()
    // showSweetAlert()
  }, [])

  useEffect(() => {
    let timeoutId;
    if (optionSelected) {
      timeoutId = setTimeout(() => {
        if (!searchValue.trim()) {
          Swal.fire({
            title: 'Please request for your requirement',
            icon: 'warning',
          });
        }
      }, 60000);
    }

    return () => clearTimeout(timeoutId);
  }, [searchValue, optionSelected]);

  // useEffect(() => {
  //   if (optionSelected) {
  //     poccreateChatAnalysisCompletion("Hello", uuid)
  //   }
  // }, [optionSelected])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log("event.target.files[0]==>", event.target.files[0])
      const allowedTypes = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
      if (allowedTypes.includes(event.target.files[0].type)) {
        const fileSizeLimit = 10 * 1024 * 1024;
        if (event.target.files[0].size > fileSizeLimit) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: "File size exceeds the limit of 10 MB",
            toast: true,
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          setImage(URL.createObjectURL(event.target.files[0]));
          setUploadImage(event.target.files[0]);
          setFileType((event.target.files[0].type == "application/pdf") ? ("pdf") : ("image"))
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Only Pdf & Image File(png, jpeg, jpg) Type Allowed!",
          toast: true,
          showConfirmButton: false,
          timer: 3000
        })
      }

    }
  }


  const uploadData = async (file, folder_type) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
      region: REGION,
    });
    const ext = file.name.split('.').pop()
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    try {
      const newFileName = new Date().getTime() + '.' + ext;
      const params = {
        Bucket: S3_BUCKET + folder_type,
        Key: newFileName,
        Body: file,
        ACL: "public-read",
      };
      return new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(newFileName);
          }
        });
      });
    } catch (error) {
      console.error("Error in UploadData:", error);
    }
  };

  const generateUuidIfEmpty = () => {
    if (!uuid) {
      // const newUuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
      const newUuid = uuidv4();
      console.log("Generated uuid:", newUuid);
      setUuid(newUuid);
      // setTimeout(() => {
      // Swal.fire({
      //   title: 'Please wait...',
      //   didOpen: () => {
      //     Swal.showLoading()
      //   }
      // })

      // poccreateChatAnalysisCompletion("Hello", newUuid)
      // }, 500)
    }
  };

  const showSweetAlert = () => {
    Swal.fire({
      title: `Hello Dr. ${localStorage.getItem("user_name")}, How are you today?`,
      imageUrl: `${process.env.PUBLIC_URL + "/assets/images/logo.png"}`,
      imageWidth: 150,
      imageHeight: 120,
      showCancelButton: true,
      confirmButtonText: "I'm feeling great",
      cancelButtonText: "I'm doing okay",
    }).then((result) => {
      setHandleSweetAlert(false)
      if (result.isConfirmed) {
        handleOptionSelection('Option 1');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleOptionSelection('Option 2');
      }
    });
  }

  const handleOptionSelection = (option) => {
    console.log(`User selected option: ${option}`);
    setOptionSelected(true);
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
  };

  const onSubmit = async () => {
    try {
      if (!uploadImage) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Please Upload File",
          toast: true,
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        console.log('data: ',);
        let imgUrl = ""
        await uploadData(uploadImage, "/analysis_file").then((newImage) => {
          Swal.close()
          console.log('newImagenewImagenewImage', newImage)
          imgUrl = newImage;
        }).catch((e) => {
          Swal.close()
        });
        poccreateChatAnalysisCompletion(imgUrl)
      }
    } catch (error) {
      console.log("err===>", error)
    }

  }
console.log("setConversation==>",conversation)
  const poccreateChatAnalysisCompletion = (imgUrl) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    console.log("fileType", fileType, "url", imgUrl)
    createChatAnalysisCompletion({ "fileType": fileType, "imgUrl": ("https://msh-dev.s3.amazonaws.com/analysis_file/" + imgUrl) }).then((res) => {
      // console.log('response===>', res)
      var response = JSON.parse(res);

      if (response.code == process.env.REACT_APP_SUCCESS) {
        const responseData = response.data.response;
        setConversation(responseData);
        Swal.close()
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      if (!handleSweetAlert) {
        Swal.close()
      }
    });
  }

  const openListeningModal = () => {
    setListeningModal(true)
  }
  const autoPresc = async () => {
    try {
      const meetingDataObj = {
        "uuid": uuid
      }
      const serializedObject = queryString.stringify(meetingDataObj);
      const url = `/doctor/auto-prescription-poc?${serializedObject}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 500);
    } catch (error) {

    }
  }



  const pocCreateTextToSpeechCompletion = (msg) => {
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    createTextToSpeechCompletion({ "text": msg }).then((res) => {
      // console.log('response===>', res)
      var response = JSON.parse(res);
      if (response.code == process.env.REACT_APP_SUCCESS) {
        const arrayBuffer = response.data.buffer.data;
        console.log("arrayBuffer==>", arrayBuffer)
        // Convert buffer data to Blob
        const byteArray = new Uint8Array(arrayBuffer);
        const blob = new Blob([byteArray], { type: 'audio/mpeg' });
        // const blob = new Blob([arrayBuffer], { type: 'audio/mp3' });
        const url = URL.createObjectURL(blob);
        // setAudioSrc(url) 
        // const audio = new Audio(url);
        console.log("url==>", url)
        if (audioRef.current) {
          console.log("test")
          audioRef.current.src = url
          audioRef.current.controls = true
          audioRef.current.play();
        } else {
          // console.log("testfsed")
        }
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      if (!handleSweetAlert) {
        Swal.close()
      }
    });
  }

  const closeListeningModal = () => {
    SpeechRecognition.stopListening();
    resetTranscript()
    setStartStopListening(true)
    setListeningModal(false)
    // setValue('prompt', searchValue, { shouldValidate: false })
  }

  const handleListening = () => {
    if (handleStartStopListening) {
      SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
      setStartStopListening(false)
    } else {
      // SpeechRecognition.stopListening();
      // resetTranscript()
      // setStartStopListening(true)
      closeListeningModal()
    }
  }
  const emptyImage = async () => {
    try {
      setImage("")
      setUploadImage(null)
      setFileType("")
    } catch (error) {

    }
  } 
  useEffect(() => {
    let timeout;
    if (transcript) {
      console.log('transcript: ', transcript);

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearchValue(transcript);
      }, 1000);
    } else {
      console.log("transscrpt functirn calluued");
    }
    return () => clearTimeout(timeout);
  }, [transcript, resetTranscript]);

  return (
    <>
      <Header />
      <section className={`${styles.searchSec} search-sec`}>
        <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit)} >
          <div className="container">
            <div className=" pb-3">
              {/* <div className="input-group mb-3">
                <span className="input-group-text" id="basic-addon1">
                  <i className="fa-solid fa-magnifying-glass"> </i>
                </span>
                <input type="text" {...register('prompt')} className="form-control" id="prompt" value={searchValue}
                  placeholder={parseInt(location.poc_type) == 7 ? "Hello I have a fever and a headache and some fatigue. What might be the problem? what do you need for accurate differential diagnosis?" :
                    parseInt(location.poc_type) == 2 ? "Please check the following prescription for interactions and indications. Please revise the prescription to remove interactions and ameliorate severe indications." :
                      " Send your message..."}
                  onChange={(e) => { setSearchValue(e.target.value) }} />
                <div className="invalid-feedback">{errors.prompt?.message}</div>
              </div> */}

              <div className="d-flex justify-content-center align-items-center gap-2">
                <div className="position-relative p-3 rounded w-100" style={{ border: "2px dashed #9d9d9d", maxWidth: 400 }}>
                {(image) && 
                  <Button onClick={emptyImage} className='border-0 position-absolute text-white  rounded-circle p-2 d-flex align-items-center justify-content-center' style={{ top: 0, right: 0, zIndex: 999, transform: "translateY(-50%)", background: "#216fc8", height: 30, width: 30 }} variant='transparent'>
                    X
                  </Button>}
                  <input type="file" onChange={onImageChange} style={{ left: 0, top: 0, zIndex: 99, opacity: 0 }} className="position-absolute h-100 w-100 file" />
                  <div className="text-center">
                    {(image) && (<img src={image} alt="" className="img-fluid mx-auto object-fit-contain" style={{ height: 100, width: 100 }} />)}
                    <p className="m-0 text-muted">Upload File</p>
                    <p className="m-0 text-muted" style={{ fontSize: 10 }}>Upload File for Analysis</p>
                  </div>
                </div>
                <button className="search-btn" type="button" onClick={onSubmit}>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/send1.svg"} alt="search" />
                </button>
                {/* <button className="search-btn" type="button" onClick={openListeningModal}>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/mdi_microphone.svg"} alt="microphone" />
                </button>
                <button className="search-btn" type="button" onClick={autoPresc}>
                  <i class="fa-solid fa-clipboard-list text-white" ></i>
                  <img src={process.env.PUBLIC_URL + "/assets/images/icon/trxn.png"} alt="search" />
                </button> */}
              </div>
            </div>

            <section className="share-medical search-sec">
  <div className="container">
    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li className="nav-item" role="presentation">
        <button
          className="nav-link active"
          id="pills-home-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-home"
          type="button"
          role="tab"
          aria-controls="pills-home"
          aria-selected="true"
        >
          Download Medical Record
        </button>
      </li>
      <li className="nav-item" role="presentation">
        <button
          className="nav-link"
          id="pills-profile-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-profile"
          type="button"
          role="tab"
          aria-controls="pills-profile"
          aria-selected="false"
        >
          Upload Medical Record
        </button>
      </li>
    </ul>
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane fade active show"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <div className="feedback-sec">
          <form>
            <div className="card p-2 p-sm-3">
              <p className="font_30 font_500 text-blue text-download d-flex align-items-center gap-2 mb-0">
                <img src="/assets/images/icon/download-icon.svg" alt="" />
                Download Medical Records
              </p>
              <hr />
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mb-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Permission Code
                    </label>
                    <div className="input-group p-0">
                      <input
                        type="text"
                        name="permission_code"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter permission code"
                      />
                      <button
                        className="btn check-btn btn-outline-secondary font_500"
                        type="button"
                        id="button-addon2"
                      >
                        Check
                      </button>
                    </div>
                    <div className="invalid-feedback" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Date
                    </label>
                    <input
                      type="text"
                      name="daterange"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mb-3">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Specific Record if Any
                    </label>
                    <select
                      className="form-select font_500"
                      id="inputGroupSelect04"
                      aria-label="Example select with button addon"
                      name="specific_record"
                    >
                      <option value="">Select Options</option>
                      <option value={1}>Symptoms Checker</option>
                      <option value={2}>AI Vital signs</option>
                    </select>
                    <div className="invalid-feedback" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="appointments">
                    <div className="appointment-list align-items-center">
                      <div className="d-flex gap-2 flex-wrap">
                        <button type="submit" className="connect-btn download">
                          <i className="fa-solid fa-eye pe-2" />
                          View Record
                        </button>
                        <button type="submit" className="connect-btn">
                          <i className="fa-solid fa-envelope pe-2" />
                          Email Record
                        </button> 
                        <button type="submit" className="connect-btn download">
                          <i className="fa-solid fa-download pe-2" />
                          Download
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        className="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div className="feedback-sec">
          <form>
            <div className="card p-2 p-sm-3">
              <p className="font_30 font_500 text-blue text-upload d-flex align-items-center gap-2 mb-0">
                <img src="/assets/images/icon/upload-icon.svg" alt="" />
                Upload Medical Records
              </p>
              <hr />
              <div className="row">
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mb-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Permission Code
                    </label>
                    <div className="input-group p-0">
                      <input
                        type="text"
                        name="permission_code2"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Enter permission code"
                      />
                      <button
                        className="btn check-btn btn-outline-secondary font_500"
                        type="button"
                        id="button-addon2"
                      >
                        Check
                      </button>
                    </div>
                    <div className="invalid-feedback" />
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-12">
                  <div className="mb-4">
                    <label
                      htmlFor="exampleFormControlInput1"
                      className="form-label"
                    >
                      Key Value
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="imageType"
                    >
                      <option value="">Select your image type</option>
                      <option value="others__Kagrz">Key and Value</option>
                      <option value="others__Kagrz__zByyE__MsuFV">
                        Table Structure
                      </option>
                    </select>
                    <div className="invalid-feedback" />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className=" mb-3">
                    <div className="input--file">
                      <div className="input-icon">
                        <img
                          src="/assets/images/upload-paper-record.png"
                          alt=""
                        />
                      </div>
                      <input
                        name="paperRecord"
                        type="file"
                        className="cursor-pointer"
                      />
                    </div>
                    <h6 className="text-center text-blue font_18 font_500 text-uppercase">
                      Upload paper record
                    </h6>
                    <div className="invalid-feedback" />
                  </div>
                </div>
                <div className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className=" mb-3">
                    <div className="input--file">
                      <div className="input-icon">
                        <img src="/assets/images/upload-efile.png" alt="" />
                      </div>
                      <input
                        name="eFile"
                        type="file"
                        className="cursor-pointer"
                      />
                    </div>
                    <h6 className="text-center text-blue font_18 font_500">
                      UPLOAD e-File
                    </h6>
                    <div className="invalid-feedback" />
                  </div>
                </div>
                <div className="col-6 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                  <div className=" mb-3">
                    <div className="input--file">
                      <div className="input-icon">
                        <img
                          src="/assets/images/upload-voice-camera.png"
                          alt=""
                        />
                      </div>
                      <input
                        name="voiceCameraRecord"
                        type="file"
                        className="cursor-pointer"
                      />
                    </div>
                    <h6 className="text-center text-blue font_18 font_500">
                      UPLOAD VOICE/CAMERA RECORD
                    </h6>
                    <div className="invalid-feedback" />
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4">
                  <div className="mb-sm-4 mt-sm-4">
                    <button
                      type="submit"
                      className="connect-btn download w-100"
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

            <div className="ans-block">
              {conversation && (
                <div>
                  <div className="ans-sub-block">
                    <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                      <div className='aiicon'>
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="profile" className="profile" />
                      </div>
                      <div>
                        {/* <p className="mb-2 font_16 text-blue font_600">Monte Sereno</p> */}
                        <pre className="mb-2 text-justify white-space-pre">{conversation}</pre>
                        {/* <button className="search-btn" type="button" onClick={() => {
                          pocCreateTextToSpeechCompletion(conversation)
                        }}>
                          <i class="fa-solid fa-volume-up text-white" ></i>
                        </button>
                        <audio style={{ height: 0, width: 0, opacity: 0 }} ref={audioRef} controls>
                          <source src="" type="audio/mpeg" />
                        </audio> */}
                        {/* <AudioPlayer
                         autoPlay
                         src="https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3"
                         onPlay={e => console.log("onPlay")}
                       // other props here
                       /> */}
                        {/* <ReactAudioPlayer
                         src="https://commondatastorage.googleapis.com/codeskulptor-demos/DDR_assets/Kangaroo_MusiQue_-_The_Neverwritten_Role_Playing_Game.mp3"
                         autoPlay={true}
                         controls
                       /> */}
                      </div>

                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </form>
      </section>
      <Footer />

      {showListeningModal &&
        (<>
          <div
            className={`modal fade ${showListeningModal ? 'show' : ''}`}
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            tabIndex="-1"
            style={{ display: showListeningModal ? 'block' : 'none' }}
          >
            <div className="modal-dialog prescription-modal modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title" id="staticBackdropLabel">Listening</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeListeningModal}
                  />
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    {!handleStartStopListening ? <img src={process.env.PUBLIC_URL + "/assets/images/icon/icons8-sound.gif"} alt="search" /> : ""}
                  </div>
                  {/* <div className="d-flex gap-3">
                        <pre className="mb-2 text-justify white-space-pre">{searchValue}</pre>
                      </div> */}
                </div>
                <div className="modal-footer">
                  {/* <button type="button" className="btn btn-secondary" onClick={handleListening}>{handleStartStopListening ? "Start" : "Stop"}</button> */}
                  <button
                    type="button"
                    className="btn submit-btn font_18"
                    onClick={handleListening}
                  >
                    {handleStartStopListening ? "Start" : "Stop"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>)
      }
    </>
  )
}