import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import parse from 'html-react-parser';
import $ from 'jquery';
import * as Yup from 'yup';
import moment from 'moment';
import AWS from 'aws-sdk';
import { Col, Row } from 'react-bootstrap';
import { createChatXrayMriAnalysisCompletion, createTextToSpeechCompletion, uploadMedicalRecord, getPatientReport, getPatientReportAnalysis } from '../../../api/apiHandler';
import Swal from 'sweetalert2';
import styles from "./Anaylysischat.module.scss"
import Header from '../../../components/header/Header';
import { Button } from 'react-bootstrap';
import Footer from '../../../components/footer/Footer';
// import randtoken from 'rand-token'
import AudioPlayer from 'react-h5-audio-player';
import ReactAudioPlayer from 'react-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import { v4 as uuidv4 } from 'uuid';
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import queryString from 'query-string';
import pdficon from '../../../assets/pdf.png'
import constant from '../../../constant/constant';
import constantFunc from '../../../constant/constantFunc';
import txtIcon from '../../../assets/txtIcon.png'
import docIcon from '../../../assets/doc.png'
import xlsxIcon from '../../../assets/xlsx.jpg'
export default function SearchChatGpt() {
  const [step, setStep] = useState('1');

  const location = useParams();
  const audioRef = useRef(null);
  const fileInputRef = useRef(null);
  const [pocType, setPocType] = useState(location.poc_type);
  const [conversation, setConversation] = useState([]);
  // const [audioSrc, setAudioSrc] = useState("");
  const [searchValue, setSearchValue] = useState('');
  console.log('searchValue: ', searchValue);
  const [optionSelected, setOptionSelected] = useState(false);
  const [uuid, setUuid] = useState('');

  const [isNewchat, setIsNewChat] = useState(1);
  const [handleSweetAlert, setHandleSweetAlert] = useState(true);
  const [showListeningModal, setListeningModal] = useState(false);
  const [handleStartStopListening, setStartStopListening] = useState(true);
  const [image, setImage] = useState("");
  const [hints, setHints] = useState(constant?.illustration_radiographic);
  const [fileType, setFileType] = useState(null);
  const [showChat, setShowChat] = useState(true);
  const [uploadImage, setUploadImage] = useState(null);
  const { interimTranscript, resetTranscript, transcript, listening } = useSpeechRecognition();

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [recordType, setRecordType] = useState(0);
  console.log('recordType: ', recordType);

  const [uploadPaperRecord, setUploadPaperRecord] = useState(null);
  const [paperRecordImage, setPaperRecordImage] = useState(null);

  const [uploadEFileRecord, setUploadEFileRecord] = useState(null);
  const [eFileRecordImage, setEFileRecordImage] = useState(null);

  const [uploadVoiceCameraRecord, setUploadVoiceCameraRecord] = useState(null);
  const navigate = useNavigate();
  const [audioData, setAudioData] = useState({
    url: "",
    text: "",
    playStatus: false
  });
  const inputRef = useRef(null);
  useEffect(() => {
    generateUuidIfEmpty()
    // showSweetAlert()
  }, [])
  const handleAudioEnd = () => {
    // console.log('Audio has ended');
    setAudioData((prev) => ({ ...prev, playStatus: false }))
    // Add any additional logic you want to execute when audio ends here
  };

  const stopAudio = () => {
    // console.log('Audio has Stoped');
    setAudioData((prev) => ({ ...prev, playStatus: false }))
    audioRef.current.pause();
    audioRef.current.currentTime = 0; // Resets audio to the start
  };


  useEffect(() => {
    let timeoutId;
    if (optionSelected) {
      timeoutId = setInterval(() => {
        if (!searchValue.trim()) {
          Swal.fire({
            title: 'Please request for your requirement',
            icon: 'warning',
          });
        }
      }, 120000);
    }

    return () => clearTimeout(timeoutId);
  }, [searchValue, optionSelected]);

  // useEffect(() => {
  //   let timeoutId;
  //   if (optionSelected) {
  //     timeoutId = setTimeout(() => {
  //       if (!searchValue.trim()) {
  //         Swal.fire({
  //           title: 'Please request for your requirement',
  //           icon: 'warning',
  //         });
  //       }
  //     }, 60000);
  //   }

  //   return () => clearTimeout(timeoutId);
  // }, [searchValue, optionSelected]);

  useEffect(() => {
    if (optionSelected) {
      poccreateChatAnalysisCompletion("Hello", uuid)
    }
  }, [optionSelected])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      console.log("event.target.files[0]==>", event.target.files[0])
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf', 'text/plain', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
      if (allowedTypes.includes(event.target.files[0].type)) {
        const fileSizeLimit = 10 * 1024 * 1024;
        if (event.target.files[0].size > fileSizeLimit) {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: "File size exceeds the limit of 10 MB",
            toast: true,
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          setImage(URL.createObjectURL(event.target.files[0]));
          setUploadImage(event.target.files[0]);
          if (event.target.files[0].type == 'application/pdf') {
            setFileType("pdf")
          } else if (event.target.files[0].type == 'text/plain') {
            setFileType("text")
          } else if (event.target.files[0].type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            setFileType("doc")
          } else if (event.target.files[0].type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            setFileType("xlsx")
          } else {
            setFileType("image")
          }
        }
      } else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Only Image File(png, jpeg, jpg, PDF, Text, docx, xlsx) Type Allowed!",
          toast: true,
          showConfirmButton: false,
          timer: 3000
        })
      }

    }
  }


  const uploadData = async (file, folder_type) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
      region: REGION,
    });
    const ext = file.name.split('.').pop()
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    try {
      const newFileName = new Date().getTime() + '.' + ext;
      const params = {
        Bucket: S3_BUCKET + folder_type,
        Key: newFileName,
        Body: file,
        ACL: "public-read",
      };
      return new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(newFileName);
          }
        });
      });
    } catch (error) {
      console.error("Error in UploadData:", error);
    }
  };

  const generateUuidIfEmpty = () => {
    if (!uuid) {
      // const newUuid = randtoken.generate(16, "abcdefghijklnmopqrstuvwxyz");
      const newUuid = uuidv4();
      console.log("Generated uuid:", newUuid);
      setUuid(newUuid);
      setTimeout(() => {
        Swal.fire({
          title: 'Please wait...',
          didOpen: () => {
            Swal.showLoading()
          }
        })
        poccreateChatAnalysisCompletion("Hello", newUuid)
      }, 500)
    }
  };

  const showSweetAlert = () => {
    Swal.fire({
      title: `Hello Dr. ${localStorage.getItem("user_name")}, How are you today?`,
      imageUrl: `${process.env.PUBLIC_URL + "/assets/images/logo.png"}`,
      imageWidth: 150,
      imageHeight: 120,
      showCancelButton: true,
      confirmButtonText: "I'm feeling great",
      cancelButtonText: "I'm doing okay",
    }).then((result) => {
      setHandleSweetAlert(false)
      if (result.isConfirmed) {
        handleOptionSelection('Option 1');
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        handleOptionSelection('Option 2');
      }
    });
  }

  const handleOptionSelection = (option) => {
    console.log(`User selected option: ${option}`);
    setOptionSelected(true);
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
  };

  const onSubmit12 = async () => {
    try {
      if (!uploadImage) {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: "Please Upload File",
          toast: true,
          showConfirmButton: false,
          timer: 3000
        })
      } else {
        console.log('data: ',);
        let imgUrl = ""
        await uploadData(uploadImage, "/analysis_file").then((newImage) => {
          Swal.close()
          console.log('newImagenewImagenewImage', newImage)
          imgUrl = newImage;
        }).catch((e) => {
          Swal.close()
        });
        // poccreateChatAnalysisCompletion(imgUrl)
        poccreateChatAnalysisCompletion(`Do detailed analysis. Analysis Format`, uuid, `https://msh-dev.s3.amazonaws.com/analysis_file/${imgUrl}`)
        setShowChat(true)
      }
    } catch (error) {
      console.log("err===>", error)
    }

  }
  console.log("setConversation==>", conversation)
  const poccreateChatAnalysisCompletion = (prompt, uuid, image = "") => {

    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    console.log("image final send", image)
    // console.log("fileType", fileType, "url", imgUrl)
    createChatXrayMriAnalysisCompletion({ "prompt": prompt, "imgUrl": image, "imgType": fileType, "uuid": uuid, "is_new_chat": isNewchat, "type": parseInt(pocType), "login_type_role": parseInt(localStorage.getItem("user_login_type_role")) }).then((res) => {
      // createChatXrayMriAnalysisCompletion({ "fileType": fileType, "imgUrl": ("https://msh-dev.s3.amazonaws.com/analysis_file/" + imgUrl) }).then((res) => {
      // console.log('response===>', res)
      var response = JSON.parse(res);


      if (response.code == process.env.REACT_APP_SUCCESS) {
        if (fileInputRef.current) {
          fileInputRef.current.value = ''; // Clear the input field
        }
        setImage("")
        setUploadImage(null)
        setFileType("")
        setIsNewChat(0)
        setConversation([]);
        setSearchValue("")
        // setSearchValue('');
        // reset()
        const responseData = response.data.response;

        const newMessages = responseData.map(async item => {
          if (item.is_new_chat == 1) {
            if (handleSweetAlert) {
              showSweetAlert()
            }
            // return {}
          } else {
            let messageContent = JSON.parse(item.response);

            const newMsgObj = {
              role: messageContent.choices[0].message.role,
              // message: messageContent.choices[0].message.content,
              message: await constantFunc.convertMarkdownLinksToHTML(messageContent.choices[0].message.content),
              readMessage: await constantFunc.convertMarkdownLinksToText(messageContent.choices[0].message.content),
              prompt: item.prompt,
              is_new_chat: item.is_new_chat
            }
            setConversation(prevConversation => [...prevConversation, newMsgObj]);
          }
        });

        //   const newMessages = responseData.map(item => {
        //     let messageContent = JSON.parse(item.response);
        //     return {
        //       role: messageContent.choices[0].message.role,
        //       message: messageContent.choices[0].message.content,
        //       prompt: item.prompt,
        //       is_new_chat: item.is_new_chat
        //     }
        // });


        // setConversation(prevConversation => [...prevConversation, ...newMessages]);
      }
      else {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
      if (!handleSweetAlert) {
        Swal.close()
      }
    });


  }

  const openListeningModal = () => {
    setListeningModal(true)
  }
  const autoPresc = async () => {
    try {
      const meetingDataObj = {
        "uuid": uuid
      }
      const serializedObject = queryString.stringify(meetingDataObj);
      const url = `/doctor/auto-prescription-poc?${serializedObject}`;
      setTimeout(() => {
        window.open(url, '_blank');
      }, 500);
    } catch (error) {

    }
  }








  useEffect(() => {
    // Initialize DateRangePicker after component mount
    $(inputRef.current).daterangepicker({
      opens: 'left'
    }, function (start, end, label) {
      setStartDate(start.format('YYYY-MM-DD'))
      setEndDate(end.format('YYYY-MM-DD'))
      // console.log("A new date selection was made: " + start.format('YYYY-MM-DD') + ' to ' + end.format('YYYY-MM-DD'));
    });

    // // Ensure to destroy DateRangePicker when component unmounts to avoid memory leaks
    // return () => {
    //     $(inputRef.current).data('daterangepicker').remove();
    // };
  }, []);


  // upload paper record
  const uploadPaperRedordData = async (file, file_path) => {
    const S3_BUCKET = process.env.REACT_APP_AWS_S3_NAME;
    const REGION = process.env.REACT_APP_AWS_S3_REGION;

    AWS.config.update({
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_KEY,
      region: REGION,
    });
    const ext = file.name.split('.').pop()
    const s3 = new AWS.S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    try {
      const newFileName = new Date().getTime() + '.' + ext;
      const params = {
        Bucket: S3_BUCKET + file_path,
        Key: newFileName,
        Body: file,
        ACL: "public-read",
      };

      return new Promise((resolve, reject) => {
        s3.putObject(params, (err, data) => {
          if (err) {
            reject(err);
          } else {
            resolve(newFileName);
          }
        });
      });
    } catch (error) {
      console.error("Error in UploadPaperRecordData:", error);
    }
  };

  const handlePaperRecord = (e) => {
    console.log("e--> paper record", e.target.files[0])
    const fileExtension = e.target.files[0].name.split('.').pop().toLowerCase();
    if (['jpeg', 'jpg', 'png'].includes(fileExtension)) {
      setPaperRecordImage(URL.createObjectURL(e.target.files[0]))
      setUploadPaperRecord(e.target.files[0]);
    } else {
      setPaperRecordImage(null)
      setUploadPaperRecord(null);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Please Upload Valid File Type : ['jpeg', 'jpg', 'png']",
        toast: true,
        showConfirmButton: false,
        timer: 2000
      })
    }

  }
  const emptyImage = async () => {
    try {
      if (fileInputRef.current) {
        fileInputRef.current.value = ''; // Clear the input field
      }
      setImage("")
      setUploadImage(null)
      setFileType("")
    } catch (error) {

    }
  }
  const handleEFile = (e) => {
    const fileExtension = e.target.files[0].name.split('.').pop().toLowerCase();
    if (['jpeg', 'jpg', 'png'].includes(fileExtension)) {
      setEFileRecordImage(URL.createObjectURL(e.target.files[0]))
      setUploadEFileRecord(e.target.files[0]);
    } else {
      setEFileRecordImage(null)
      setUploadEFileRecord(null)
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: "Please Upload Valid File Type : ['jpeg', 'jpg', 'png']",
        toast: true,
        showConfirmButton: false,
        timer: 2000
      })
    }
  }

  const handleVoiceCameraRecord = (e) => {
    setUploadVoiceCameraRecord(e.target.files[0]);
  }

  // validation for download medical record
  const validationSchema = Yup.object().shape({
    // record_name: Yup.string()
    //     .required("Please enter name of record")
    //     .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed for this field'),
    // permission_code: Yup.string()
    //     .required('Please enter permission code'),
    specific_record: Yup.string()
      .required('Please select specific record'),

  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors } = formState;

  const handleEmailInput = async () => {
    const email = prompt("Enter your email:");
    if (email === null) return handleEmailInput();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Please enter a valid email address',
        toast: true,
        showConfirmButton: false,
        timer: 1000
      });
      return handleEmailInput();
    }
    return email;
  };

  const onSubmit = async (data) => {
    let params;
    params = {
      // "record_type": recordType,
      "start_date": startDate,
      "end_date": endDate,
      "type": parseInt(data.specific_record)
    }
    console.log('params', params)
    Swal.fire({
      title: 'Please wait...',
      didOpen: () => {
        Swal.showLoading()
      }
    })
    getPatientReportAnalysis(params).then((response) => {
      response = JSON.parse(response)
      // console.log("response", response);
      // poccreateChatAnalysisCompletion("Hello", uuid)
      if (response.code == process.env.REACT_APP_SUCCESS) {
        console.log("response", JSON.stringify(response.data.data));
        poccreateChatAnalysisCompletion(`Do analysis of this patient Data: ${JSON.stringify(response.data.data)}`, uuid)
        setShowChat(true)
        // Swal.close()
        // if (recordType == 3) {
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'success',
        //     title: response.message,
        //     toast: true,
        //     showConfirmButton: false,
        //     timer: 2000
        //   })
        // } else {
        //   window.open(response.data.url, '_blank');
        // }
      } else {
        Swal.close()
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: response.message,
          toast: true,
          showConfirmButton: false,
          timer: 2000
        })
      }
    }).catch((errors) => {
      Swal.close()
      console.log("errror", errors.response);
      if (errors.response != undefined) {
        console.log("enter errr");
        if (errors.response.status == process.env.REACT_APP_NOT_FOUND) {
          Swal.fire({
            position: 'top-end',
            icon: 'info',
            title: 'No data found',
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        } else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Something went wrong',
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
      }
    })

  }



  // const onSubmitChat = async (data) => {
  //   console.log("data-->",data)
  //   poccreateChatAnalysisCompletion(data.prompt, uuid)
  //   setSearchValue("")
  // }


  // const onSubmitChat = async () => {
  // console.log("searchValue--->", searchValue)
  // poccreateChatAnalysisCompletion(searchValue, uuid)
  // setSearchValue("")
  // }

  const onSubmitChat = async () => {

    if (!uploadImage) {
      console.log("searchValue--->", searchValue)
      poccreateChatAnalysisCompletion(searchValue, uuid)
      setSearchValue("")
    } else {
      console.log('data: ',);
      let imgUrl = ""
      await uploadData(uploadImage, "/analysis_file").then((newImage) => {
        Swal.close()
        console.log('newImagenewImagenewImage', newImage)
        imgUrl = newImage;
      }).catch((e) => {
        Swal.close()
      });
      // poccreateChatAnalysisCompletion(imgUrl)
      poccreateChatAnalysisCompletion(searchValue, uuid, `https://msh-dev.s3.amazonaws.com/analysis_file/${imgUrl}`)
      setShowChat(true)
    }
  }

  // validation for upload medical record
  const validationSchema2 = Yup.object().shape({
    imageType: Yup.string().required('Please select your image type'),
    paperRecord: Yup.mixed()
      .test(
        'fileType',
        'Invalid file type. Only JPG, JPEG, and PNG files are allowed.',
        (value) => {
          if (value && value.length > 0) {
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
            return allowedTypes.includes(value[0].type);
          }
          // If value is not present (empty), consider it as valid.
          return true;
        }
      ),

    eFile: Yup.mixed()
      .test(
        'fileType',
        'Invalid file type. Only JPG, JPEG, and PNG files are allowed.',
        (value) => {
          if (value && value.length > 0) {
            const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
            return allowedTypes.includes(value[0].type);
          }
          // If value is not present (empty), consider it as valid.
          return true;
        }
      ),

    voiceCameraRecord: Yup.mixed()
      .test(
        'fileType',
        'Invalid file type. Only MP3 and MP4 files are allowed.',
        (value) => {
          if (value && value.length > 0) {
            const allowedTypes = ['audio/mp3', 'video/mp4'];
            return allowedTypes.includes(value[0].type);
          }
          // If value is not present (empty), consider it as valid.
          return true;
        }
      ),

  });

  const formOptions2 = { resolver: yupResolver(validationSchema2) };
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm(formOptions2);

  const onSubmit2 = async (data) => {
    if (uploadPaperRecord != null) {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      await uploadPaperRedordData(uploadPaperRecord, "/docsumo/paper_record").then((paperrecord) => {
        Swal.close()
        console.log('paperrecordpaperrecord', paperrecord)
        data.paperRecordImage = paperrecord;

      }).catch((e) => {
        Swal.close()
      });
    }

    if (uploadEFileRecord != null) {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      await uploadPaperRedordData(uploadPaperRecord, "/docsumo/efile_record").then((efilerecord) => {
        Swal.close()
        console.log('efilerecordefilerecord', efilerecord)
        data.eFileRecordImage = efilerecord;

      }).catch((e) => {
        Swal.close()
      });
    }

    if (uploadVoiceCameraRecord != null) {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      await uploadPaperRedordData(uploadPaperRecord, "/docsumo/voice_camera_record").then((voicecamerarecord) => {
        Swal.close()
        console.log('voicecamerarecordvoicecamerarecord', voicecamerarecord)
        data.voiceCameraRecordImage = voicecamerarecord;

      }).catch((e) => {
        Swal.close()
      });
    }

    if (uploadPaperRecord != null || uploadEFileRecord != null || uploadVoiceCameraRecord != null) {
      var params = {
        "patient_id": parseInt(localStorage.getItem('user_id')),
        "doctor_id": 0,
        "chemist_id": 0,
        "user_type": parseInt(process.env.REACT_APP_USER_TYPE_PATIENT),
        "paper_record_file": data.paperRecordImage != null ? [data.paperRecordImage] : [],
        "e_file": data.eFileRecordImage != null ? [data.eFileRecordImage] : [],
        "camera_input_file": data.voiceCameraRecordImage != null ? [data.voiceCameraRecordImage] : [],
        "type": data.imageType,
        "file_type": "url",
        // "name_of_record": "abc",
        // "permission_code": "ABCAS123",
      }
      console.log('params', params)

      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      uploadMedicalRecord(params).then((response) => {
        response = JSON.parse(response)
        if (response.code == process.env.REACT_APP_SUCCESS) {
          Swal.close()
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
          setTimeout(() => {
            navigate(0)
          }, 2000)
        } else {
          Swal.close()
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
      })
    }

  }

  const pocCreateTextToSpeechCompletion = (msg) => {
    if (audioData?.text && audioData?.text == msg) {
      setAudioData((prev) => ({ ...prev, playStatus: true }))
      audioRef.current.src = audioData?.url
      audioRef.current.controls = true
      audioRef.current.play();
    } else {
      Swal.fire({
        title: 'Please wait...',
        didOpen: () => {
          Swal.showLoading()
        }
      })
      createTextToSpeechCompletion({ "text": msg }).then((res) => {
        // console.log('response===>', res)
        var response = JSON.parse(res);
        if (response.code == process.env.REACT_APP_SUCCESS) {
          const arrayBuffer = response.data.buffer.data;
          console.log("arrayBuffer==>", arrayBuffer)
          // Convert buffer data to Blob
          const byteArray = new Uint8Array(arrayBuffer);
          const blob = new Blob([byteArray], { type: 'audio/mpeg' });
          // const blob = new Blob([arrayBuffer], { type: 'audio/mp3' });
          const url = URL.createObjectURL(blob);
          // setAudioSrc(url) 
          // const audio = new Audio(url);
          console.log("url==>", url)
          if (audioRef.current) {
            console.log("test")
            audioRef.current.src = url
            audioRef.current.controls = true
            audioRef.current.play();
            setAudioData({
              url: url,
              text: msg,
              playStatus: true
            })
          } else {
            // console.log("testfsed")
          }
        }
        else {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: response.message,
            toast: true,
            showConfirmButton: false,
            timer: 2000
          })
        }
        if (!handleSweetAlert) {
          Swal.close()
        }
      });
    }

  }

  const closeListeningModal = () => {
    SpeechRecognition.stopListening();
    resetTranscript()
    setStartStopListening(true)
    setListeningModal(false)
    // setValue('prompt', searchValue, { shouldValidate: false })
  }

  const handleListening = () => {
    if (handleStartStopListening) {
      SpeechRecognition.startListening({ continuous: true, timeout: 5000 });
      setStartStopListening(false)
    } else {
      // SpeechRecognition.stopListening();
      // resetTranscript()
      // setStartStopListening(true)
      closeListeningModal()
    }
  }

  useEffect(() => {
    let timeout;
    if (transcript) {
      console.log('transcript: ', transcript);

      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setSearchValue(transcript);
      }, 1000);
    } else {
      console.log("transscrpt functirn calluued");
    }
    return () => clearTimeout(timeout);
  }, [transcript, resetTranscript]);

  return (
    <>
      <Header />
      <section className={`${styles.searchSec} search-sec`}>
        {/* <form className="form-horizontal m-t-30" onSubmit={handleSubmit(onSubmit12)} > */}
        <div className="container">

          <Row>
            <Col lg="12" className="my-2">
              <div className="border rounded p-3 text-center bg-white mx-auto"
                style={{ border: "2px dashed #9d9d9d", maxWidth: 400 }}>
                <h4 className="m-0 fw-bold">
                  {constant?.poc_apps?.[3]}
                </h4>
              </div>
            </Col>
            <Col lg="12" className="my-2">
              <div className=" pb-3">
                <div className="d-flex justify-content-center align-items-center gap-2">
                  <div className="position-relative p-3 rounded w-100" style={{ border: "2px dashed #9d9d9d", maxWidth: 400 }}>
                    {(image) &&
                      <Button onClick={emptyImage} className='border-0 position-absolute text-white  rounded-circle p-2 d-flex align-items-center justify-content-center' style={{ top: 0, right: 0, zIndex: 999, transform: "translateY(-50%)", background: "#216fc8", height: 30, width: 30 }} variant='transparent'>
                        X
                      </Button>}
                    <input type="file" ref={fileInputRef} onChange={onImageChange} style={{ left: 0, top: 0, zIndex: 99, opacity: 0 }} className="position-absolute h-100 w-100 file" />
                    <div className="text-center">
                      {(image) && (<img src={((fileType == "pdf" && pdficon) || (fileType == "text" && txtIcon) || (fileType == "doc" && docIcon) || (fileType == "xlsx" && xlsxIcon) || image)} alt="" className="img-fluid mx-auto object-fit-contain" style={{ height: 100, width: 100 }} />)}
                      <p className="m-0 text-muted">Upload File</p>
                      <p className="m-0 text-muted" style={{ fontSize: 10 }}>Upload File for Analysis</p>
                    </div>
                  </div>
                  {/* <button className="search-btn" type="button" onClick={onSubmit12}>
                <img src={process.env.PUBLIC_URL + "/assets/images/icon/send1.svg"} alt="search" />
              </button> */}
                </div>
              </div>

              <div className="ans-block">
                {
                  (showChat && (<div className="form-horizontal m-t-30"   >
                    <div className="container pt-3">
                      <div className="d-flex gap-2">
                        <div className="input-group mb-3">
                          <span className="input-group-text" id="basic-addon1">
                            <i className="fa-solid fa-magnifying-glass"> </i>
                          </span>
                          <input type="text" {...register('prompt')} className="form-control" id="prompt" value={searchValue}
                            placeholder={parseInt(location.poc_type) == 7 ? "Hello I have a fever and a headache and some fatigue. What might be the problem? what do you need for accurate differential diagnosis?" :
                              parseInt(location.poc_type) == 2 ? "Please check the following prescription for interactions and indications. Please revise the prescription to remove interactions and ameliorate severe indications." :
                                " Send your message..."}
                            onChange={(e) => { setSearchValue(e.target.value) }} />
                          <div className="invalid-feedback">{errors.prompt?.message}</div>
                        </div>
                        <div className="d-flex gap-2">
                          <button className="search-btn" type="button" onClick={onSubmitChat}>
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/send1.svg"} alt="search" />
                          </button>
                          <button className="search-btn" type="button" onClick={openListeningModal}>
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/mdi_microphone.svg"} alt="microphone" />
                          </button>
                          <button className="search-btn" type="button" onClick={autoPresc}>
                            {/* <i class="fa-solid fa-clipboard-list text-white" ></i> */}
                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/trxn.png"} alt="search" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>))
                }
                {
                  hints?.length > 0 && (<div className=" overflow-auto d-flex align-items-center justify-content-cednter mb-2 gap-10 px-4 w-100">
                    {
                      hints?.length > 0 && hints?.map((itm) => (
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          overlay={<Tooltip id="button-tooltip">{itm}</Tooltip>}
                        >
                          <div onClick={() => { setSearchValue(itm); setValue('prompt', itm); }} className="d-block py-2 align-items-center justify-content-center btn commonBtn text-truncate px-3 rounded-pill" style={{
                            maxWidth: 300,
                            fontSize: 12,
                            flexShrink: 0,
                          }}>{itm}      </div>
                        </OverlayTrigger>))
                    }

                  </div>)
                }
                {conversation.map((entry, index) => (
                  <div key={index}>
                    {/* {entry.is_new_chat == 0 ? */}
                    {/* <div className="que-block"> */}
                    {/* <img src={localStorage.getItem("profile_image")} alt="profile" className="profile" /> */}
                    {/* <div> */}
                    {/* <p className='mb-0 font_16 font_600'>You</p> */}
                    {/* <p className="mb-0 font_16">{entry.prompt}</p> */}
                    {/* </div>

                  </div> : ""} */}

                    <div className="ans-sub-block">
                      <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                        <div className='aiicon'>
                          <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="profile" className="profile" />
                        </div>
                        <div>
                          <p className="mb-2 font_16 text-blue font_600">Monte Sereno</p>
                          <pre className="mb-2 text-justify white-space-pre">{parse(entry.message)}</pre>
                          {/* <button className="search-btn" type="button" onClick={() => {
                        pocCreateTextToSpeechCompletion(entry.message)
                      }}>
                        <i class="fa-solid fa-volume-up text-white" ></i>
                      </button> */}

                          {(
                            audioData?.playStatus && audioData?.text == (parse(entry.readMessage)) && (<button className="search-btn" type="button"
                              onClick={() => {
                                stopAudio()
                              }}>
                              <i className="fa-solid fa-pause text-white" />
                            </button>)
                          ) ||
                            (<button className="search-btn" type="button" onClick={() => {
                              pocCreateTextToSpeechCompletion(parse(entry.readMessage))
                            }}>
                              <i className="fa-solid fa-volume-up text-white" />
                            </button>)}
                          <audio onEnded={handleAudioEnd} style={{ height: 0, width: 0, opacity: 0 }} ref={audioRef} controls>
                            <source src="" type="audio/mpeg" />
                          </audio>
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>



          {/* <div className="ans-block">
              {conversation && (
                <div>
                  <div className="ans-sub-block">
                    <div className="bg-[#040404] rounded-lg  mb-2 overflow-auto subblock">
                      <div className='aiicon'>
                        <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="profile" className="profile" />
                      </div>
                      <div>   
                        <pre className="mb-2 text-justify white-space-pre">{conversation}</pre>
                        
                      </div>

                    </div>
                  </div>
                </div>
              )}
            </div> */}
        </div>
        {/* </form> */}
      </section>
      <Footer />

      {showListeningModal &&
        (<>
          <div
            className={`modal fade ${showListeningModal ? 'show' : ''}`}
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
            tabIndex="-1"
            style={{ display: showListeningModal ? 'block' : 'none' }}
          >
            <div className="modal-dialog prescription-modal modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <h5 className="modal-title" id="staticBackdropLabel">Listening</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={closeListeningModal}
                  />
                </div>
                <div className="modal-body">
                  <div className="text-center">
                    {!handleStartStopListening ? <img src={process.env.PUBLIC_URL + "/assets/images/icon/icons8-sound.gif"} alt="search" /> : ""}
                  </div>
                  {/* <div className="d-flex gap-3">
                        <pre className="mb-2 text-justify white-space-pre">{searchValue}</pre>
                      </div> */}
                </div>
                <div className="modal-footer">
                  {/* <button type="button" className="btn btn-secondary" onClick={handleListening}>{handleStartStopListening ? "Start" : "Stop"}</button> */}
                  <button
                    type="button"
                    className="btn submit-btn font_18"
                    onClick={handleListening}
                  >
                    {handleStartStopListening ? "Start" : "Stop"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop fade show"></div>
        </>)
      }
    </>
  )
}