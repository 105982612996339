import React, { useState, useEffect } from 'react'
import Header from '../../../components/header/Header';
import Footer from '../../../components/footer/Footer';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { findPrescriptionListFromChimeConversation, patientInformation, prescription, getProfile, getQrPrescription } from "../../../api/apiHandler";
import Swal from 'sweetalert2';
import queryString from 'query-string';
import moment from 'moment';

function QrCodePrescription() {
    const param = useParams();
    const navigate = useNavigate();
    console.log("param--->",param)
    const location = useLocation();
    const [meetingData, setMeetingData] = useState(param);
    console.log('meetingData: ', meetingData);
    const [medicineList, setMedicineList] = useState([]);
    console.log('medicineList: ', medicineList);

    const [patientData, setPatientData] = useState(null);

    useEffect(() => {
        if (meetingData != null) {
            Swal.fire({
                title: 'Please wait...',
                didOpen: () => {
                    Swal.showLoading()
                }
            })
            const findPrescriptionObj = {
                "meeting_id": meetingData.meeting_id
            }
            getQrPrescription(findPrescriptionObj).then((response) => {
                response = JSON.parse(response)
                Swal.close()
                if (response.code == process.env.REACT_APP_SUCCESS) {
                    console.log("response.data.response-->",response.data.meetingData)
                    setMedicineList(response.data.meetingData)
                } else {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'error',
                        title: response.message,
                        toast: true,
                        showConfirmButton: false,
                        timer: 2000
                    })
                }
            })
            // getPatientDetails()
            // getDoctorDetails()
        }
    }, [])

    // const getPatientDetails = () => {
    //     if (patientId != 0) {
    //         patientInformation({ "patient_id": patientId }).then((response) => {
    //             response = JSON.parse(response)
    //             console.log("response", response);
    //             if (response.code == process.env.REACT_APP_SUCCESS) {
    //                 setPatientData(response.data)
    //             } else {
    //                 setPatientData(null)
    //                 Swal.fire({
    //                     position: 'top-end',
    //                     icon: 'error',
    //                     title: response.message,
    //                     toast: true,
    //                     showConfirmButton: false,
    //                     timer: 2000
    //                 })
    //             }
    //         })
    //     }
    // }

    // const getDoctorDetails = () => {
    //     getProfile({}).then((response) => {
    //         response = JSON.parse(response)
    //         console.log("response", response);
    //         if (response.code == process.env.REACT_APP_SUCCESS) {
    //             setDoctorDataDetails(response.data)
    //         }
    //         // Swal.close()
    //     });

    // }

    

    // const handleGoToPrescription = () => {
    //     console.log("go to prescription");
    //     const prescriptionObj = {
    //         "patient_id" : patientId,
    //         "appointment_id" : meetingData.appointment_id,
    //         "meeting_id" : meetingData.meeting_id
    //     }
    //     navigate('/doctor/prescription',{ state : prescriptionObj }) 
    // }

    return (
        <>
            <main className="bg-color flex-column">
                <Header />
                <section className="doctorsec-pad share-medical">
                    <div className="container">
                        <div className="card">
                            <div className="card-body doctor-table-card">
                                <div className="table-responsive">
                                    <table className="doctor-list-table table table-bordered">
                                        <thead>
                                            <tr>
                                                <th scope="row">Page</th>
                                                <th>Name of Drug</th>
                                                <th>Strength</th>
                                                <th>Strength Type</th>
                                                <th>Unit</th>
                                                <th>Form</th>
                                                <th>Dose</th>
                                                <th>Frequency</th>
                                                <th>Intakes or Applications</th>
                                                <th>Route</th>
                                                <th>Refills</th>
                                                <th>Quantity</th>
                                                <th>Comments</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {medicineList && medicineList.map((item, index) => {
                                                return (
                                                    <tr id={item.id} key={index}>
                                                        <td scope="row">{index + 1}</td>
                                                        <td>
                                                            <h3 className="doc-details">{item.medicine_name}</h3>
                                                        </td>
                                                        {/* <td>{item.strength}</td>
                                                        <td>{item.strength_type}</td>
                                                        <td>{item.medicine_type}</td>
                                                        <td>{item.dose}</td>
                                                        <td>{item.frequency}</td>
                                                        <td>{item.intake}</td>
                                                        <td>{item.route}</td>
                                                        <td>{item.refills}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.comments}</td> */}


                                                        <td>{item.strength}</td>
                                                        <td>{item.strength_type}</td>
                                                        <td>{item.units || item.unit}</td>
                                                        <td>{item.form || item.medicine_type}</td>
                                                        <td>{item.dose}</td>
                                                        <td>{item.frequency}</td>
                                                        <td>{item.intakes_applications || item.intake}</td>
                                                        <td>{item.route}</td>
                                                        <td>{item.refills}</td>
                                                        <td>{item.quantity}</td>
                                                        <td>{item.comments}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </main>
        </>
    )
}

export default QrCodePrescription;
