import React, { useState, useEffect } from 'react'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import styles from "./QRCodePop.module.scss"
import logo from "../../../../assets/logo.png"

const QRCodePop = ({ datePresc, docData, patientData, qrCodeUrl, medicineList, qrCode, setQrCode }) => {
  const handleQRCode = () => setQrCode(!qrCode);
  const [patientDataObj, setPatientDataObj] = useState();


  console.log("medicineList-->,", medicineList)

  //   {
  //     "id": 47,
  //     "first_name": "Mit",
  //     "middle_name": "D",
  //     "last_name": "Patel",
  //     "email": "test111@yopmail.com",
  //     "country_code": "+93",
  //     "phone_no": "1234567896",
  //     "patient_code": "PT-S0FLPA6X0Z",
  //     "doctor_code": null,
  //     "chemist_code": null,
  //     "role": 1,
  //     "createdAt": "2024-03-14T07:05:59.000Z",
  //     "is_verified": 1,
  //     "country": "India",
  //     "country_id": 155,
  //     "state": "Gujarat",
  //     "city": "Surat",
  //     "age": 22,
  //     "address": "Varachha",
  //     "is_married": 0,
  //     "have_children": 0,
  //     "how_many_children": 0,
  //     "id_national": "cdcdcd",
  //     "id_other": "dcdcdc",
  //     "nationality": "dcdcdc",
  //     "biometric_id": "cdcdcd",
  //     "insurance_id": "cdcdcd",
  //     "profile_image_name": "1712901990331.jpeg",
  //     "token": "5xuyvpf1ifedtws2bk5ta9q1nulngums4f89xt45uxwpcjlbacziqkok81t58a0s",
  //     "share_medical_download_code": "EJCF0L12rmlI8qfz69JOXA==",
  //     "prescription_code": "Lz9tTiEUvfH1KkVRKdcMbg==",
  //     "code_expiration_date": "2024-05-13 14:20:09",
  //     "profile_image": "https://msh-staging.s3.amazonaws.com/profile_images/1712901990331.jpeg"
  // }


  return (
    <>
      <Modal
        show={qrCode}
        onHide={handleQRCode}
        backdrop="static"
        keyboard={false}
        centered
        size='xl'
        className={`${styles.QRCodePop} QRCodePop`}
        scrollable="true"
      >
        <Modal.Header className='' closeButton>
          <Modal.Title style={{ fontSize: 16 }} className=' font_500 text-dark  d-flex align-items-center gap-2 mb-0'>View Prescription</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="py-2">
            <div className="bg-light p-2 d-flex align-items-center justify-content-between">
              <p className="m-0 text-dark fw-sbold">{datePresc}</p>
              {qrCodeUrl && (<img style={{ height: 80, width: 80 }} src={qrCodeUrl} alt="" className="img-fluid" />)}
            </div>
          </div>
          <div className="py-2">
            <div className="p-2 rounded border">
              <img src={logo} alt="" style={{ height: 50 }} className="img-fluid object-contain" />
              <ul className="list-unstyled ps-0 mb-0">
                <li className="py-1 d-flex align-items-center justify-content-between">
                  <p className="m-0 text-dark" style={{ fontSize: 12 }}>Doctor Details</p>
                  <p className="m-0 text-dark" style={{ fontSize: 12 }}>Patient Details</p>
                </li>
                <li className="py-1 d-flex align-items-center justify-content-between">
                  <p className="m-0 text-dark" style={{ fontSize: 12 }}>Dr {docData?.first_name + ' ' + docData?.middle_name + ' ' + docData?.last_name}</p>
                  <p className="m-0 text-dark" style={{ fontSize: 12 }}>{patientData?.first_name + ' ' + patientData?.middle_name + ' ' + patientData?.last_name}</p>
                </li>
              </ul>
              <div className="pt-32">
                <p className="m-0 text-dark" style={{ fontSize: 12 }}>Prescription Details</p>
                <div className="table-responsive">
                  <table className="table m-0 border">
                    <thead>
                      <tr>
                        <th
                          style={{ fontSize: 12 }}
                          className="bg-light border-light">Medicine Name</th>
                        <th
                          style={{ fontSize: 12 }}
                          className="bg-light border-light text-center">Qr Code</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        medicineList && medicineList.length > 0 && (medicineList.map((item) => (<tr>
                          <td>
                            <p className="m-0 text-dark text-muted" style={{ fontSize: 12 }}>{
                              item.medicine_name}</p>
                          </td>
                          <td>
                            <div className="text-center">
                              {(qrCodeUrl) && <img style={{ height: 80, width: 80 }} src={qrCodeUrl} alt="" className="img-fluid mx-auto" />}
                            </div>
                          </td>
                        </tr>))
                        )
                      }

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="py-2">
            <Button
              onClick={handleQRCode} className="d-flex align-items-center justify-content-center commonBtn w-100">Close</Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default QRCodePop